/* eslint-disable indent */
import axios from "axios";
import history from "./history";
import { ROUTE_CONSTANTS, LOCAL_STORAGE_SERVICE } from "../../utils";

const mainInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const makeRequest = (instance) => (method, url, token, ...params) => {
  // Set Language Headers
  const locale = LOCAL_STORAGE_SERVICE._getFromLocalStorage("locale");
  mainInstance.defaults.headers.common["Accept-Language"] = locale;
  // Set Access token
  const access_token = LOCAL_STORAGE_SERVICE._getAccessToken();
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    mainInstance.defaults.headers.common["Authorization"] =
      "Bearer " + access_token;
  }
  if (!token) {
    delete axios.defaults.headers.common["Authorization"];
  }
  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // trigger 'loading=false' event here
      return Promise.resolve(response);
    },
    (error) => {
      // trigger 'loading=false' event here
      const originalRequest = error.config;
      if (error?.response?.status === 401) {
        originalRequest._retry = true;
        originalRequest.defaults.headers.common["Authorization"] =
          "Bearer " + access_token;
        originalRequest.headers["Authorization"] = "Bearer " + access_token;
        return axios(originalRequest);
        // history.push("/401");
      } else if (error?.response?.status === 500) {
        history.push(ROUTE_CONSTANTS.Error_500);
      } else {
        return Promise.reject(error);
      }
    },
  );
  return instance[method](url, ...params);
};

const API_REQUEST = (method, url, token) => (...params) => {
  return makeRequest(mainInstance)(method, url, token, ...params);
};
export default API_REQUEST;
