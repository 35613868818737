/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider, IntlActions } from "react-redux-multilingual";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import routes from "./route";
import { store } from "./store/index";
import { DefaultRoute, PrivateRoute, PageLoader } from "./components";
import {
  LANG_TRANS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
} from "./utils";
import TagManager from "react-gtm-module";

import { createMuiTheme } from "@material-ui/core/styles";
import "tabler-react/dist/Tabler.css";
import "./assets/sass/app.scss";
import "./App.css";
import { clientAction } from "./store/actions";

const jss = create({ plugins: [...jssPreset().plugins, rtl()]});

const ltrTheme = createMuiTheme({ direction: "ltr" });
const rtlTheme = createMuiTheme({ direction: "rtl" });

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'bSecureLogin',
};


function App(props) {
  const { locale, gtm_id, showPageLoader } = props;

  const defaultLocale = HELPER.getDefaultLocale(locale);
  const [langLocale, setLangLocale] = useState(defaultLocale);
  const langDirection = HELPER.getLngDirection(locale);
  const storageLocale = LOCAL_STORAGE_SERVICE._getFromLocalStorage("locale");

  useEffect(() => {
    store.dispatch(IntlActions.setLocale(langLocale));
    store.dispatch(clientAction.reset_default_state());
    if (!HELPER.isEmpty(gtm_id) && tagManagerArgs.gtmId !== gtm_id) {
      tagManagerArgs.gtmId = gtm_id;
    }
    TagManager.initialize(tagManagerArgs);
    // returned function will be called on component unmount
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!HELPER.isEmpty(locale)) {
      if (locale !== storageLocale) {
        setLangLocale(storageLocale);
      } else {
        setLangLocale(locale);
      }
    }
    if (!HELPER.isEmpty(gtm_id) && tagManagerArgs.gtmId !== gtm_id) {
      tagManagerArgs.gtmId = gtm_id;
      TagManager.initialize(tagManagerArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, storageLocale, gtm_id]);

  return (
    <>
      {showPageLoader ? <PageLoader /> : ""}
      <StylesProvider jss={jss}>
        <ThemeProvider theme={langDirection === "rtl" ? rtlTheme : ltrTheme}>
          <IntlProvider
            translations={LANG_TRANS}
            locale={langLocale}
            defaultLocale="en"
          >
            <Router>
              <Switch>
                {routes.map((route, index) => {
                  return route.protected ? (
                    <PrivateRoute key={index} {...route} />
                  ) : route.default ? (
                    <DefaultRoute key={index} {...route} />
                  ) : (
                    <Route key={index} {...route} />
                  );
                })}
              </Switch>
            </Router>
          </IntlProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

function mapStateToProps(state) {
  const { locale } = state.Intl;
  const { showPageLoader } = state.loading;
  const { gtm_id } = state.config;
  return {
    showPageLoader,
    locale,
    gtm_id,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
