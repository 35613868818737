export const CHECKOUT_NAVIGATION = {
  ICONS: {
    VERIFICATION: "new-icons-phone-verification",
    ADDRESS: "new-icons-address",
    SHIPMENT: "new-icons-shipping-icon",
    PAYMENT: "new-icons-payment-icon",
    CART: "new-icons-shopping-bag",
    BANK_OTP: "new-icons-otp-send",
  },
  NORMAL_CHECKOUT: {
    STEP_COUNT: {
      VERIFICATION_SCREEN: 1,
      ADDRESS_SCREEN: 2,
    },
  },
};
