import React from "react";
import { IMAGE_SRC } from "../../utils";

function LoadingButton(props) {
  const Spinner = () => (
    <img
      src={IMAGE_SRC.BSECURE_SPINNER}
      className="zoom2 pr-2"
      height="20"
      alt="spinner-white"
    />
  );
  const { loadingState, preloadingText, loadingText } = props;
  return loadingState ? (
    <>
      {" "}
      <Spinner /> <span>{loadingText}</span>{" "}
    </>
  ) : (
    <span>{preloadingText}</span>
  );
}

export default LoadingButton;
