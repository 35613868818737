// @flow

import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function BackButton(props) {
  const { btnTitle } = props;
  function moveBackwards() {
    const { history } = props;
    history.goBack();
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary width150"
      onClick={() => moveBackwards()}
    >
      {btnTitle}
    </button>
  );
}

function mapStateToProps() {
  return {};
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BackButton));
