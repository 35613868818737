export const ROUTE_CONSTANTS = {
  BASE: "/",
  UI_ELEMENTS: "/ui-elements",
  OAUTH: "/auth/sso",
  ONE_TAP_LOGIN: "/one-tap-login",
  ONE_TAP_LOGIN_WITH_REF: "/one-tap-login?ref=",
  PHONE_SCREEN: "/verify-phone",
  CHANGE_NETWORK: "/change-network",
  OTP_SCREEN: "/otp-screen",
  RETRY_OTP: "/retry-otp",
  ADD_NEW_ADDRESS: "/add-new-address",
  MAP_FULL_SCREEN: "/map",
  Error_401: "/401",
  Error_403: "/403",
  Error_404: "/404",
  Error_500: "/500",
  Error_503: "/503",
  Error_Page: "/errorpage",
  GENERAL_ERROR_PAGE: "/error",
  ANY: "*",
};
