const asset_base_path = process.env.REACT_APP_ASSET_BASE_PATH;
const environment = process.env.REACT_APP_ENVIRONMENT;
const folder_path = process.env.REACT_APP_ASSET_FOLDER_NAME;

const base_path = asset_base_path + "/" + environment + "/" + folder_path + "/";
const image_config = "?auto=compress&";

export const IMAGE_SRC = {
  BSECURE_3D_LOGO: `${base_path}assets/images/logo/bSecure_3dlogo.png${image_config}`,
  BSECURE_BLACK_ICON: `${base_path}assets/images/logo/bSecure_black_icon.png${image_config}`,
  BSECURE_FAVICON_LOGO: `${base_path}assets/images/logo/bsecure_favicon.png${image_config}`, // w=300
  BSECURE_ICON: `${base_path}assets/images/logo/bSecure_icon.png${image_config}`,
  BSECURE_WHITE_LOGO: `${base_path}assets/images/logo/bSecure_white_svg.svg`,
  BSECURE_ICON_LOGO: `${base_path}assets/images/logo/iconLogo.png${image_config}`,
  BSECURE_SECURE_LOGO: `${base_path}assets/images/logo/secure_logo.png${image_config}`,

  //  avatar
  USER_AVATAR: `${base_path}assets/images/avatar/user_avatar.png${image_config}`,

  //  spinners
  BSECURE_BLUE_SPINNER: `${base_path}assets/images/spinners/blue.gif`,
  BSECURE_SPINNER: `${base_path}assets/images/spinners/white.gif`,

  //  icons
  BLACK_MAP_PIN: `${base_path}assets/images/icons/black-map-pin.png${image_config}`,
  BLUE_MAP_PIN: `${base_path}assets/images/icons/blue-map-pin.png${image_config}`,
  CLOSE: `${base_path}assets/images/icons/close-icon-new.png${image_config}`,
  MAP_FULL_SCREEN_CONTROL: `${base_path}assets/images/icons/full-screen.svg`,
  SEARCH: `${base_path}assets/images/icons/search-new.png${image_config}`,

  BSECURE_ANNIMATION: `${base_path}assets/images/bSecure_animation.gif`,
  mapTarget: `${base_path}assets/images/map.png${image_config}`,
  FOOTER_LOGO: `${base_path}assets/images/app-footer-logo.svg`,
};
