import * as React from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import { AppWrapper } from "../../../components";
import {
  BREADCRUMB_SETTINGS_JSON,

  CONSTANTS,
  IMAGE_SRC,
  history,
} from "../../../utils";

function Error401(props) {
  const { locale, translate } = props;

  const redirectToRoot = () => {
    history.goBack();
  };

  return (
    <AppWrapper
      showAccountDropdown={false}
      showLangIcon={true}
      showPoweredBy={false}
      showFooter={false}
      breadcrumb={{
        type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
        options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
        show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW,
      }}
    >
      <section className="errorPage">
        <div className="errorPageLogo">
          <img src={IMAGE_SRC.BSECURE_ICON_LOGO} alt="Icon Logo" />
        </div>
        <div className="errorPageText">
          <h1>{translate("oops_text")}</h1>
          <p dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}>
            {translate("error_heading")} 401 -{" "}
            {translate("unauthorized_access")}
          </p>
          <span onClick={(e) => redirectToRoot} className="btn btn-error-back">
            {translate("go_back")}
          </span>
        </div>
      </section>
    </AppWrapper>
  );
}

function mapStateToProps(state) {
  const { locale } = state.Intl;
  return {
    locale,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Error401));
