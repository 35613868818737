import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslate } from "react-redux-multilingual";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import {
  GTM_HELPER,
} from "../../../utils";

const DialogTitle = ((props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="btn-primary closeright"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const redirectToPrivacyPage = () => {
  window.open(process.env.REACT_APP_PRIVACY_URL, '_blank').focus();
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


function PrivacyDialog(props) {
  const { translate } = props;

  useEffect(() => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
        {
          action: GTM_HELPER.GTM_EVENT.NAVIGATION.POPUP.PRIVACY.ACTION,
          label: GTM_HELPER.GTM_EVENT.NAVIGATION.POPUP.PRIVACY.LABEL,
          properties: {},
        },
    );
  }, []);

  return (
    <>
      <DialogTitle id="privacy-dialog-title" onClose={() => props.handleClose()}></DialogTitle>
      <DialogContent id="privacyDialogContent" >
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_1.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_1.CONTENT_1")}
          {" "}
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_1.CONTENT_2")}
        </Typography>
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_2.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_2.CONTENT_1")}
          {" "}
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_2.CONTENT_2")}
        </Typography>
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.CONTENT")}
          {" "}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_1")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_2")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_3")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_4")}
        </Typography>
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.CONTENT")}
          {" "}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_1")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_2")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_3")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_3.LIST.ITEM_4")}
        </Typography>
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_4.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_4.CONTENT_1")}
          {" "}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_4.CONTENT_2")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_4.CONTENT_3")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_4.CONTENT_4")}
        </Typography>
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_5.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_5.CONTENT_1")}
          {" "}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_5.LIST.ITEM_1")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_5.LIST.ITEM_2")}
          <br />
          {translate("DIALOG_CONTENT.PRIVACY.SECTION_5.CONTENT_2")}
          <br />
        </Typography>
        <Typography gutterBottom>
          <span onClick={redirectToPrivacyPage} className="see_more">See more</span>
        </Typography>
      </DialogContent>
    </>
  );
}

export default (withTranslate(PrivacyDialog));
