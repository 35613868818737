import React from "react";
import Zoom from "@material-ui/core/Zoom";

function PageLoader() {
  return (
    <Zoom in={true}>
      <div className="loading">
        <div className="spinner"></div>
      </div>
    </Zoom>
  );
}
export default PageLoader;
