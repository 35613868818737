import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { languageActions } from "../../store/actions";
import { Accordion, Card } from "react-bootstrap";
import { HELPER } from "../../utils";
import {
  SUPPORTED_LANG,
} from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LanguagePopup(props) {
  const { openLangPopup, locale, translate } = props;

  const [open, setOpen] = useState(openLangPopup);

  useEffect(() => {
    setOpen(openLangPopup);
    if (locale) {
      document.querySelector("html").setAttribute("lang", locale);

      const lang_direction = HELPER.getLngDirection(locale);
      document.querySelector("body").setAttribute("dir", lang_direction);
    }
  }, [openLangPopup, locale]);

  const onCloseHandler = () => {
    setOpen(false);
    props.closeLanguagePopup();
  };

  const DialogTitle = (props) => {
    const { onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const changeLngLocale = (lang_locale) => {
    setOpen(false);

    props.changeLanguage(lang_locale);

    document.querySelector("html").setAttribute("lang", lang_locale);

    const lang_direction = HELPER.getLngDirection(lang_locale);
    document.querySelector("body").setAttribute("dir", lang_direction);

    props.closeLanguagePopup();
  };

  return (
    <div>
      <Dialog
        className="OtherPopup languageMainPopup"
        disableBackdropClick
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          className="langCloseIcon"
          onClose={onCloseHandler}
        ></DialogTitle>
        <div className="insidelangPopup">
          <div className="otherPopupInv">
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                component={"div"}
              >
                <span className="suggestedHeadingText">
                  {translate("suggested_language_text")}
                </span>
                <div className="dropdownAccordion">
                  <div className="customRadio shippingCustomRadio">
                    {SUPPORTED_LANG.map((language, i) =>
                      language?.lng_priority === 1 ? (
                        <Card
                          onClick={() => changeLngLocale(language.locale)}
                          className={
                            language.locale === locale ? "activeShow" : ""
                          }
                          key={"languages-" + i}
                        >
                          <Accordion.Toggle
                            as={Card.Header}
                            className="card-header-left"
                            eventKey={"languages-" + i}
                            dir={language.dir}
                          >
                            <h5 className="LangName">
                              {language.language} {language.region}
                            </h5>
                            <h6 className="LangCountry">{language.country}</h6>
                          </Accordion.Toggle>
                        </Card>
                      ) : "",
                    )}
                  </div>
                </div>
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-slide-description"
                component={"div"}
              >
                <span className="suggestedHeadingText">
                  {translate("select_region_text")}
                </span>
                <div className="dropdownAccordion">
                  <div className="customRadio shippingCustomRadio">
                    {SUPPORTED_LANG.map((language, i) => (
                      <Card
                        onClick={() => changeLngLocale(language.locale)}
                        className={
                          language.locale === locale ? "activeShow" : ""
                        }
                        key={"region-" + i}
                      >
                        <Accordion.Toggle
                          as={Card.Header}
                          className="card-header-left"
                          eventKey={"region-" + i}
                        >
                          <h5 className="LangName">
                            {language.language} {language.region}
                          </h5>
                          <h6 className="LangCountry">{language.country}</h6>
                        </Accordion.Toggle>
                      </Card>
                    ))}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  const { openLangPopup } = state.action;
  const { locale } = state.Intl;
  return {
    openLangPopup,
    locale,
  };
}

const mapDispatchToProps = {
  closeLanguagePopup: languageActions.closeLanguagePopup,
  changeLanguage: languageActions.updateLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(LanguagePopup));
