/* eslint-disable indent */

import { apiService } from "../middlewares/api_service";
import { ERROR_MESSAGES } from "../../utils";
import { HELPER, LOCAL_STORAGE_SERVICE } from "../../utils";
import { alertActions } from "../actions";
import {
  addressConstants,
  metaConstants,
  dialogConstants,
} from "../actionTypes";

export const addressAction = {
  add_new_address,
  get_location_detail,
  disable_location_permission_popup,
  empty_location_details,
};

function empty_location_details() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: addressConstants.EMPTY_LOCATION_DETAILS };
  }
}


function add_new_address(data, dropdown, history, redirect = true) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .addAddress(data)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (!HELPER.isEmpty(responseStatus) && responseStatus === 200) {
          LOCAL_STORAGE_SERVICE._saveToLocalStorage("username", data?.name);
          // check countries
          const responseBody = response?.data?.body;

          const redirect_url = responseBody.redirect_url;
          //  Redirect to url received in API response
          // window.location.href = redirect_url;
          HELPER.redirectToPathAndClearLocalStorage(redirect_url);
          dispatch(success(responseBody, dropdown, data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request() {
    return { type: addressConstants.ADD_NEW_REQUEST };
  }
  function success(responseBody, dropdown, data) {
    return {
      type: addressConstants.ADD_NEW_SUCCESS,
      address: responseBody,
      dropdown: dropdown,
      profile: data,
    };
  }
  function failure() {
    return { type: addressConstants.ADD_NEW_FAILURE };
  }
}

function get_location_detail(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getLocationDetails(requestData)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone staus is success
        if (!HELPER.isEmpty(responseStatus) && responseStatus === 200) {
          const responseData = response?.data?.body;
          dispatch(success(responseData));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request() {
    return { type: addressConstants.GET_LOCATION_DETAILS_REQUEST };
  }
  function success(data) {
    let call_meta_deta = false;
    if (data.sync_metadata === 1) {
      call_meta_deta = true;
    }
    if (call_meta_deta) {
      // Call meta data api first then on its success send get-location details api success
      return (dispatch) => {
        dispatch(request());
        apiService
          .getMetaData()
          .then((response) => {
            // check response status
            const responseStatus = response?.data?.status;
            // Check if respone sttaus is success
            if (!HELPER.isEmpty(responseStatus) && responseStatus === 200) {
              // check countries
              const countries = response?.data?.body;
              const data = HELPER.parseMetaData(countries);
              dispatch(success(data));
              dispatch(location_success());
            }
          })
          .catch((error) => {
            const error_response = error?.response;
            if (error_response === undefined) {
              dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
              dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
            } else {
              dispatch(failure(error));
            }
          });
      };

      function request() {
        return { type: metaConstants.META_REQUEST };
      }
      function success(data) {
        return { type: metaConstants.META_SUCCESS, metaData: data };
      }
      function failure() {
        return { type: metaConstants.META_FAILURE };
      }
      function location_success() {
        return {
          type: addressConstants.GET_LOCATION_DETAILS_SUCCESS,
          data,
        };
      }
    } else {
      return {
        type: addressConstants.GET_LOCATION_DETAILS_SUCCESS,
        data,
      };
    }
  }
  function failure() {
    return { type: addressConstants.GET_LOCATION_DETAILS_FAILURE };
  }
}

function disable_location_permission_popup() {
  return (dispatch) => {
    const onClose = () => {
      dispatch(close());
    };
    const onSuccess = () => {
      dispatch(close());
    };
    const onCancel = () => {
      dispatch(close());
    };
    const modal = {
      title: "Please enable location from the browser settings.",
      content: "",
      cancelBtn: "",
      successBtn: "",
    };
    dispatch(open(modal, onClose, onSuccess, onCancel));
  };

  function close() {
    return { type: dialogConstants.MODAL_CLOSE };
  }
  function open(modal, onClose, onSuccess, onCancel) {
    return {
      type: dialogConstants.MODAL_OPEN,
      modal,
      onClose,
      onSuccess,
      onCancel,
    };
  }
}
