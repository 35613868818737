import React, { useState, useEffect } from "react";
import { withTranslate } from "react-redux-multilingual";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { otpAction, clientAction } from "../../store/actions";
import {
  CONSTANTS,
  ERROR_MESSAGES,
  ROUTE_CONSTANTS,
  HELPER,
} from "../../utils";

function OneTapLoginForm(props) {
  const {
    location,
    phone,
    username,
    history,
    translate,
    locale,
  } = props;
  const [page, showPage] = useState(false);
  const [request_id] = useState(location?.state?.request_id);
  const [customer_identifier] = useState(location?.state?.customer_identifier);
  const [user] = useState(HELPER.capitalizeFirstLetter(username));

  useEffect(() => {
    // Check identifier
    if (HELPER.isEmpty(request_id)) {
      showErrorPage(400, CONSTANTS.AUTHENTICATION_PARAMETERS.REQUEST_ID);
    } else if (HELPER.isEmpty(customer_identifier)) {
      showErrorPage(400, CONSTANTS.AUTHENTICATION_PARAMETERS.CUSTOMER_IDENTIFIER);
    } else {
      showPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function continueAsUser() {
    // call verify otp api
    const requestData = {
      customer_identifier,
      sso_request_id: request_id,
    };
    props.submitOtp(requestData, history, location);
  }

  function notAsUser() {
    // redirecting on Phone Number Screen
    history.push({
      pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
      state: {
        request_id,
      },
    });
  }

  const showErrorPage = (errorCode, param_identifier) => {
    // History get for redirection
    let message;
    if (param_identifier === CONSTANTS.AUTHENTICATION_PARAMETERS.REQUEST_ID) {
      message = ERROR_MESSAGES.REQUEST_ID_NOT_FOUND;
    } else if (
      param_identifier ===
      CONSTANTS.AUTHENTICATION_PARAMETERS.CUSTOMER_IDENTIFIER
    ) {
      message = ERROR_MESSAGES.CUSTOMER_IDENTFIER_ID_NOT_FOUND;
    }
    const state = {
      errorCode,
      message,
    };
    props.errorClientAccess(state, history);
    // Show error page
    history.push({
      pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
    });
  };
  const continue_as_user = translate("continue_as_user", {
    customer_name: user.substring(0, 22),
  });
  const not_as_user = translate("not_as_user", {
    customer_name: user.substring(0, 22),
  });


  return (
    <>
      {page ? (
        <>
          <h1 className="oneTapHeading">{user}</h1>
          <p className="ContNum">+{HELPER.checkLocalStorage("phone", phone)}</p>
          <div className="continueBtn newContinueBtnWrap">
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => continueAsUser()}
              dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}
            >
              {HELPER.oneTapTextFormatting(continue_as_user, user.substring(0, 22), "AS_USER")}
            </button>
            <button
              type="button"
              className="btn btn-outline-primary btn-block"
              onClick={() => notAsUser()}
              dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}
            >
              {HELPER.oneTapTextFormatting(not_as_user, user.substring(0, 22), "NOT_AS_USER")}
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

function mapStateToProps(state) {
  const {
    phone,
    username,
    customer_identifier,
  } = state.client;
  const { locale } = state.Intl;
  return {
    phone,
    username,
    customer_identifier,
    locale,
  };
}

const mapDispatchToProps = {
  submitOtp: otpAction.verify_otp,
  errorClientAccess: clientAction.error_client_access,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(OneTapLoginForm)));
