/* eslint-disable  new-cap */
// no-unused-vars,
import React, { useState, useEffect } from "react";
import { withTranslate } from "react-redux-multilingual";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Alert,
  LoadingButton,
  PhoneNumberComponent,
} from "../../components";
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";

import {
  alertActions,
  otpAction,
  verificationAction,
  formAction,
} from "../../store/actions";
import {
  GTM_HELPER,
  HELPER,
} from "../../utils";

let timeoutTime;

function VerifyPhoneForm(props) {
  const {
    alert,
    sendPhoneVerification,
    history,
    location,
    translate,
    contact_number,
    selected_country,
    defaultPhoneCode,
    phoneCodeDropdownList,
    locale_updated,
    networkId,
    network_name,
    defaultCountry,
  } = props;
  const errors = {
    phone_number: "",
  };

  const [phoneNumber, setPhoneNumber] = useState(contact_number);
  const [default_country, setDefaultCountry] = useState([]);

  const [country, setCountry] = useState(selected_country);
  // Initialized to handle validation errors
  const [error, setError] = useState(errors);
  const [network_id, setNetwork_id] = useState(null);
  // const [network_name, setNetwork_name] = useState(null);

  const request_id = location?.state?.request_id;

  useEffect(() => {
    // GTM_HELPER._appFlow(1, GTM_HELPER.GTM_EVENT_CATEGORY.LOGIN);

    if (!locale_updated && HELPER.isEmpty(phoneCodeDropdownList)) {
      // Clear alerts
      props.clearAlerts();
      //  Call meta data API so that default country is selected and we are able to select network
      props.getCountryList();

      //  Case: Network Screen
      //  Check if network id is recieved via route parameters from select-network screen
      if (!HELPER.isEmpty(networkId)) {
        setNetwork_id(networkId);
        setPhoneNumber(phoneNumber);
      }
    }
    if (!HELPER.isEmpty(phoneNumber) && !HELPER.isEmpty(country)) {
      setPhoneNumber(phoneNumber);
    }

    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    //  For network API call set default country code
    if (!HELPER.isEmpty(defaultPhoneCode?.id)) {
      const code = defaultPhoneCode?.id;
      const selected_country = HELPER.findInJson(phoneCodeDropdownList, code);
      setDefaultCountry(selected_country);
    }

    // if (!HELPER.isEmpty(defaultCountry?.value)) {
    //   // Get all networks
    //   props.getAllNetwork(defaultCountry?.value);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry, defaultPhoneCode]);

  const handleChange = (phone, dialCode, country) => {
    const errors = {
      phone_number: "",
    };
    setError(errors);
    setCountry(country);
    setPhoneNumber(phone);
    const countryCallingCode = country.dialCode;
    props.UPDATE_COUNTRY(country);
    props.UPDATE_PHONE_NUMBER(phone.replace(countryCallingCode, ""));
    // if (HELPER.isEmpty(location.state?.network_id)) {
    //   setNetworkInfo(phone);
    // }
  };

  const validatePhone = () => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.LOGIN,
        {
          action: GTM_HELPER.GTM_EVENT.PHONE_NUMBER.INPUT.ACTION,
          label: GTM_HELPER.GTM_EVENT.PHONE_NUMBER.INPUT.LABEL,
          properties: {},
        },
    );

    const code = country.dialCode;
    if (phoneNumber.substr(0, code.length) == country.dialCode && !HELPER.isEmpty(phoneNumber.slice(code.length))) {
      return parsePhoneNumber("+" + phoneNumber);
    } else if (phoneNumber.substr(0, code.length) == country.dialCode && HELPER.isEmpty(phoneNumber.slice(0, code.length))) {
      return false;
    } else {
      return parsePhoneNumber("+" + country.dialCode + phoneNumber);
    }
  };

  const submitForm = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const submitFormOnClick = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleSubmit = (e) => {
    HELPER.hideShowFooter(true);

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.LOGIN,
        {
          action: GTM_HELPER.GTM_EVENT.PHONE_NUMBER.SUBMIT.ACTION,
          label: GTM_HELPER.GTM_EVENT.PHONE_NUMBER.SUBMIT.LABEL,
          properties: {},
        },
    );

    const validationCheck = handleValidation();
    if (validationCheck.phone_number === "") {
      const countryCallingCode = country.dialCode;
      const data = {
        phone_number: phoneNumber.replace(countryCallingCode, ""),
        country_code: countryCallingCode,
        network_name,
        network_id,
        sso_request_id: request_id,
      };
      props.verifyPhone(data, request_id, history);
    }

    timeoutTime = setTimeout(() => {
      props.clearAlerts();
    }, 7000);
  };

  const handleValidation = (e) => {
    const errors = {
      phone_number: "",
    };
    const phone = validatePhone();
    if (!phoneNumber) {
      errors.phone_number = translate("field_is_required");
    }
    if (isValidNumber(phone.number) === false) {
      errors.phone_number = translate("invalid_phone_number");
    }
    setError(errors);
    return errors;
  };

  // const handlePhoneSave = () => {
  //   props.updatePhone(phone_number, network_id, network_name);
  // };

  return (
    <>
      <h1>{translate("phone_screen_title")}</h1>
      <p className="signviewPtext">{translate("phone_title")}</p>
      <Alert alert={alert} />
      {!HELPER.isEmpty(default_country) ? (
        <form onSubmit={submitFormOnClick}>
          <div className={`dropInput ${error.phone_number !== "" ? "formInput md-form error" : ""}`}>
            <div
              className={`input-group ${error.phone_number !== "" ? "error" : ""}`}
              unselectable="on"
              onDoubleClick={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              onKeyDown={submitForm}
              onClick={() => HELPER.hideShowFooter(true)}
              onBlur={() => HELPER.hideShowFooter(false)}
              onFocus={() => HELPER.hideShowFooter(true)}
            >
              <PhoneNumberComponent
                countryCode={!HELPER.isEmpty(country) ? country?.dialCode : ""}
                phone={phoneNumber}
                handleChange={handleChange}
                defaultCountry={default_country}
                error={!HELPER.isEmpty(error.phone_number) ? true : false}
                errorMessage={error.phone_number}
              />
            </div>
            {error.phone_number !== "" ? (
              <span className="errorMessage">{error.phone_number}</span>
            ) : (
              ""
            )}
          </div>
          <div className="notCode">
            {/* <NotOnNetwork savePhoneNumber={handlePhoneSave} screen="phone" /> */}
          </div>
          <button
            type="submit"
            className="btn btn-primary width150"
            disabled={HELPER.isEmpty(phoneNumber) ? true : false}
          >
            <LoadingButton
              loadingState={sendPhoneVerification}
              preloadingText={translate("next_btn")}
              loadingText={translate("next_btn")}
            />
          </button>
        </form>
      ) : (
        <div></div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { alert } = state;
  const { locale_updated } = state.action;
  const { contact_number, selected_country } = state.form;
  const { sendPhoneVerification, networkId, network_name } = state.client;
  const {
    defaultPhoneCode,
    phoneCodeDropdownList,
    defaultCountry,
  } = state.meta;
  return {
    alert,
    locale_updated,
    sendPhoneVerification,
    contact_number,
    selected_country,
    defaultPhoneCode,
    phoneCodeDropdownList,
    defaultCountry,
    networkId,
    network_name,
  };
}
const mapDispatchToProps = {
  getCountryList: verificationAction.get_country_dropdown_list,
  clearAlerts: alertActions.clear,
  verifyPhone: otpAction.verify_phone,
  UPDATE_COUNTRY: formAction.UPDATE_COUNTRY,
  UPDATE_PHONE_NUMBER: formAction.UPDATE_PHONE_NUMBER,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(VerifyPhoneForm)));
