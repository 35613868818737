/* eslint-disable indent */

import { otpConstants, verificationConstants } from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import {
  CONSTANTS,
  ERROR_MESSAGES,
  ROUTE_CONSTANTS,
  LOCAL_STORAGE_SERVICE,
  HELPER,
} from "../../utils";
import { alertActions } from "./index";

export const otpAction = {
  verify_otp,
  verify_phone,
  resend_otp,
};

function verify_otp(requestData) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .verifyOtp(requestData)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS) {
          // check response body
          const responseBody = response?.data?.body;
          //  Set Access Token cookie in browser
          const access_token = responseBody?.access_token;
          LOCAL_STORAGE_SERVICE._updateAccessToken(access_token);
          const redirect_url = responseBody?.redirect_url;

          const store_url = LOCAL_STORAGE_SERVICE._getFromLocalStorage("store_url");

          const responseAltered = {
            redirect_url,
            store_url,
            signup: responseBody?.signup,
            customer_name: responseBody?.customer_name,
            customer_email: responseBody?.customer_email,
            address_count: parseInt(responseBody?.address_count),
          };

          LOCAL_STORAGE_SERVICE._saveToLocalStorage("redirect_url", redirect_url);

          dispatch(success(responseAltered));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request() {
    return { type: otpConstants.OTP_VERIFY_REQUEST };
  }
  function success(responseAltered) {
    return {
      type: otpConstants.OTP_VERIFY_SUCCESS,
      response: responseAltered,
    };
  }
  function failure() {
    return { type: otpConstants.OTP_VERIFY_FAILURE };
  }
}

function verify_phone(data, request_id, history) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .sendOtp(data)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const responseBody = response?.data?.body;
          const signUpUser = responseBody?.signup;

          const phone = data?.phone_number;
          // Parse country code and phone number
          const phoneNumber = phone.slice(3);
          const networkCode = phone.substring(0, 3);
          const showPhone = phoneNumber.slice(-4);
          let hideNumbers = "";
          for (let i = phoneNumber.length - 4; i > 0; i--) {
            hideNumbers += "*";
          }
          const phone_number = hideNumbers + showPhone;
          LOCAL_STORAGE_SERVICE._saveToLocalStorage("phone", phone_number);
          const responseData = {
            phone_network_id: responseBody?.phone_network_id,
            phone_network_code: networkCode,
            phone_network_name: data?.network_name,
            country_code: data?.country_code,
            phone: phone_number,
            otp_timer: responseBody?.otp_timer,
            signup: signUpUser,
          };
          dispatch(success(responseData));

          history.push({
            pathname: ROUTE_CONSTANTS.OTP_SCREEN,
            state: {
              prevState: CONSTANTS.PREV_SCREEN.PHONE_SCREEN,
              request_id,
            },
          });
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request() {
    return { type: verificationConstants.PHONE_VERIFICATION_REQUEST };
  }
  function success(responseData) {
    return {
      type: verificationConstants.PHONE_VERIFICATION_SUCCESS,
      data: responseData,
    };
  }
  function failure() {
    return { type: verificationConstants.PHONE_VERIFICATION_FAILURE };
  }
}

function resend_otp(requestData, history, location) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .resendOtp(requestData)
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // check response body
          const responseMessage = response?.data?.message;
          dispatch(alertActions.success(responseMessage));
          dispatch(success());
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          HELPER.redirectToPathAndClearLocalStorage(store_url);
          // window.location.href = store_url;

          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request() {
    return { type: otpConstants.RETRY_OTP_SEND_REQUEST };
  }
  function success() {
    return { type: otpConstants.RETRY_OTP_SEND_SUCCESS };
  }
  function failure() {
    return { type: otpConstants.RETRY_OTP_SEND_FAILURE };
  }
}
