import { formConstants } from "../actionTypes";

export const formAction = {
  UPDATE_COUNTRY,
  UPDATE_CUSTOMER_NAME,
  UPDATE_PHONE_NUMBER,
  UPDATE_COUNTRY_LIST,
  UPDATE_CUSTOMER_EMAIL,
};

function UPDATE_COUNTRY(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: formConstants.UPDATE_COUNTRY, response };
  }
}

function UPDATE_CUSTOMER_NAME(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: formConstants.UPDATE_CUSTOMER_NAME, response };
  }
}


function UPDATE_CUSTOMER_EMAIL(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: formConstants.UPDATE_CUSTOMER_EMAIL, response };
  }
}


function UPDATE_PHONE_NUMBER(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: formConstants.UPDATE_PHONE_NUMBER, response };
  }
}

function UPDATE_COUNTRY_LIST(response) {
  return (dispatch) => {
    dispatch(request(response));
  };

  function request(response) {
    return { type: formConstants.UPDATE_COUNTRY_LIST, response };
  }
}
