import { dialogConstants } from "../actionTypes";
import { HELPER } from "../../utils";

export const permissionActions = {
  show_permission_popup,
  hide_permission_popup,
  allow_permission_popup,
  dis_allow_permission_popup,
};

// TERMS & CONDITIONS
function show_permission_popup() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: dialogConstants.PERMISSION_POPUP_OPEN };
  }
}

function hide_permission_popup() {
  return (dispatch) => {
    dispatch(close());
  };

  function close() {
    return { type: dialogConstants.PERMISSION_POPUP_CLOSE };
  }
}
function allow_permission_popup() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: dialogConstants.PERMISSION_POPUP_ALLOWED };
  }
}
function dis_allow_permission_popup(store_url) {
  return (dispatch) => {
    dispatch(success());
    HELPER.redirectToPathAndClearLocalStorage(store_url, store_url);
    // window.location.href = store_url;
  };

  function success() {
    return { type: dialogConstants.PERMISSION_POPUP_DISALLOWED };
  }
}
