import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/index";
import App from "./App.react";
// import { unregister } from "./ServiceWorker";

import {
  GTM_HELPER,
} from "./utils";

import "./index.css";
import "./c3jscustom.css";

const rootElement = document.getElementById("root");
if (rootElement) {
  // unregister();
  /*
    INITIALIZE GoogleAnalytics
  */
  GTM_HELPER._initializeGA();
  ReactDOM.render(<Provider store={store}><App /></Provider>, rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
