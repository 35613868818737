import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";

import Countdown, { zeroPad } from "react-countdown-now";

import { otpAction, verificationAction } from "../../../../../store/actions";
import {
  GTM_HELPER,
  LOCAL_STORAGE_SERVICE,
  ROUTE_CONSTANTS,
} from "../../../../../utils";

function OTPBreadcrumb(props) {
  const {
    history,
    disableOTPTimer,
    otp_timer,
    translate,
    retryOtp,
    location,
  } = props;

  // Get request Id from location parameter
  const request_id = location?.state?.request_id;

  const [otpIcon, setOtpIcon] = useState("icon-icon-comment");
  const [timer, setTimer] = useState(LOCAL_STORAGE_SERVICE._getTimer(otp_timer));
  const [key, setKey] = useState(0);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{translate("resend_otp_title")}</span>;
    } else {
      return (
        <span>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  useEffect(() => {
    if (retryOtp) {
      setTimer(LOCAL_STORAGE_SERVICE._getTimer(otp_timer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryOtp]);

  useEffect(() => {
    if (disableOTPTimer) {
      setKey(key + 1);
    } else {
      props.setOTPTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableOTPTimer]);

  const onStart = () => {
    props.setOTPTimer(true);
    setOtpIcon("new-icons-otp-send");
  };

  const onComplete = () => {
    props.setOTPTimer(false);
    setOtpIcon("icon-icon-resend");
  };


  const retryOTP = (e) => {
    e.preventDefault();

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.OTP,
        {
          action: GTM_HELPER.GTM_EVENT.OTP.RETRY.ACTION,
          label: GTM_HELPER.GTM_EVENT.OTP.RETRY.LABEL,
          properties: {},
        },
    );

    // Check if network id is recieved via route parameters from select-network screen
    // Set request data for retry-otp api and send it as well
    const requestData = JSON.stringify({
      network_id: null,
      sso_request_id: request_id,
    });
    props.resendOTP(requestData, history, location);


    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.OTP,
        {
          action: GTM_HELPER.GTM_EVENT.OTP.RETRY.ACTION,
          label: GTM_HELPER.GTM_EVENT.OTP.RETRY.LABEL,
          properties: {},
        },
    );


    history.push({
      pathname: ROUTE_CONSTANTS.RETRY_OTP,
      state: {
        request_id,
      },
    });
    // Set timer again and disable otp div so that no one can click on resend-otp btn for another x mins as per otp_timer value
    setTimer(LOCAL_STORAGE_SERVICE._getTimer(otp_timer));
    setKey(key + 1);
  };

  return (
    <span onClick={retryOTP} id={`${!disableOTPTimer ? "retry-otp" : ""}`}>
      <div className={"icon"}>
        <span className={"iconW font18 " + otpIcon}></span>
      </div>
      <div className={"statusText"}>
        {" "}
        <Countdown
          key={key}
          autoStart={true}
          renderer={renderer}
          date={timer}
          controlled={false}
          onStart={onStart}
          onComplete={onComplete}
        />
      </div>
    </span>
  );
}

function mapStateToProps(state) {
  const {
    retryOtp,
  } = state.config;
  const { otp_timer } = state.client;
  return {
    retryOtp,
    otp_timer,
  };
}
const mapDispatchToProps = {
  resendOTP: otpAction.resend_otp,
  setOTPTimer: verificationAction.SET_OTP_TIMER_STATE,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withRouter(OTPBreadcrumb)));
