import {
  addressConstants,
  metaConstants,
  verificationConstants,
  dialogConstants,
  otpConstants,
} from "../actionTypes";

const initState = {
  showPageLoader: false,
  showBtnLoader: false,
  loadingStatus: 2.5,
};

const loadingReducer = (state = initState, action) => {
  switch (action.type) {
    //  META DATA CALL
    // State update for API: GET_META
    // GET v1/metadata
    case metaConstants.META_REQUEST:
      return {
        ...state,
        showPageLoader: true,
      };
    case metaConstants.META_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
      };
    case metaConstants.META_FAILURE:
      return {
        ...state,
        showPageLoader: false,
      };
    //  META DATA CALL
    // State update for API: GET_META
    // GET v1/metadata
    case metaConstants.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        showPageLoader: true,
      };
    case metaConstants.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
      };
    case metaConstants.COUNTRY_LIST_FAILURE:
      return {
        ...state,
        showPageLoader: false,
      };
    //  META DATA CALL
    // State update for API: GET_META
    // GET v1/metadata
    case verificationConstants.PHONE_VERIFICATION_REQUEST:
      return {
        ...state,
        showPageLoader: true,
        loadingStatus: 25.95,
      };
    case verificationConstants.PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
        loadingStatus: action.data?.signup === 1 ? 55.75 : 65.75,
      };
    case verificationConstants.PHONE_VERIFICATION_FAILURE:
      return {
        ...state,
        showPageLoader: false,
        loadingStatus: 45.95,
      };
    //  META DATA CALL
    // State update for API: GET_META
    // GET v1/metadata
    case otpConstants.OTP_VERIFY_REQUEST:
      return {
        ...state,
        showPageLoader: true,
        showBtnLoader: true,
        loadingStatus: 65.25,
      };
    case otpConstants.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
        showBtnLoader: false,
        loadingStatus: action.response?.signup ? 75.75 : 100,
      };
    case otpConstants.OTP_VERIFY_FAILURE:
      return {
        ...state,
        showPageLoader: false,
        showBtnLoader: false,
        loadingStatus: 70.95,
      };

    case otpConstants.RETRY_OTP_SEND_REQUEST:
      return {
        ...state,
        showPageLoader: true,
      };
    case otpConstants.RETRY_OTP_SEND_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
      };
    case otpConstants.RETRY_OTP_SEND_FAILURE:
      return {
        ...state,
        showPageLoader: false,
      };
    case dialogConstants.PERMISSION_POPUP_ALLOWED:
      return {
        ...state,
        showPageLoader: true,
      };
    case dialogConstants.PERMISSION_POPUP_DISALLOWED:
      return {
        ...state,
        showPageLoader: true,
      };
    case addressConstants.ADD_NEW_REQUEST:
      return {
        ...state,
        showPageLoader: true,
        showBtnLoader: true,
        loadingStatus: 90,
      };
    case addressConstants.ADD_NEW_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
        showBtnLoader: false,
        loadingStatus: 100,
      };
    case addressConstants.ADD_NEW_FAILURE:
      return {
        ...state,
        showPageLoader: false,
        showBtnLoader: false,
        loadingStatus: 90,
      };
    case addressConstants.GET_LOCATION_DETAILS_REQUEST:
      return {
        ...state,
        showPageLoader: true,
        loadingStatus: 80,
      };
    case addressConstants.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        showPageLoader: false,
        loadingStatus: 87,
      };
    case addressConstants.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        showPageLoader: false,
        loadingStatus: 80,
      };

    //  IDENTIFY CUSTOMER LOADING STATE
    case verificationConstants.AT_VALIDATION_REQUEST:
      return {
        ...state,
        loadingStatus: 3.5,
      };
    case verificationConstants.AT_VALIDATION_SUCCESSFULL:
      return {
        ...state,
        loadingStatus: 50,
      };
    case verificationConstants.AT_VALIDATION_UNSUCCESSFULL:
      return {
        ...state,
        loadingStatus: 25.25,
      };
    case verificationConstants.AT_VALIDATION_FAILURE:
      return {
        ...state,
        loadingStatus: 0,
      };
    case verificationConstants.VERIFY_CLIENT_REQUEST:
      return {
        ...state,
        loadingStatus: 3.5,
      };
    case verificationConstants.VERIFY_CLIENT_SUCCESS:
      return {
        ...state,
        loadingStatus: 25.25,
      };
    case verificationConstants.VERIFY_CLIENT_FAILURE:
      return {
        ...state,
        loadingStatus: 0,
      };
    default:
      return state;
  }
};
export default loadingReducer;
