import { alertConstants } from "../actionTypes";

export const alertActions = {
  success,
  warning,
  error,
  secondary,
  clear,
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function secondary(message) {
  return { type: alertConstants.SECONDARY, message };
}

function warning(message) {
  return { type: alertConstants.WARNING, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
