import {
  HELPER,
} from "../../utils";

function validEmail(email) {
  // return /\S+@\S+\.\S+/.test(email);
  const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const emailValidator = /^\w+[\w-+\.]*\@\w+([-\.]\w+)*\.[a-zA-Z]{2,}$/;
  return email.match(emailValidator) ? true : false;
}
function validateEmail(email, translate) {
  const validation_error = {
    status: false,
    message: "",
  };
  if (HELPER.isEmpty(email)) {
    validation_error.status = true;
    validation_error.message = translate("field_is_required");
  } else if (email.length > 50) {
    validation_error.status = true;
    validation_error.message = translate("email_field_max_validation");
  } else if (email.length > 50) {
    validation_error.status = true;
    validation_error.message = "Email field should not be greater than 50 characters";
  } else if (!VALIDATION_HELPER.validEmail(email)) {
    validation_error.status = true;
    validation_error.message = translate("invalid_email_address");
  }
  return validation_error;
}
const VALIDATION_HELPER = {
  validateEmail,
  validEmail,
};
export default VALIDATION_HELPER;
