export const ERROR_MESSAGES = {
  NETWORK_CONNECTIVITY_STATUS_CODE: 599,
  NETWORK_CONNECTIVITY:
    "No network connection found. Kindly check your internet connection",
  CLIENT_NOT_FOUND: "Missing required parameter: client_id",
  RESPONSE_TYPE_FOUND: "Missing required parameter: response_type",
  CODE_FOUND: "Missing required parameter: code",
  SCOPE_NOT_FOUND: "Missing required parameter: scope",
  REQUEST_ID_NOT_FOUND: "Missing required parameter: request_id",
  STATE_NOT_FOUND: "Missing required parameter: state",
  CUSTOMER_IDENTFIER_ID_NOT_FOUND:
    "Missing required parameter: customer_identifier",
  OTP_VALIDATION: "OTP should be atleast 6 digits",
};
