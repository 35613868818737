export const API_ENDPOINTS = {
  // VALIDATE ACCESS TOKEN
  VERIFY_CLIENT: "v1/sso/verify-client",

  // VALIDATE ACCESS TOKEN
  IDENTIFY_CUSTOMER: "v1/token/identify-customer",

  // CUSTOMER
  CUSTOMER_PROFILE: "v1/sso/customer/profile",

  // OTP
  OTP_SEND: "v1/sso/otp/send",
  OTP_VERIFY: "v1/sso/otp/verify",
  OTP_RESEND: "v1/sso/otp/resend",

  //  ADDRESS
  ADD_ADDRESS: "v1/customer/address",
  GET_LOCATION_DETAILS: "v1/customer/get-location-details",

  // META
  GET_META: "v1/metadata",
  GET_COUNTRY_DROPDOWN_LIST: "v1/countries-dropdown-list",
};
