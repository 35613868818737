import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import { IntlReducer as Intl } from "react-redux-multilingual";

import actionReducer from "./action.reducer";
import addressReducer from "./address.reducer";
import alertReducer from "./alert.reducer";
import clientReducer from "./client.reducer";
import mapReducer from "./map.reducer";
import metaDataReducer from "./metaData.reducer";
import loadingReducer from "./loading.reducer";
import numberReducer from "./number.reducer";
import configReducer from "./config.reducer";

const configPersistConfig = {
  key: "config",
  storage: storage,
  whitelist: [
    "merchant_app_theme_code",
    "gtm_id",
    "is_pin_location_mandatory",
  ],
};

const loadingPersistConfig = {
  key: "loading",
  storage: storage,
  whitelist: [
    "loadingStatus",
  ],
};

const clientPersistConfig = {
  key: "client",
  storage: storage,
  whitelist: [
    "address_count",
    "client_verified",
    "customer_name",
    "customer_email",
    "merchant_name",
    "otp_timer",
    "redirect_url",
    "showAddressScreen",
    "signup",
    "signupUser",
    "store_url",
    "username",
    "network_code",
    "country_code",
    "phone",
  ],
};

  const mapPersistConfig = {
    key: "map",
    storage: storage,
    whitelist: [
      'setCoordinatesLat',
      'setCoordinatesLng',
      'updateCoordinatesLat',
      'updateCoordinatesLng',
      'searchPlace',
      'locationPermission',
      'setCoordinatesLatMap',
      'setCoordinatesLngMap',
    ],
  };


  const addressPersistConfig = {
    key: "address",
    storage: storage,
    whitelist: [
      'selectedDropdown',
      'selectedAddress',
      'locationDetails',
      'locationDetailsMap',
    ],
  };

  const metaPersistConfig = {
    key: "meta",
    storage: storage,
    whitelist: [
      'metaData',
    ],
  };

  const numberPersistConfig = {
    key: "form",
    storage: storage,
    whitelist: [
      'name',
      'email',
    ],
  };


const rootReducer = combineReducers({
  alert: alertReducer,
  client: persistReducer(clientPersistConfig, clientReducer),
  address: persistReducer(addressPersistConfig, addressReducer),
  loading: persistReducer(loadingPersistConfig, loadingReducer),
  meta: persistReducer(metaPersistConfig, metaDataReducer),
  map: persistReducer(mapPersistConfig, mapReducer),
  action: actionReducer,
  form: persistReducer(numberPersistConfig, numberReducer),
  config: persistReducer(configPersistConfig, configReducer),
  Intl,
});

export default rootReducer;
