import { CONSTANTS } from "../../utils";
import {
  dialogConstants,
  otpConstants,
  languageConstants,
} from "../actionTypes";

const initState = {
  // POPUP
  setOpen: false,
  popupType: CONSTANTS.POPUP_TYPE.TERMS,
  // MODAL
  setModel: false,
  //  Language MODAL
  openLangPopup: false,
  // PERMISSION MODAL
  setPermissionModel: false,
  setPermissions: false,
  // MODAL EVENTS
  onCloseHandler: null,
  onCancelHandlder: null,
  onSuccessHandler: null,
  modal: { title: "", content: "", cancelBtn: "", successBtn: "" },
};

const actionReducer = (state = initState, action) => {
  switch (action.type) {
    case dialogConstants.TERMS_OPEN:
      return { ...state, setOpen: true, popupType: action.response };
    case dialogConstants.TERMS_CLOSE:
      return { ...state, setOpen: false };
    case dialogConstants.MODAL_OPEN:
      return {
        ...state,
        setModel: true,
        onCloseHandler: action.onClose,
        onCancelHandlder: action.onCancel,
        onSuccessHandler: action.onSuccess,
        modal: action.modal,
      };
    case dialogConstants.MODAL_CLOSE:
      return { ...state, setModel: false };
    case dialogConstants.PERMISSION_POPUP_OPEN:
      return {
        ...state,
        setPermissionModel: true,
      };
    case dialogConstants.PERMISSION_POPUP_CLOSE:
      return { ...state, setPermissionModel: false };
    case dialogConstants.PERMISSION_POPUP_ALLOWED:
      return { ...state, setPermissions: true };

    case languageConstants.LANGUAGE_POPUP_OPEN:
      return {
        ...state,
        openLangPopup: true,
      };
    case languageConstants.LANGUAGE_POPUP_CLOSE:
      return { ...state, openLangPopup: false, locale_updated: false };
    case languageConstants.UPDATE_LANGUAGE_LOCALE:
      return { ...state, locale_updated: true };

    //  Open permission pop up on successful otp response
    case otpConstants.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        setPermissionModel: true,
      };
    default:
      return state;
  }
};
export default actionReducer;
