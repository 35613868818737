import { addressConstants, metaConstants } from "../actionTypes";
import { ROUTE_CONSTANTS } from "../../utils";

const initState = {
  selectedAddress: [],
  selectedDropdown: [],
  //  Gets address location for get-address-location-api and returns user address
  locationDetails: [],
  find_geo_location: false,
};

const addressReducer = (state = initState, action) => {
  const fullScreen=window.location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN;
  switch (action.type) {
    // ADD NEW ADDRESS
    case addressConstants.ADD_NEW_REQUEST:
      return state;
    case addressConstants.ADD_NEW_SUCCESS:
      return {
        ...state,
        selectedAddress: action.address,
        selectedDropdown: action?.dropdown,
      };
    case addressConstants.ADD_NEW_FAILURE:
      return {
        ...state,
        submitAddressForm: false,
      };
    case addressConstants.EMPTY_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: fullScreen ? state.locationDetails: [],
        selectedAddress: [],
        locationDetailsMap: [],
      };
    case metaConstants.DEFAULT_META_REQUEST:
      return {
        ...state,
        selectedDropdown: [],
      };
    case metaConstants.DEFAULT_META_SUCCESS:
      return {
        ...state,
        selectedDropdown: action.metaData,
      };
    case metaConstants.DEFAULT_META_FAILURE:
      return {
        ...state,
      };
    case addressConstants.GET_LOCATION_DETAILS_REQUEST:
      return {
        ...state,
      };
    case addressConstants.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        find_geo_location: true,
        locationDetails: fullScreen ? state.locationDetails : action.data,
        locationDetailsMap: fullScreen ? action.data : [],
      };
     // eslint-disable-next-line spaced-comment
     //address locationDetails save on confirm button
    case addressConstants.CONFIRM_ADDRESS_FULLSCREEN_MAP:
      return {
       ...state,
       find_geo_location: true,
       locationDetails: state.locationDetailsMap?.address ? state.locationDetailsMap : state.locationDetails,
       locationDetailsMap: [],
     };
    case addressConstants.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
      };
    case addressConstants.GET_LOCATION_DETAILS_EMPTY:
      return {
        ...state,
        locationDetails: [],
      };
    default:
      return state;
  }
};
export default addressReducer;
