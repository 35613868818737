// @flow

import React from "react";
import { withTranslate } from "react-redux-multilingual";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { HELPER } from "../../../utils";

function AddAddressForm(props) {
  const { translate, propStates, setCoordinatesLat, setzip_code, zip_code } = props;
  const { is_pin_location_mandatory } = propStates;
  const {
    country_id,
    province_id,
    city_id,
    area_id,
    address,
    countryData,
    citiesData,
    provinceData,
    areasData,
    errors,
    is_default,
  } = props.state;
  const errorCountry = HELPER.isEmpty(errors.country_id) ? false : true;
  const errorProvince = HELPER.isEmpty(errors.province_id) ? false : true;
  const errorCity = HELPER.isEmpty(errors.city_id) ? false : true;
  const errorArea = HELPER.isEmpty(errors.area_id) ? false : true;
  const errorAddress = HELPER.isEmpty(errors.address) ? false : true;
  return (
    <>
      {/* Address */}
      <TextField
        type="textarea"
        // type="text"
        margin="normal"
        variant="outlined"
        className="col-md-12"
        label={translate("address_label")}
        name="address"
        placeholder="Enter your address"
        rows={6}
        multiline={true}
        required={true}
        onChange={props.handleChange}
        value={address}
        helperText={errors.address}
        error={errorAddress}
        autoFocus={false}
      />
          <>
          <div className="inline-items">
            {/* Country */}
            <Autocomplete
              disabled={!!is_pin_location_mandatory && setCoordinatesLat}
              options={countryData || []}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              getOptionSelected={(option, value) => {
                return value?.value;
              }}
              value={country_id}
              onChange={props.handleCountryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  name="country_id"
                  label={translate("country_label")}
                  placeholder="Country"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  required={true}
                  error={errorCountry}
                  helperText={errors.country_id}
                  autoFocus={false}
                />
              )}
            />

            {/* Province */}
            {provinceData && (
              <Autocomplete
                disabled={!!is_pin_location_mandatory && setCoordinatesLat}
                options={provinceData}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                getOptionSelected={(option, value) => {
                  return value?.value;
                }}
                ListboxProps={{ style: { maxHeight: "10rem" }}}
                value={province_id}
                onChange={props.handleProvinceChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    name="province_id"
                    label={translate("province_label")}
                    placeholder="Province"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required={true}
                    error={errorProvince}
                    helperText={errors.province_id}
                    autoFocus={false}
                  />
                )}
              />
            )}
            </div>

            <div className="inline-items">
            {/* Cities */}
            {citiesData && (
              <Autocomplete
                disabled={!!is_pin_location_mandatory && setCoordinatesLat}
                options={citiesData}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                getOptionSelected={(option, value) => {
                  return value?.value;
                }}
                value={city_id}
                onChange={props.handleCityChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    name="city_id"
                    label={translate("city_label")}
                    placeholder="City"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required={true}
                    error={errorCity}
                    helperText={errors.city_id}
                    autoFocus={false}
                  />
                )}
              />
            )}
              <TextField
                type="number"
                // type="text"
                margin="normal"
                variant="outlined"
                label={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
                name="zip_code"
                placeholder={translate("ADDRESS.FORM.LABEL.ZIPCODE")}
                onChange={(e)=> setzip_code(e.target.value)}
                value={zip_code}
                autoFocus={false}
              />
            </div>
            {/* Area */}
            <Autocomplete
              options={areasData || []}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              getOptionSelected={(option, value) => {
                return value?.value;
              }}
              value={area_id}
              onChange={props.handleAreaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  name="area_id"
                  label={translate("area_label")}
                  placeholder="Area"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  required={true}
                  error={errorArea}
                  helperText={errors.area_id}
                  autoFocus={false}
                />
              )}
            />
          </>

      <div className="checkBoxCard">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="is_default"
              value={Number(is_default)}
              onChange={props.handleDefaultChange}
              checked={Number(is_default) === 1 ? true : false}
            />
          }
          label={translate("default_address_text")}
        />
      </div>
    </>
  );
}

export default withTranslate(AddAddressForm);
