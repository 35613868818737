// @flow

import * as React from "react";
import { AppWrapper } from "../index";

function SSOWrapper(props) {
  const {
    child,
    breadcrumbOptions,
    showPoweredBy,
    showFooter,
  } = props;
  return (
    <AppWrapper
      showHeader={true}
      showPoweredBy={showPoweredBy}
      showLangIcon={true}
      showFooter={showFooter}
      breadcrumb={breadcrumbOptions}
    >
      <div className="screen midWrap bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="signInView midView bg-white">{child}</div>
            </div>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}

export default SSOWrapper;
