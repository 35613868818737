// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import { withRouter } from "react-router-dom";
import { AppWrapper } from "../../index";

import {
  BREADCRUMB_SETTINGS_JSON,
  IMAGE_SRC,
  CONSTANTS,
  history,
} from "../../../utils";

function ErrorUnverifiedToken(props) {
  const { errorCode, message, translate, locale } = props;
  const redirectToRoot = (e) => {
    history.goBack();
  };
  return (
    <AppWrapper
      showAccountDropdown={false}
      showPoweredBy={false}
      showFooter={false}
      breadcrumb={{
        type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
        options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
        show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW,
      }}
    >
      <section className="errorPage">
        <div className="errorPageLogo">
          <img src={IMAGE_SRC.BSECURE_ICON_LOGO} alt="Icon Logo" />
        </div>
        <div className="errorPageText">
          <h1>{translate("oops_text")}</h1>
          <p dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}>
            {" "}
            {translate("error_heading")} {errorCode} - Invalid Request .
          </p>
          <p>{message}</p>
          <a href={(e) => redirectToRoot} className="btn btn-error-back">
            {translate("go_back")}
          </a>
        </div>
      </section>
    </AppWrapper>
  );
}

function mapStateToProps(state) {
  const { locale } = state.Intl;
  const { errorCode, message } = state.client;
  return { errorCode, message, locale };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(ErrorUnverifiedToken)));
