// @flow

import * as React from "react";
import { connect } from "react-redux";

import "animate.css";

import {
  CONSTANTS,
} from "../../../../utils";
import {
  GeneralBreadcrumb,
  OTPBreadcrumb,
} from "../../../../components";


function Breadcrumb(props) {
  const { options, label, disableClick } = props;
  const {
    CLASS,
  } = options;

  return (
    <div className={`topStatus animate__animated animate__fadeInDown ${CLASS} ${disableClick ? "disable_click" : ""}`}>
      { label === CONSTANTS.BREADCRUMB_TYPE.GENERAL ? <GeneralBreadcrumb options={options} /> : ""}
      { label === CONSTANTS.BREADCRUMB_TYPE.OTP ? <OTPBreadcrumb /> : ""}
    </div>
  );
}

function mapStateToProps(state) {
  const { disableClick } = state.config.breadcrumbSettings;
  return {
    disableClick,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
