import * as React from "react";
import { withTranslate } from "react-redux-multilingual";

import { HELPER } from "../../../../../utils";

function GeneralBreadcrumb(props) {
  const { options, translate } = props;

  const {
    ICON,
    ICON_CLASS,
    HEADER_CLASS,
    LABEL,
  } = options;

  return (
    <span>
      {
        !HELPER.isEmpty(ICON) ? (
          <div className={`icon `}>
            <i className={`${ICON} ${ICON_CLASS}`}></i>
          </div>
        ) : ""
      }
      <div className={"statusText" + HEADER_CLASS}>{translate(LABEL)}</div>
    </span>
  );
}

export default withTranslate(GeneralBreadcrumb);
