import { ERROR_MESSAGES, HELPER } from "../../utils";

let bodyDirection;
let errorMessage;
let errorMessageDescription;

export const errorAction = (error_response) => {
  bodyDirection = HELPER.getBodyDirection();
  if (error_response === undefined) {
    return bodyDirection === "rtl" ? ERROR_MESSAGES.UR_NETWORK_CONNECTIVITY : ERROR_MESSAGES.EN_NETWORK_CONNECTIVITY;
  } else {
    const error_message = error_response?.data?.message;
    return error_message;
  }
};
export const errorPageAction = (error_response, showBody = false) => {
  bodyDirection = HELPER.getBodyDirection();

  if (error_response === undefined) {
    errorMessage =
      bodyDirection === "rtl" ? ERROR_MESSAGES.UR_NETWORK_CONNECTIVITY : ERROR_MESSAGES.EN_NETWORK_CONNECTIVITY;
    errorMessageDescription =
      bodyDirection === "rtl" ? ERROR_MESSAGES.UR_NETWORK_CONNECTIVITY_DESCRIPTION : ERROR_MESSAGES.EN_NETWORK_CONNECTIVITY_DESCRIPTION;
    return {
      error: errorMessage,
      errorStatus: ERROR_MESSAGES.NETWORK_CONNECTIVITY_STATUS,
      errorDescription: errorMessageDescription,
    };
  } else {
    const error_message = error_response?.data?.message;
    const error_status = error_response?.data?.status;
    const error_body = error_response.data?.body;
    return {
      error: error_message,
      errorStatus: error_status,
      errorDescription: error_response?.statusText,
      errorBody: error_body,
    };
  }
};

export const successAlertAction = (success_response) => {
  const responseMessage = success_response?.data?.message;
  return responseMessage;
};
