import React, { useState, useEffect } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { isIOS, isIPhone13 } from "react-device-detect";
import startsWith from "lodash.startswith";

import "react-phone-input-2/lib/style.css";
import { HELPER } from "../../utils";
import { formAction } from "../../store/actions";

let countryMatched;

function PhoneNumberComponent(props) {
  const {
    defaultCountry,
    onlyCountriesDropdownList,
    phone,
    countryCode,
    translate,
    country_list,
  } = props;
  const code = defaultCountry.name;

  const [countryList, setCountryList] = useState([]);
  const [phone_number, setPhoneNumber] = useState(HELPER.isEmpty(phone) ? phone : phone.replace("+", ""));
  const [countryDialCode, setCountryDialCode] = useState(countryCode);
  const [countryChangeCount, setCountryChangeCount] = useState(!HELPER.isEmpty(countryCode) ? 1 : 0);

  useEffect(() => {
    const element = document.getElementsByClassName("form-control");
    element[0].oncontextmenu = eventPrevented;
    element[0].dblclick = eventPrevented;
    element[0].selectionchange = eventPrevented;
    element[0].onselectionstart = eventPrevented;
    element[0].mouseup = eventPrevented;
    element[0].onmousemove = eventPrevented;
    element[0].focus();


    if (!HELPER.isEmpty(country_list) && !HELPER.isEmpty(countryDialCode)) {
      setPhoneNumber(countryDialCode + phone_number);
      const country = country_list.find((country) => parseInt(country.dialCode) === parseInt(countryDialCode));
      props.handleChange(phone, countryDialCode, country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (phone, country) => {
    if (!HELPER.isEmpty(country)) {
      const dialCode = country.dialCode;
      if (parseInt(phone.charAt(0)) === 0) {
        const phoneNumber = phone.replace(/^0+/, "");
        setPhoneNumber("+" + dialCode + phoneNumber);
        props.handleChange(phoneNumber, dialCode, country);
      } else {
        if (!isIOS) {
          setPhoneNumber(phone);
        } else {
          if (isIOS && isIPhone13) {
            const updatedDialCode = parseInt(phone.substring(0, dialCode.length));
            if (
              parseInt(dialCode) !== parseInt(countryDialCode) &&
              countryChangeCount === 0
            ) {
              setCountryChangeCount(1);
              phone = countryDialCode + phone;
              setPhoneNumber(phone);
            } else if (
              parseInt(dialCode) !== parseInt(countryDialCode) &&
              countryChangeCount >= 1
            ) {
              if (updatedDialCode !== parseInt(countryDialCode)) {
                if (
                  !(
                    updatedDialCode === parseInt(dialCode) &&
                    parseInt(dialCode) !== parseInt(countryDialCode)
                  )
                ) {
                  phone = countryDialCode + phone;
                }
              }
            } else if (parseInt(dialCode) === parseInt(countryDialCode)) {
              setCountryChangeCount(1);
              if (updatedDialCode !== parseInt(countryDialCode)) {
                if (
                  !(
                    updatedDialCode === parseInt(dialCode) &&
                    parseInt(dialCode) !== parseInt(countryDialCode)
                  )
                ) {
                  phone = countryDialCode + phone;
                }
              }
            } else {
              setCountryChangeCount(1);
            }
            country = countryList.find((country) => parseInt(country.dialCode) === parseInt(countryDialCode));
          }
          setPhoneNumber(phone);
        }
        props.handleChange(phone, countryDialCode, country);
      }
    }
  };

  const eventPrevented = (event) => {
    event.preventDefault();
  };

  const disableBackspace = (event) => {
    if ((event.key === 17 || event.keyCode === 2 || event.keyCode === 32 || event.keyCode === 65) ||
    ((event.target.value === '+92') && event.key === '0')) {
      event.preventDefault();
    }
  };

  return (
    <PhoneInput
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: true,
        // autoComplete: 'off'
      }}
      withCountryCallingCode={false}
      autoFormat={false}
      containerClass={"custom-tel-container"}
      inputClass="custom-tel-input"
      buttonClass={"custom-tel-flag-dropdown"}
      dropdownClass={"custom-tel-dropdown"}
      onKeyPress={disableBackspace}
      onKeyDown={disableBackspace}
      placeholder={translate("phone_placeholder")}
      country={code.toLowerCase()}
      value={phone_number}
      onChange={handleChange}
      onlyCountries={onlyCountriesDropdownList}
      isValid={(inputNumber, country, countries, e) => {
        if (HELPER.isEmpty(countryList)) {
          setCountryList(countries);
          props.uPDATE_COUNTRY_LIST(countries);
        }
        return countries.some((country) => {
          countryMatched = startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
          if (countryMatched) {
            setCountryDialCode(country.dialCode);
          }
          return (countryMatched);
        });
      }}
    />
  );
}

function mapStateToProps(state) {
  const { onlyCountriesDropdownList } = state.meta;
  const { country_list } = state.form;
  return {
    onlyCountriesDropdownList,
    country_list,
  };
}
const mapDispatchToProps = {
  uPDATE_COUNTRY_LIST: formAction.UPDATE_COUNTRY_LIST,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(PhoneNumberComponent));
