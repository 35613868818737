import React, { useState, useEffect } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { Accordion, Card } from "react-bootstrap";
// import { MerchantLogoComponent, AccountDropdown } from "../";
import { permissionActions, languageActions } from "../../store/actions";
import { CONSTANTS, IMAGE_SRC, GTM_HELPER, HELPER } from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PermissionPopup(props) {
  const {
    setPermissionModel,
    merchant_name,
    store_url,
    translate,
    locale,
  } = props;

  const [open, setOpen] = useState(setPermissionModel);
  const [accordianClass, setAccordianClass] = useState(null);

  const addClassToCard = () => {
    if (accordianClass === null) {
      setAccordianClass("activeShow");
    } else {
      setAccordianClass(null);
    }
  };

  useEffect(() => {
    setOpen(setPermissionModel);
  }, [setPermissionModel]);

  const onCloseHandler = (e) => {
    e.preventDefault();
    setOpen(false);
    props.closePermissionPopup();
  };
  const onCancelHandlder = (e) => {
    e.preventDefault();
    setOpen(false);
    // window.history.back();
    props.closePermissionPopup();
    props.permissionRejected(store_url);

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.PERMISSION,
        {
          action: GTM_HELPER.GTM_EVENT.PERMISSION.REJECT.ACTION,
          label: GTM_HELPER.GTM_EVENT.PERMISSION.REJECT.LABEL,
          properties: {},
        },
    );
  };
  const onSuccessHandler = (e) => {
    e.preventDefault();
    setOpen(false);
    props.permissionsAllowed();
    props.closePermissionPopup();

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.PERMISSION,
        {
          action: GTM_HELPER.GTM_EVENT.PERMISSION.ALLOW.ACTION,
          label: GTM_HELPER.GTM_EVENT.PERMISSION.ALLOW.LABEL,
          properties: {},
        },
    );
  };

  return (
    <div>
      <Dialog
        className="OtherPopup"
        disableBackdropClick
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="mainWrap">
          <header>
            <div className="container">
              <div className="row justify-content-between mainPermissionPopWrap">
                <div className="col-8">
                  <div className="logo_One">
                    <img
                      src={IMAGE_SRC.BSECURE_WHITE_LOGO}
                      className={"header-brand-img"}
                      alt="bSecure"
                    />
                  </div>
                </div>
                <div className="headerDropdown customHeaderThings"></div>
              </div>
            </div>
          </header>
        </div>
        <div
          className="otherPopupInv"
          dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {HELPER.getPermissionText(translate("continue_with", { merchant_name }), merchant_name)}
          </DialogTitle>

          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}
              component={"div"}
            >
              {HELPER.getPermissionText(translate("permission_text", { merchant_name }), merchant_name)}
            </DialogContentText>
          </DialogContent>

          <div className="dropdownAccordion">
            <div className="customRadio shippingCustomRadio">
              <Accordion defaultActiveKey={null}>
                <Card className={accordianClass} key={0}>
                  <Accordion.Toggle
                    as={Card.Header}
                    className="card-header-left"
                    eventKey={0}
                    onClick={() => addClassToCard()}
                  >
                    <span>{translate("what_you_allow")}</span>
                    <span>
                      <i className="caret"></i>
                    </span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={0}>
                    <Card.Body>
                      <ul>
                        <li className="active">
                          {translate("name_placeholder")}{" "}
                          <span className="tickIcon"></span>
                        </li>
                        <li className="active">
                          {translate("phone_title")}{" "}
                          <span className="tickIcon"></span>
                        </li>
                        <li className="active">
                          {translate("address_title")}{" "}
                          <span className="tickIcon"></span>
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>

          <div className="bottomDialog">
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                component={"div"}
              >
                <p>
                  <i className="icon-icon-lock"></i>
                  {translate("permission_footer_text")}
                </p>
              </DialogContentText>
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              onClick={onSuccessHandler}
              color="primary"
              className="btn btn-outline-white"
            >
              {translate("allow_btn")}
            </Button>
            <Button
              onClick={onCancelHandlder}
              color="primary"
              className="btn btn-white btn-white-B"
            >
              {translate("cancel_btn")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  const { setPermissionModel } = state.action;
  const { locale } = state.Intl;
  const { merchant_name, store_url } = state.client;
  return {
    setPermissionModel,
    merchant_name: HELPER.capitalizeFirstLetter(merchant_name),
    store_url,
    locale,
  };
}

const mapDispatchToProps = {
  closePermissionPopup: permissionActions.hide_permission_popup,
  permissionsAllowed: permissionActions.allow_permission_popup,
  permissionRejected: permissionActions.dis_allow_permission_popup,
  openLanguagePopup: languageActions.openLanguagePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(PermissionPopup));
