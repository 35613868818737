
/* eslint-disable max-len */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import { TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";

import { CheckoutWrapper, MapFullScreenPopUp } from "../../../components";
import {
  HELPER,
  IMAGE_SRC,
  GTM_HELPER,
  CONSTANTS,
} from "../../../utils";
import {
  addressAction,
  mapActions,
} from "../../../store/actions";
import MapComponent from "./map";

function FullScreenMapView(props) {
  const { translate, searchPlace, history, setCoordinatesLat, locationPermission, setCoordinatesLatMap, is_pin_location_mandatory } = props.properties;
  const [address, setAddress] = useState("");
  const [loadMap, setMapLoad] = useState(false);

  //  Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!HELPER.isEmpty(searchPlace)) {
      setAddress(searchPlace);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPlace]);

  const handleAddressChange = (e) => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.ACTION,
          label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.LABEL,
          properties: {},
        },
    );
    setAddress(e.target.value);
  };

  const handleMapLoading = (e) => {
    setMapLoad(true);
  };

  const resetLocation = (e) => {
    setAddress("");
    props.properties.emptyLocationDetails();
  };

  const getPinLocation = (e) => {
    props.properties.getPinLocation(true);
  };

  const closeMapDirection = (e) => {
    props.properties.confirmLocation();
    history.goBack();
  };

  useEffect(() => {
    if (!setCoordinatesLat) {
      getPinLocation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={"FullScreenAddressWrapper"}>
        <div className={isMobile ? "address-pin-container mobile-screen" : "address-pin-container"}>
          <div className="map-box">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* Address */}
              <TextField
                type="text"
                margin="normal"
                variant="outlined"
                className="col-md-12 msgfieldBot"
                inputProps={{
                  id: "map-address",
                }}
                name="address"
                placeholder={translate("map_address_label")}
                required={true}
                onChange={handleAddressChange}
                value={address}
                autoFocus={false}
              />
            </form>
            <div className="formRightSide">
              <button type="button">
                {
                  !HELPER.isEmpty(address) ? <img src={IMAGE_SRC.CLOSE} alt="map-search-icon" onLoad={handleMapLoading} onClick={resetLocation} /> : <img src={IMAGE_SRC.SEARCH} alt="map-search-icon" onLoad={handleMapLoading} />
                }
              </button>
              <button type="button" className="mapDirection">
                <span onClick={getPinLocation}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle class="st0" cx="18" cy="18" r="17.6" /><path class="st1" d="M18.2 13.8c-2.4 0-4.3 2-4.3 4.4 0 2.4 1.9 4.4 4.3 4.4s4.3-2 4.3-4.4C22.5 15.8 20.6 13.8 18.2 13.8zM27.9 17.1c-0.5-4.5-4.1-8.2-8.6-8.7V6.2h-2.2v2.2C12.6 9 9 12.6 8.5 17.1H6.2v2.2h2.2c0.5 4.5 4.1 8.2 8.6 8.7v2.2h2.2V28c4.5-0.5 8.1-4.1 8.6-8.7h2.2v-2.2H27.9zM18.2 25.8c-4.2 0-7.6-3.4-7.6-7.6s3.4-7.6 7.6-7.6 7.6 3.4 7.6 7.6S22.4 25.8 18.2 25.8z" /></svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {
        loadMap ? <MapComponent /> : ""
      }

      {locationPermission !== CONSTANTS.PERMISSIONS.GRANTED && !setCoordinatesLat && !setCoordinatesLatMap && is_pin_location_mandatory &&
        <div className={"FullScreenDialog"}>
          <MapFullScreenPopUp locationPermission={locationPermission} />
        </div>
      }

      <div className={"FullScreenLocationBtnWrapper"}>
        <div className="confirm-location">
          <button
            type="button"
            id="back-btn"
            className="btn btn-primary"
            onClick={closeMapDirection}
            disabled={!setCoordinatesLat && !setCoordinatesLatMap}
          >
            Confirm Location
          </button>
        </div>
      </div>
    </>
  );
}

function FullScreenMap(props) {
  return (
    <CheckoutWrapper
      fullscreenModeEnabled={true}
      showMap={false}
      child={<FullScreenMapView properties={props} />}
    />
  );
}

function mapStateToProps(state) {
  const { searchPlace, setCoordinatesLat, locationPermission, setCoordinatesLatMap } = state.map;
  const { is_pin_location_mandatory } = state.config;
  return { searchPlace, setCoordinatesLat, locationPermission, setCoordinatesLatMap, is_pin_location_mandatory };
}
const mapDispatchToProps = {
  getPinLocation: mapActions.GET_LOCATION_PIN,
  emptyLocationDetails: addressAction.empty_location_details,
  confirmLocation: mapActions.CONFIRM_ADDRESS_FULLSCREEN_MAP,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withRouter(FullScreenMap)));
