import ReactGA from 'react-ga';

const GTM_EVENT_CATEGORY = {
  NAVIGATION: "General Navigation",
  LOGIN: "Login",
  OTP: "OTP",
  ADDRESS: "Address",
  PERMISSION: "Permission",
};

const GTM_EVENT = {
  NAVIGATION: {
    LANG_CHANGE: {
      ACTION: "Change language button",
      LABEL: "Globe button",
    },
    POPUP: {
      TERMS: {
        ACTION: "Terms Popup is pressed",
        LABEL: "Terms Popup",
      },
      PRIVACY: {
        ACTION: "Privacy Popup is pressed",
        LABEL: "Privacy Popup",
      },
    },
  },
  BREADCRUMB: {
    NAVIGATION: {
      ADDRESS: {
        ACTION: "Move to address screen",
        LABEL: "Address",
      },
    },
  },
  PERMISSION: {
    ALLOW: {
      ACTION: "Permission allow button is pressed",
      LABEL: "Permission allow",
    },
    REJECT: {
      ACTION: "Permission reject button is pressed",
      LABEL: "Permission reject",
    },
  },
  PHONE_NUMBER: {
    INPUT: {
      ACTION: "Phone number input is pressed",
      LABEL: "Phone input",
    },
    SUBMIT: {
      ACTION: "Phone Number submission button",
      LABEL: "Step buttons",
    },
  },
  OTP: {
    INPUT: {
      ACTION: "OTP code input",
      LABEL: "OTP input",
    },
    RETRY: {
      ACTION: "Retry OTP button pressed",
      LABEL: "OTP retry",
    },
    SUBMIT: {
      ACTION: "OTP code submission button",
      LABEL: "Step button",
    },
  },
  ADDRESS: {
    INPUT: {
      NAME: {
        ACTION: "Name input is pressed",
        LABEL: "Name input",
      },
      EMAIL: {
        ACTION: "Email input is pressed",
        LABEL: "Email input",
      },
      COUNTRY: {
        ACTION: "Country input is pressed",
        LABEL: "Country input",
      },
      PROVINCE: {
        ACTION: "Province input is pressed",
        LABEL: "Province input",
      },
      CITY: {
        ACTION: "City input is pressed",
        LABEL: "City input",
      },
      AREA: {
        ACTION: "Area input is pressed",
        LABEL: "Area input",
      },
      ADDRESS: {
        ACTION: "Address input is pressed",
        LABEL: "Address input",
      },
    },
    SUBMIT: {
      ACTION: "Address submission button",
      LABEL: "Step button",
    },
  },
};


function _initializeGA() {
  // Initialize google analytics page view tracking
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
}

function _initApp() {
  // Initialize e-com plugin of analytics
  ReactGA.plugin.require('ec');
  // use that plugin in your other calls, e.g.:
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1, option: GTM_EVENT_CATEGORY.LOGIN });
  ReactGA.plugin.execute('ec', 'clear');

  return true;
}

function _setPageView(location) {
  // Update the user's current page
  ReactGA.set({ page: location });
  // Record a pageview for the given page
  ReactGA.pageview(location);
  // ReactGA.ga('send', 'pageview', location);
}

function _appFlow(step, name) {
  window.bSecureLogin = window.bSecureLogin || [];
  // Initialize e-com plugin of analytics
  ReactGA.plugin.require('ec');
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: step, option: name });
  ReactGA.plugin.execute('ec', 'clear');
  return true;
}

function _generalEventLog(SUB_EVENT, properties) {
  window.bSecureLogin = window.bSecureLogin || [];
  const action = properties?.action;
  const label = properties?.label;

  delete properties.action;
  delete properties.label;

  window.bSecureLogin.push({
    event: SUB_EVENT,
    eventProps: {
      category: SUB_EVENT,
      action,
      label,
      value: {
        nonInteraction: false,
      },
    },
  });

  ReactGA.event({
    category: SUB_EVENT,
    action,
    label,
    nonInteraction: false,
  });

  return true;
}


function _navigationEventLog(type, properties) {
  window.bSecureLogin = window.bSecureLogin || [];
  const action = properties?.action;
  const label = properties?.label;

  delete properties.action;
  delete properties.label;

  window.bSecureLogin.push({
    event: type,
    eventProps: {
      category: type,
      action,
      label,
      value: {
        ...properties,
        nonInteraction: false,
      },
    },
  });

  ReactGA.event({
    category: type,
    action,
    label,
    nonInteraction: false,
  });
  return true;
}

export const GTM_HELPER = {
  GTM_EVENT_CATEGORY: GTM_EVENT_CATEGORY,
  GTM_EVENT,
  _initializeGA,
  _initApp,
  _setPageView,
  _appFlow,
  _generalEventLog,
  _navigationEventLog,
};
