import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function CustomerNumber(props) {
  const { country_code, network_code, phone } = props;
  return (
    <>
      +{country_code}({network_code}){phone}
    </>
  );
}

function mapStateToProps(state) {
  const { network_code, phone, country_code } = state.client;
  return { network_code, phone, country_code };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerNumber));
