import React from "react";
import { withTranslate } from "react-redux-multilingual";
import { IMAGE_SRC } from "../../utils";

function PoweredBy(props) {
  const { translate } = props;
  return (
    <>
      <div className="bSecureIcon powered">
        {translate("powered_by")}{" "}
        <img src={IMAGE_SRC.BSECURE_ICON} alt="bSecureIcon" />
      </div>
    </>
  );
}

export default withTranslate(PoweredBy);
