import React from "react";
import { AppWrapper } from "../";
import { BREADCRUMB_SETTINGS_JSON } from "../../utils";

function Home() {
  return (
    <>
      {/* <div className="iconSpinning"></div> */}
      <AppWrapper
        showPoweredBy={true}
        showAccountDropdown={false}
        showLangIcon={false}
        showMerchantLogo={false}
        allowProfileAPI={false}
        showFooter={false}
        breadcrumb={{
          type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
          show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW,
        }}
      >
        <div className="screen midWrap bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="home-loading">
                  <div className="home-heading">
                    Loading Secure Login
                  </div>
                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
}
export default Home;
