import {
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  ErrorUnverifiedToken,
  Home,
} from "./components";
import {
  OneTapLogin,
  OTP,
  VerifyPhone,
  VerifyClient,
  AddNewAddress,
} from "./containers";
import FullScreen from "./containers/GoogleMap/FullScreen";
import {
  ROUTE_CONSTANTS,
} from "./utils";

const routes = [
  {
    path: ROUTE_CONSTANTS.BASE,
    exact: true,
    component: Home,
    protected: false,
    default: false,
    view: "base",
  },
  {
    path: ROUTE_CONSTANTS.OAUTH,
    exact: false,
    component: VerifyClient,
    protected: false,
    default: false,
    view: "init-oauth",
  },
  {
    path: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
    exact: false,
    component: ErrorUnverifiedToken,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: ROUTE_CONSTANTS.ONE_TAP_LOGIN,
    exact: true,
    component: OneTapLogin,
    protected: false,
    default: false,
    view: "one-tap-login",
  },
  {
    path: ROUTE_CONSTANTS.ONE_TAP_LOGIN_WITH_REF,
    exact: true,
    component: OneTapLogin,
    protected: false,
    default: false,
    view: "one-tap-login",
  },
  {
    path: ROUTE_CONSTANTS.PHONE_SCREEN,
    exact: true,
    component: VerifyPhone,
    protected: false,
    default: false,
    view: "phone",
  },
  {
    path: ROUTE_CONSTANTS.OTP_SCREEN,
    exact: true,
    component: OTP,
    protected: false,
    default: false,
    view: "otp",
  },
  {
    path: ROUTE_CONSTANTS.RETRY_OTP,
    exact: true,
    component: OTP,
    protected: false,
    default: false,
    view: "retry-otp",
  },
  {
    path: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
    exact: true,
    component: AddNewAddress,
    protected: false,
    default: false,
    view: "address",
  },
  {
    path: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
    exact: true,
    component: FullScreen,
    protected: false,
    default: false,
    view: "map",
  },
  {
    path: ROUTE_CONSTANTS.Error_401,
    exact: false,
    component: Error401,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: ROUTE_CONSTANTS.Error_403,
    exact: false,
    component: Error403,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: ROUTE_CONSTANTS.Error_404,
    exact: false,
    component: Error404,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: ROUTE_CONSTANTS.Error_500,
    exact: false,
    component: Error500,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: ROUTE_CONSTANTS.Error_503,
    exact: false,
    component: Error503,
    protected: false,
    default: false,
    view: "error",
  },
  {
    path: "*",
    exact: false,
    component: Error400,
    protected: false,
    default: false,
    view: "*",
  },
];

export default routes;
