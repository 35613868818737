import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { Rifm } from "rifm";
import { AsYouType } from "libphonenumber-js";
import { HELPER } from "../../utils";

const parseDigits = (string) => (string.match(/\d+/g) || []).join("");

const formatPhone = (string) => {
  const digits = parseDigits(string).substr(0, 10);
  return new AsYouType("US").input(digits);
};

function PhoneMasking(props) {
  const { translate } = props;
  const [number, setNumber] = React.useState("");

  useEffect(() => {
    if (!HELPER.isEmpty(props.phone_number)) {
      setNumber(props.phone_number);
    }
  }, [props]);

  const handleChange = (number) => {
    setNumber(number.replace(/^0+/, ""));
    props.changePhoneNumber({ number: number });
  };

  return (
    <Rifm
      // format={formatInteger}
      mask={
        number.length < 6 && /[^\d]+/.test(number[3]) ? undefined : number.length >= 14
      }
      format={formatPhone}
      value={number}
      onChange={handleChange}
    >
      {({ value, onChange }) => (
        <input
          type="tel"
          autocomplete="off"
          id="phone_number"
          name="phone_number"
          // pattern="[0-9]{(000) 00000000}"
          value={value}
          className="form-control phone_with_ddd"
          aria-label="Text input with dropdown button"
          placeholder={translate("phone_placeholder")}
          onChange={onChange}
        />
      )}
    </Rifm>
  );
}

export default (withRouter(withTranslate(PhoneMasking)));
