import { alertConstants } from "../../store/actionTypes";
const initState = {
  type: null,
  message: null,
};
const alertReducer = (state = initState, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        type: "success",
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        ...state,
        type: "danger",
        message: action.message,
      };
    case alertConstants.WARNING:
      return {
        ...state,
        type: "warning",
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {
        ...state,
        type: null,
        message: null,
      };
    default:
      return state;
  }
};

export default alertReducer;
