// @flow
// eslint-disable-next-line
import React from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";

import { ContentDialog } from "../../../components";

import { dialogAction } from "../../../store/actions";
import {
  IMAGE_SRC,
  CONSTANTS,
} from "../../../utils";

function AppFooter(props) {
  const { translate } = props;

  const openTermsPopup = () => {
    props.openDialogBox(CONSTANTS.POPUP_TYPE.TERMS);
  };

  const openPrivacyPopup = () => {
    props.openDialogBox(CONSTANTS.POPUP_TYPE.PRIVACY);
  };

  return (
    <>
      <section className="footerWrap">
        <div className="container">
          <div className="footer-main">
            <img src={IMAGE_SRC.FOOTER_LOGO} alt="" onClick={() => window.open(process.env.REACT_APP_BSECURE_WEBSITE_URL, '_blank')} />
            <ul>
              <li><span onClick={openTermsPopup}>{translate("FOOTER.TERMS")}</span></li>
              <li><span onClick={openPrivacyPopup}>{translate("FOOTER.PRIVACY")}</span></li>
            </ul>
          </div>
        </div>
      </section>
      <ContentDialog />
    </>
  );
}


function mapStateToProps() {
  return {};
}
const mapDispatchToProps = {
  openDialogBox: dialogAction.openDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslate(AppFooter));
