import { metaConstants } from "../actionTypes";

const initState = {
  metaData: [],
  defaultCountry: {
    value: null,
    label: null,
  },

  defaultPhoneCode: [],
  phoneCodeDropdownList: [],
  onlyCountriesDropdownList: [],
};

const metaDataReducer = (state = initState, action) => {
  switch (action.type) {
    case metaConstants.META_REQUEST:
      return state;
    case metaConstants.META_SUCCESS:
      return {
        ...state,
        metaData: action.metaData.countryObject,
        defaultCountry: action.metaData.default_country,
      };
    case metaConstants.META_FAILURE:
      return state;
    case metaConstants.COUNTRY_LIST_REQUEST:
      return state;
    case metaConstants.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        defaultPhoneCode: action.response.default,
        phoneCodeDropdownList: action.response.dropdown,
        onlyCountriesDropdownList: action.response.countriesList,
      };
    case metaConstants.COUNTRY_LIST_FAILURE:
      return state;
    default:
      return state;
  }
};
export default metaDataReducer;
