import {
  verificationConstants,
  otpConstants,
  dialogConstants,
} from "../actionTypes";
import { LOCAL_STORAGE_SERVICE, HELPER } from "../../utils";

const initState = {
  errorCode: null,
  message: null,

  client_verified: false,

  store_url: null,
  merchant_name: null,
  userIdentified: null,
  country_code: null,
  username: null,
  phone: null,
  phone_network_id: null,
  network_code: null,
  otp_timer: null,
  disableOTPTimer: false,
  sendPhoneVerification: false,
  signupUser: 0,

  redirect_url: null,
  signup: null,
  customer_name: null,
  customer_email: null,
  address_count: null,

  showAddressScreen: false,
  retryOtp: false,
};

let phone;
let username;
let store_url;

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case verificationConstants.RESET_DEFAULT_REQUEST:
      return initState;
    case verificationConstants.VERIFY_CLIENT_REQUEST:
      return { ...state, client_verified: true };
    case verificationConstants.VERIFY_CLIENT_SUCCESS:
      const merchant_name = action?.response?.merchant_name;
      store_url = action?.response?.store_url;
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("merchant_name", merchant_name);
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("store_url", store_url);
      return {
        ...state,
        merchant_name,
        store_url,
        client_verified: true,
      };
    case verificationConstants.VERIFY_CLIENT_FAILURE:
      return { ...state, client_verified: false };

    // SHOW ACCESS FORBIDDEN
    case verificationConstants.SHOW_ACCESS_FORBIDDEN:
      return {
        ...state,
        errorCode: action.data?.errorCode,
        message: action.data?.message,
      };

    // VERIFY CLIENT
    // VALIDATE TOKEN
    case verificationConstants.AT_VALIDATION_REQUEST:
      return { ...state };
    case verificationConstants.AT_VALIDATION_SUCCESSFULL:
      phone = action?.data?.user_details?.phone_number;
      username = action?.data?.user_details?.user_name;

      LOCAL_STORAGE_SERVICE._saveToLocalStorage("phone", phone);
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("username", username);

      return {
        ...state,
        userIdentified: action?.data?.user_identified,
        phone,
        username,
      };
    case verificationConstants.AT_VALIDATION_UNSUCCESSFULL:
      return {
        ...state,
        userIdentified: action?.data?.user_identified,
      };
    case verificationConstants.AT_VALIDATION_FAILURE:
      return { ...state };

    // PHONE VERIFICATION
    case verificationConstants.PHONE_VERIFICATION_REQUEST:
      return { ...state, sendPhoneVerification: true };
    case verificationConstants.PHONE_VERIFICATION_SUCCESS:
      const dataAction = action.data;
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("network_code", dataAction?.phone_network_code);
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("country_code", dataAction?.country_code);
      return {
        ...state,
        sendPhoneVerification: false,
        phone: dataAction?.phone,
        phone_network_id: dataAction?.phone_network_id,
        network_code: dataAction?.phone_network_code,
        country_code: dataAction?.country_code,
        otp_timer: dataAction?.otp_timer,
        signupUser: dataAction?.signup,
      };
    case verificationConstants.PHONE_VERIFICATION_FAILURE:
      return { ...state, sendPhoneVerification: false };

    case otpConstants.OTP_VERIFY_REQUEST:
      return {
        ...state,
      };
    case otpConstants.OTP_VERIFY_SUCCESS:
      store_url = action?.response?.store_url;
      LOCAL_STORAGE_SERVICE._saveToLocalStorage("store_url", store_url);
      return {
        ...state,
        redirect_url: action?.response?.redirect_url,
        signup: action?.response?.signup,
        customer_name: action?.response?.customer_name,
        customer_email: action?.response?.customer_email,
        address_count: parseInt(action?.response?.address_count),
        store_url,
      };
    case otpConstants.OTP_VERIFY_FAILURE:
      return {
        ...state,
      };
    case otpConstants.RETRY_OTP_SEND_REQUEST:
      return {
        ...state,
        retryOtp: true,
      };
    case otpConstants.RETRY_OTP_SEND_SUCCESS:
      return {
        ...state,
        retryOtp: false,
      };
    case otpConstants.RETRY_OTP_SEND_FAILURE:
      return {
        ...state,
        disableOTPTimer: true,
        retryOtp: false,
      };

    case dialogConstants.PERMISSION_POPUP_ALLOWED:
      const signup = state?.signup;
      const redirect_url = state?.redirect_url;
      const address_count = state?.address_count;
      let showAddressScreen = false;
      //  POST OTP VERIFY USECASES
      if (signup === 0 && address_count > 0) {
        //  Redirect to url received in API response
        HELPER.redirectToPathAndClearLocalStorage(redirect_url);
        // window.location.href = redirect_url;
      } else if (
        (signup === 1 && address_count === 0) ||
        (parseInt(signup) === 0 && parseInt(address_count) === 0)
      ) {
        showAddressScreen = true;
      }
      return {
        ...state,
        showAddressScreen: showAddressScreen,
      };
    case dialogConstants.PERMISSION_POPUP_DISALLOWED:
      return { ...state };

    default:
      return state;
  }
};
export default clientReducer;
