// @flow

import * as React from "react";
import { SSOWrapper } from "../../components";
import { BREADCRUMB_SETTINGS_JSON } from "../../utils";
import OneTapLoginForm from "./form";

function OneTapLogin() {
  return (
    <SSOWrapper
      child={<OneTapLoginForm />}
      showAccountDropdown={false}
      breadcrumbOptions={
        {
          type: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN.TYPE,
          options: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN,
          show: BREADCRUMB_SETTINGS_JSON.ONE_TAP_LOGIN.SHOW,
        }
      }
    ></SSOWrapper>
  );
}

export default OneTapLogin;
