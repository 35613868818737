/* eslint-disable indent */

import { verificationConstants } from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import { IntlActions } from "react-redux-multilingual";
import {
  CONSTANTS,
  ERROR_MESSAGES,
  ROUTE_CONSTANTS,
  LOCAL_STORAGE_SERVICE,
  HELPER,
  GTM_HELPER,
} from "../../utils";
import { alertActions } from "./index";

export const clientAction = {
  verify_client,
  error_client_access,
  identify_customer,
  reset_default_state,
};

function verify_client(requestData, history) {
  // Parse request data
  return (dispatch) => {
    dispatch(request());
    apiService
      .verifyClient(requestData)
      .then((response) => {
        // LOCAL_STORAGE_SERVICE._removeAccessToken();
        // check response status
        const responseStatus = response?.data?.status;
        const responseData = response?.data?.body;
        const request_id = responseData?.request_id;
        // Success: keep request id received in response,show Enter Phone Number Screen
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          dispatch(IntlActions.setLocale(responseData?.default_lang ?? CONSTANTS.IS_URDU));
          GTM_HELPER._initApp();
          // GTM_HELPER._initApp(request_id);
          // Show Phone Number Screen
            history.push({
              pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
              state: {
                request_id,
              },
            });
          // keep request id received in response
          dispatch(success(responseData));
        }
      })
      .catch((error) => {
        console.log(error);
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          const requestData = {
            errorCode: ERROR_MESSAGES.NETWORK_CONNECTIVITY_STATUS_CODE,
            message: ERROR_MESSAGES.NETWORK_CONNECTIVITY,
          };
          dispatch(request_page(requestData));
          // Show error page
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });
        } else {
          const error_response = error?.response;
          const error_message = error_response?.data?.message;
          const requestData = {
            errorCode: error_response?.data?.status,
            message: error_message,
          };
          dispatch(request_page(requestData));
          // Show error page
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });

          dispatch(failure(error_message));
          dispatch(alertActions.error(error_message));
        }
      });
  };

  function request_page(data) {
    return { type: verificationConstants.SHOW_ACCESS_FORBIDDEN, data };
  }
  function request() {
    return { type: verificationConstants.VERIFY_CLIENT_REQUEST };
  }
  function success(response) {
    return { type: verificationConstants.VERIFY_CLIENT_SUCCESS, response };
  }
  function failure() {
    return { type: verificationConstants.VERIFY_CLIENT_FAILURE };
  }
}

function identify_customer(request_id, history) {
  // Parse request data
  const requestData = JSON.stringify({
    sso_request_id: request_id,
  });

  return (dispatch) => {
    dispatch(request());
    apiService
      .identifyCustomer(requestData)
      .then(async (response) => {
        LOCAL_STORAGE_SERVICE._removeAccessToken();
        // check response status
        const responseStatus = response?.data?.status;
        const responseData = response?.data?.body;
        // Check if [ user_identified = 1/0, user_name=x, phone_number=x, new_access_token=x]
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS &&
          responseData?.user_identified === 1
        ) {
          // get customer information from current_access_token
          // return user name, phone_number (92312*****9), new_access_token in response if customer is_verified=1
          const customer_identifier =
            responseData?.user_details?.customer_identifier;
          const default_lang = responseData?.default_lang;
          dispatch(IntlActions.setLocale(default_lang));
          // show Continue As screen
          history.push({
            pathname: ROUTE_CONSTANTS.ONE_TAP_LOGIN,
            state: {
              prevState: CONSTANTS.PREV_SCREEN.IDENTIFY_CUSTOMER,
              request_id,
              customer_identifier,
            },
          });

          dispatch(verifiedSuccess(responseData));
        } else {
          // if( user_identified ==0), normal flow -- call intialize
          history.push({
            pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
            state: {
              request_id,
            },
          });

          dispatch(unverifiedSuccess(responseData));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          const requestData = {
            errorCode: ERROR_MESSAGES.NETWORK_CONNECTIVITY_STATUS_CODE,
            message: ERROR_MESSAGES.NETWORK_CONNECTIVITY,
          };
          dispatch(request_page(requestData));
          // Show error page
          history.push({
            pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
          });
        } else {
          const error_response = error?.response;
          if (error_response === undefined) {
            dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
            dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          } else {
            const error_message = error_response?.data?.message;
            const error_status = error_response?.data?.status;
            if (error_status === 500) {
              dispatch(failure(error_message));
              dispatch(alertActions.error(error_message));
            } else {
              history.push({
                pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
                state: {
                  request_id,
                },
              });
            }
            dispatch(failure(error_message));
            dispatch(alertActions.error(error_message));
          }
        }
      });
  };

  function request_page(data) {
    return { type: verificationConstants.SHOW_ACCESS_FORBIDDEN, data };
  }
  function request() {
    return { type: verificationConstants.AT_VALIDATION_REQUEST };
  }
  function verifiedSuccess(data) {
    return { type: verificationConstants.AT_VALIDATION_SUCCESSFULL, data };
  }
  function unverifiedSuccess(data) {
    return {
      type: verificationConstants.AT_VALIDATION_UNSUCCESSFULL,
      data,
    };
  }
  function failure() {
    return { type: verificationConstants.AT_VALIDATION_FAILURE };
  }
}

function error_client_access(requestData) {
  return (dispatch) => {
    dispatch(request_page(requestData));
  };
  function request_page(data) {
    return { type: verificationConstants.SHOW_ACCESS_FORBIDDEN, data };
  }
}

function reset_default_state() {
  return (dispatch) => {
    dispatch(request_page());
  };
  function request_page() {
    return { type: verificationConstants.RESET_DEFAULT_REQUEST };
  }
}


