import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Alert } from "tabler-react";
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";

import { HELPER } from "../../utils";
import { alertActions } from "../../store/actions";

let timeoutTime;
let alertExists;

function AlertComponent(props) {
  const { alert } = props;
  const fixed_classes =
    "col mx-auto col-xs-12 col-sm-12 col-md-12 col-lg-12 alert_classes";

  alertExists =
    alert.message &&
    alert.type &&
    alert.message !== null &&
    alert.type !== null;

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      if (alert && !HELPER.isEmpty(alert?.message)) {
        props.clearAlerts();
      }
      clearTimeout(timeoutTime);
    };
  });
  useEffect(() => {
    if (alert && !HELPER.isEmpty(alert?.message)) {
      timeoutTime = setTimeout(() => {
        props.clearAlerts();
      }, 8000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return alertExists ? (
    <div className={fixed_classes}>
      <Zoom in={true}>
        <Paper elevation={4}>
          <Alert type={`${alert.type}`}>{alert.message}</Alert>
        </Paper>
      </Zoom>
    </div>
  ) : (
    ""
  );
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const mapDispatchToProps = {
  clearAlerts: alertActions.clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
