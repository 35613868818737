import React from "react";
import { CheckoutWrapper } from "../../../components";
import AddressForm from "./form";

function AddNewAddress() {
  //  This functional component specifies to show map component and contain add new address form
  return <CheckoutWrapper showMap={true} child={<AddressForm />} />;
}

export default AddNewAddress;
