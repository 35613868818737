/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars, new-cap */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import {
  AppWrapper,
  LoadingButton,
  Alert,
} from "../../components/index";
import OtpInput from "react-otp-input";
import ReactHtmlParser from "react-html-parser";
import { otpAction, alertActions, clientAction } from "../../store/actions";
import {
  CONSTANTS,
  ERROR_MESSAGES,
  ROUTE_CONSTANTS,
  GTM_HELPER,
  HELPER,
  BREADCRUMB_SETTINGS_JSON,
} from "../../utils";

let timeoutTime;

function OTP(props) {
  const {
    history,
    location,
    country_code,
    translate,
    phone,
    network_code,
    showBtnLoader,
    retryOtp,
  } = props;


  // Get request Id from location parameter
  const request_id = location?.state?.request_id;
  const digitLimit = 6;

  // State assign
  const [showPage, setShowPage] = useState(false);
  const [verify_otp, setVerify_otp] = useState("");
  // const [network_id,setNetwork_id] = useState(null);

  const submitOTP = (e) => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.OTP,
        {
          action: GTM_HELPER.GTM_EVENT.OTP.SUBMIT.ACTION,
          label: GTM_HELPER.GTM_EVENT.OTP.SUBMIT.LABEL,
          properties: {},
        },
    );

    e.preventDefault();
    // Validate otp code length and then call verify otp api
    if (verify_otp.length === digitLimit && !showBtnLoader) {
      const requestData = {
        otp: verify_otp,
        sso_request_id: request_id,
      };
      props.submitOtp(requestData, history, location);
    } else if (!showBtnLoader) {
      props.errorAlerts(translate("otp_validation_message"));
      timeoutTime = setTimeout(() => {
        props.clearAlerts();
      }, 7000);
    }
  };

  useEffect(() => {
    GTM_HELPER._appFlow(2, GTM_HELPER.GTM_EVENT_CATEGORY.OTP);
    //  Check if my session has request_id parameter
    if (HELPER.isEmpty(request_id)) {
      showErrorPage(400, CONSTANTS.AUTHENTICATION_PARAMETERS.REQUEST_ID);
    } else {
      setShowPage(true);
    }
    // returned function will be called on component unmount
    return () => {
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    retryOtp ? setVerify_otp("") : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryOtp]);

  const showErrorPage = (errorCode, param_identifier) => {
    // History get for redirection
    let message;
    if (param_identifier === CONSTANTS.AUTHENTICATION_PARAMETERS.REQUEST_ID) {
      message = ERROR_MESSAGES.REQUEST_ID_NOT_FOUND;
    }
    const state = {
      errorCode,
      message,
    };
    props.errorClientAccess(state, history);
    // Show error page
    history.push({
      pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
    });
  };

  const moveBackwards = (e) => {
    history.push({
      pathname: ROUTE_CONSTANTS.PHONE_SCREEN,
      state: {
        request_id,
      },
    });
  };

  const handleChange = (e) => {
    if (verify_otp.length === 1) {
      // LOAD GTM EVENT
      GTM_HELPER._generalEventLog(
          GTM_HELPER.GTM_EVENT_CATEGORY.OTP,
          {
            action: GTM_HELPER.GTM_EVENT.OTP.INPUT.ACTION,
            label: GTM_HELPER.GTM_EVENT.OTP.INPUT.LABEL,
            properties: {},
          },
      );
    }
    setVerify_otp(e.replace(/,/g, ""));
  };


  // const get_network_name = HELPER.checkLocalStorage(
  //   "network_name": ""
  //   !HELPER.isEmpty(network_name) ? network_name : "Unidentified network"
  // );
  const get_network_name = "";
  const formatted_phone_number = "+" + country_code + "(" + network_code + ")" + phone;
  const otp_placeholder = translate("otp_placeholder", {
    network_name: get_network_name,
    phone_number: formatted_phone_number,
  });
  const otpPlaceholder = HELPER.otpTextFormatting(otp_placeholder, get_network_name, formatted_phone_number);
  return (
    <>
      <AppWrapper
        showAccountDropdown={false}
        showLangIcon={true}
        showPoweredBy={false}
        showFooter={false}
        showHeader={true}
        breadcrumb={
          {
            type: BREADCRUMB_SETTINGS_JSON.OTP.TYPE,
            options: BREADCRUMB_SETTINGS_JSON.OTP,
            show: BREADCRUMB_SETTINGS_JSON.OTP.SHOW,
          }}
      >
        <div className="screen midWrap bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="signInView midView bg-white">
                  <h1>{translate("otp_title")}</h1>
                  <Alert />
                  <form className="myForm" onSubmit={submitOTP} id="otp-form">
                    <p className="Otptext">
                      {ReactHtmlParser(otpPlaceholder)}
                      {/* <span className="num">
                        <CustomerNumber />
                      </span> */}
                      {translate("otp_click_here")}
                      {/* . <NotOnNetwork /> */}
                    </p>

                    <div className="OtpNum">
                      <div className="OTPPin">
                        <div
                          id="otp-pin-input"
                          className="OTPPinNo otpScreen"
                          data-pin
                        >
                          <OtpInput
                            value={verify_otp}
                            shouldAutoFocus={true}
                            onChange={handleChange}
                            numInputs={digitLimit}
                            isInputNum={true}
                            name="verify_otp"
                            autoComplete="one-time-code"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="optionBtn">
                      <button
                        type="button"
                        id="back-btn"
                        className="btn btn-outline-primary width150"
                        onClick={moveBackwards}
                      >
                        {translate("back_btn")}
                      </button>
                      <button
                        type="submit"
                        id="otp-submit"
                        className="btn btn-primary width150"
                        disabled={
                          verify_otp.length !== digitLimit || showBtnLoader ? true : false
                        }
                      >
                        <LoadingButton
                          loadingState={showBtnLoader}
                          preloadingText={translate("next_btn")}
                          loadingText={translate("next_btn")}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
}

function mapStateToProps(state) {
  const { showBtnLoader } = state.loading;
  const {
    country_code,
    phone,
    network_code,
    customer_name,
    customer_email,
    showAddressScreen,
    retryOtp,
  } = state.client;
  return {
    country_code,
    phone,
    network_code,
    customer_name,
    customer_email,
    showAddressScreen,
    showBtnLoader,
    retryOtp,
  };
}
const mapDispatchToProps = {
  submitOtp: otpAction.verify_otp,
  errorAlerts: alertActions.error,
  successAlerts: alertActions.success,
  clearAlerts: alertActions.clear,
  resendOTP: otpAction.resend_otp,
  errorClientAccess: clientAction.error_client_access,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(OTP)));
