import React, { useEffect } from "react";
import { AppWrapper } from "../index";
import { withRouter } from "react-router-dom";
import CheckoutMap from "./map";
import {
  BREADCRUMB_SETTINGS_JSON,
  ROUTE_CONSTANTS as routeName,
} from "../../utils";
import MandatoryPin from "../MandatoryPin";
import { mapActions } from '../../store/actions';
import { connect } from "react-redux";

function CheckoutWrapper(props) {
  const { child, showMap, fullscreenModeEnabled, location, locationPermission, updateLocationPermission } = props;

  const path = location?.pathname;
  const ADDRESS_ROUTES =
    path === routeName.ADD_NEW_ADDRESS ||
    path === routeName.MAP_FULL_SCREEN;

  const showChildObject = () => {
    return (
      <>
        <div className={fullscreenModeEnabled ? "fs-container" : "container"}>
          <div className="row">
            <div className="col-sm-12">
              <div className="signInView bg-white">{child}</div>
            </div>
          </div>

        </div>
      </>
    );
  };

  function handlePermission() {
    function successCallback() {
      locationPermission !== CONSTANTS.PERMISSIONS.GRANTED && updateLocationPermission(CONSTANTS.PERMISSIONS.GRANTED);
    }
    function errorCallback() {
      locationPermission !== CONSTANTS.PERMISSIONS.DENIED && updateLocationPermission(CONSTANTS.PERMISSIONS.DENIED);
    }

    if (navigator?.permissions?.query) {
      navigator.permissions.query({ name: "geolocation" })
        .then(function(result) {
          updateLocationPermission(result.state);
        });
    } else {
      // for mobile ios resolved ask permission again on refresh if blocked
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      }
    }
  }
  useEffect(() => {
    if (ADDRESS_ROUTES) {
      // get permission
      handlePermission();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper
      showHeader={true}
      showAccountDropdown={true}
      showLangIcon={true}
      showFooter={false}
      breadcrumb={{
        type: BREADCRUMB_SETTINGS_JSON.CHECKOUT.TYPE,
        options: BREADCRUMB_SETTINGS_JSON.CHECKOUT,
        show: BREADCRUMB_SETTINGS_JSON.CHECKOUT.SHOW,
      }}
    >
      {ADDRESS_ROUTES ? <MandatoryPin /> : ""}
      {!fullscreenModeEnabled ? (
        <div className="screen bg-white">
          <CheckoutMap showMap={showMap} />
          {showChildObject()}
        </div>
      ) : (
        showChildObject()

      )}
    </AppWrapper>
  );
}

function mapStateToProps(state) {
  const { locationPermission } = state.map;
  return { locationPermission };
}
const mapDispatchToProps = {
  updateLocationPermission: mapActions.UPDATE_PERMISSION_LOCATION,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutWrapper));
