import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { HELPER } from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modal(props) {
  const {
    setModel,
    onCloseHandler,
    onCancelHandlder,
    onSuccessHandler,
    modal,
  } = props;
  const [open, setOpen] = useState(setModel);

  useEffect(() => {
    setOpen(props.setModel);
  }, [props]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {HELPER.isEmpty(modal.title) ? (
          ""
        ) : (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              {modal.title}
            </DialogTitle>
          </>
        )}

        {HELPER.isEmpty(modal.content) ? (
          ""
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {modal.content}
              </DialogContentText>
            </DialogContent>
          </>
        )}

        <DialogActions>
          {modal.cancelBtn ? (
            <Button
              onClick={onCancelHandlder}
              color="primary"
              className="btn btn-outline-white"
            >
              {modal.cancelBtn}
            </Button>
          ) : (
            ""
          )}
          {modal.successBtn ? (
            <Button
              onClick={onSuccessHandler}
              color="primary"
              className="btn btn-white btn-white-B"
            >
              {modal.successBtn}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    setModel,
    onCloseHandler,
    onCancelHandlder,
    onSuccessHandler,
    modal,
  } = state.action;
  return {
    setModel,
    onCloseHandler,
    onCancelHandlder,
    onSuccessHandler,
    modal,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
