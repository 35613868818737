/* eslint-disable no-invalid-this */

import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { GoogleMap as ReactGoogleMap, Marker } from "react-google-maps";
import { isMobile } from "react-device-detect";

import { Modal } from "../../../components";
import MapControl from "./control";
import FullScreenControl from "./fullscreen";

import { mapActions, addressAction } from "../../../store/actions";
import {
  HELPER,
  IMAGE_SRC,
  CONSTANTS,
  ROUTE_CONSTANTS,
} from "../../../utils";
import { withRouter } from "react-router";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    const { translate } = this.props;
    this.state = {
      lat: 24.8607,
      lng: 67.0011,
      defaultLat: null,
      defaultLng: null,
      disable: false,
      isMarkerShown: false,
      bounds: false,
      locationPopupTile: translate("browser_location_enable_text"),
      mapTitle: translate("find_geo_location"),
    };
    this.handleGetMyLocation = this.handleGetMyLocation.bind(this);
    this.handleMapFullScreenControl = this.handleMapFullScreenControl.bind(this);
  }

  showMarkerConfig() {
    const { p } = this.props;
    const { defaultLat, defaultLng } = this.state;
    if ((!HELPER.isEmpty(defaultLat) || !HELPER.isEmpty(defaultLng)) && p.isMarkerShown) {
      this.setState({ isMarkerShown: true });
    } else {
      this.setState({ isMarkerShown: false });
    }
  }

  componentWillMount() {
    const {
      p,
      updateCoordinatesLat,
      updateCoordinatesLng,
      history,
      enableFullScreenMode,
      setCoordinatesLat,
      setCoordinatesLng,
      location,
      metaData,
      getMyLocation,
    } = this.props;
    const { defaultLat, defaultLng } = this.state;
    this.initAutocomplete();

    if (getMyLocation) {
      this.handleGetMyLocation();
      this.props.getPinLocation(false);
    }

    if ((!HELPER.isEmpty(defaultLat) || !HELPER.isEmpty(defaultLng)) && p.isMarkerShown) {
      this.setState({ isMarkerShown: true });
    } else {
      this.setState({ isMarkerShown: false });
    }

    if (location.pathname === ROUTE_CONSTANTS.ADD_NEW_ADDRESS && HELPER.isEmpty(setCoordinatesLat) && !HELPER.isEmpty(metaData)) {
      this.handleGetMyLocation();
    }

    if (!HELPER.isEmpty(updateCoordinatesLat)) {
      const lat = parseFloat(updateCoordinatesLat);
      const lng = parseFloat(updateCoordinatesLng);
      const bounds = new window.google.maps.LatLngBounds();
      const positionLatLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(positionLatLng);
      this.setState({
        defaultLat: lat,
        defaultLng: lng,
        isMarkerShown: true,
        bounds,
      });
      this.props.saveAddressLatLng(updateCoordinatesLat, updateCoordinatesLng);
    }
    if (!HELPER.isEmpty(setCoordinatesLat)) {
      const lat = parseFloat(setCoordinatesLat);
      const lng = parseFloat(setCoordinatesLng);
      const bounds = new window.google.maps.LatLngBounds();
      const positionLatLng = new window.google.maps.LatLng(lat, lng);
      bounds.extend(positionLatLng);
      this.setState({
        defaultLat: lat,
        defaultLng: lng,
        isMarkerShown: true,
        bounds,
      });
      // this.props.saveAddressLatLng(updateCoordinatesLat, updateCoordinatesLng);
    }
    if (enableFullScreenMode) {
      history.push({
        pathname: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
      });
      this.props.setFullScreen(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { translate } = this.props;
    const {
      updateCoordinatesLat,
      updateCoordinatesLng,
      find_geo_location,
      getMyLocation,
      clearAddress,
      metaData,
      setCoordinatesLat,
    } = nextProps;
    if (location.pathname === ROUTE_CONSTANTS.ADD_NEW_ADDRESS && HELPER.isEmpty(setCoordinatesLat) && !HELPER.isEmpty(metaData)) {
      this.handleGetMyLocation();
    }

    if (getMyLocation) {
      this.handleGetMyLocation();
      this.props.getPinLocation(false);
    }

    if (find_geo_location) {
      this.setState({
        mapTitle: translate("found_geo_location"),
      });
    }

    if (this.props.updateCoordinatesLat !== nextProps.updateCoordinatesLat) {
      this.setState({
        lat: updateCoordinatesLat,
        lng: updateCoordinatesLng,
        disable: true,
      }, this.showMarkerConfig);
      if (!HELPER.isEmpty(updateCoordinatesLat) && !HELPER.isEmpty(updateCoordinatesLng)) {
        this.props.saveAddressLatLng(updateCoordinatesLat, updateCoordinatesLng);
      }
    }
    if (clearAddress) {
      this.setState({
        defaultLat: null,
        defaultLng: null,
        isMarkerShown: false,
      });
    }
  }

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const bounds = new window.google.maps.LatLngBounds();
    const positionLatLng = new window.google.maps.LatLng(lat, lng);
    bounds.extend(positionLatLng);
    this.map.panTo({ lat, lng });
    this.setState({
      defaultLat: lat,
      defaultLng: lng,
      bounds,
    });
    this.props.saveAddressLatLng(lat, lng);
    const requestData = {
      latitude: lat,
      longitude: lng,
    };
    this.props.getLocationDetails(requestData);
  };

  handleGetMyLocation = (e) => {
    const thisref = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const bounds = new window.google.maps.LatLngBounds();
        const positionLatLng = new window.google.maps.LatLng(lat, lng);
        bounds.extend(positionLatLng);
        if (!HELPER.isEmpty(thisref.map)) {
          thisref.map.panTo({ lat, lng });
        }
        thisref.setState({
          defaultLat: lat,
          defaultLng: lng,
          isMarkerShown: true,
          bounds,
        });
        thisref.props.saveAddressLatLng(lat, lng);
        const requestData = {
          latitude: lat,
          longitude: lng,
        };
        thisref.props.getLocationDetails(requestData);
      }, (error) => {
        const thisref = this;
        if (error.code === 1) {
          thisref.props.locationPermission !== CONSTANTS.PERMISSIONS.DENIED && thisref.props.permissionLocation(CONSTANTS.PERMISSIONS.DENIED);
          if (window.location?.pathname !== ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
            thisref.props.disableLocationPermissionPopup(thisref.state.locationPopupTile);
          }
        }
        console.error("Error Code = " + error.code + " - " + error.message);
      }, { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.defaultLat !== nextState.defaultLat ||
      this.state.mapTitle !== nextState.mapTitle
    ) {
      return true;
    } else {
      // force our map to never update after the initial load. If there is a case where you do want it to update, put that logic here
      return false;
    }
  }

  initAutocomplete = () => {
    const element = document.getElementById('map-address');
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "formatted_address", "geometry"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(element, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        } else {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const thisref = this;
          const lat = coordinates?.lat;
          const lng = coordinates?.lng;
          const bounds = new window.google.maps.LatLngBounds();
          const positionLatLng = new window.google.maps.LatLng(lat, lng);
          bounds.extend(positionLatLng);
          if (!HELPER.isEmpty(thisref.map)) {
            thisref.map.panTo({ lat, lng });
            thisref.setState({
              defaultLat: lat,
              defaultLng: lng,
              isMarkerShown: true,
              bounds,
            });
          }
          if (!HELPER.isEmpty(lat) && !HELPER.isEmpty(lng)) {
            thisref.props.saveAddressLatLng(lat, lng);
            thisref.props.updatedAddressLatLng(parseFloat(lat), parseFloat(lng));
          }
          const requestData = {
            latitude: lat,
            longitude: lng,
          };
          thisref.props.getLocationDetails(requestData);
        }
      });
    }
  };

  handleMapFullScreenControl = (e) => {
    const { history, location } = this.props;
    if (location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
      history.goBack();
    } else {
      history.push({
        pathname: ROUTE_CONSTANTS.MAP_FULL_SCREEN,
      });
    }
  };

  render() {
    const {
      lat,
      lng,
      isMarkerShown,
      defaultLat,
      defaultLng,
      bounds,
      mapTitle,
    } = this.state;
    const { locale, location } = this.props;
    const en_mapTypeControlOptions = location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN ? window.google.maps.ControlPosition.TOP_RIGHT : window.google.maps.ControlPosition.TOP_LEFT;
    const ur_mapTypeControlOptions = location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN ? window.google.maps.ControlPosition.TOP_LEFT : window.google.maps.ControlPosition.TOP_RIGHT;
    return (
      <div>
        <ReactGoogleMap
          ref={(map) => {
            this.map = map;
            if (map && bounds && isMarkerShown) {
              map.fitBounds(bounds);
            }
          }}
          defaultZoom={10}
          disableDefaultUI={true}
          defaultCenter={{ lat, lng }}
          options={{
            draggable: true, // make map draggable
            panControl: false,
            mapTypeControl: isMobile ? false : true,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            scrollwheel: false,
            zoomControl: true,
            fullscreenControl: false,
            maxZoom: 18,
            mapTypeControlOptions: {
              position: locale === CONSTANTS.IS_URDU ? ur_mapTypeControlOptions : en_mapTypeControlOptions,
            },
            panControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT,
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT,
              style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
          }}
        >
          <>
            <FullScreenControl
              position={locale === CONSTANTS.IS_URDU ? window.google.maps.ControlPosition.TOP_LEFT : window.google.maps.ControlPosition.RIGHT_TOP}
              parentClass="mapTopLayer"
            >
              <div id="fullScreenToggleBtn">
                <div
                  className="fullscreen-btn"
                  onClick={this.handleMapFullScreenControl}
                  id="map-fullscreen"
                  dir="ltr"
                >
                  <button
                    title="Toggle fullscreen view"
                    aria-label="Toggle fullscreen view"
                    type="button"
                    className={"fullscreen-control"}
                  >
                    <img
                      src={IMAGE_SRC.MAP_FULL_SCREEN_CONTROL}
                      alt="Toggle fullscreen view"
                    />
                  </button>
                </div>
              </div>
            </FullScreenControl>
            <MapControl
              position={window.google.maps.ControlPosition.RIGHT_BOTTOM}
              parentClass="ribbionParent"
            >
              <div className="mapRibbion"
                onClick={this.handleGetMyLocation}
              >
                <p>{mapTitle} </p>
              </div>
              <div
                className="mappinbtn"
                onClick={this.handleGetMyLocation}
                id="get-my-location"
                dir="ltr"
              >
                <button type="button">
                  <img src={IMAGE_SRC.BLACK_MAP_PIN} alt="map location" />
                </button>
              </div>
            </MapControl>
          </>
          {isMarkerShown && (
            <Marker
              position={{
                lat: parseFloat(defaultLat),
                lng: parseFloat(defaultLng),
              }}
              draggable={true}
              onDragEnd={(e) => this.onMarkerDragEnd(e)}
            />
          )}
        </ReactGoogleMap>
        <Modal />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { metaData } = state.meta;
  const { updateCoordinatesLat, updateCoordinatesLng, setCoordinatesLng,
    setCoordinatesLat, enableFullScreenMode, getMyLocation, clearAddress } = state.map;
  const { find_geo_location } = state.address;
  const { locale } = state.Intl;
  return { metaData, updateCoordinatesLat, updateCoordinatesLng, find_geo_location, enableFullScreenMode, getMyLocation, clearAddress, setCoordinatesLat, setCoordinatesLng, locale };
}
const mapDispatchToProps = {
  saveAddressLatLng: mapActions.set_position,
  getLocationDetails: addressAction.get_location_detail,
  disableLocationPermissionPopup:
    addressAction.disable_location_permission_popup,
  setFullScreen: mapActions.SET_FULL_SCREEN_MODE,
  updatedAddressLatLng: mapActions.update_position,
  getPinLocation: mapActions.GET_LOCATION_PIN,
  permissionLocation: mapActions.UPDATE_PERMISSION_LOCATION,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(MapComponent)));
