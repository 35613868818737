import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslate } from "react-redux-multilingual";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import {
  GTM_HELPER,
} from "../../../utils";

const DialogTitle = ((props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} id="terms-dialog-title">
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="btn-primary closeright"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function TermsDialog(props) {
  const { translate } = props;

  useEffect(() => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
        {
          action: GTM_HELPER.GTM_EVENT.NAVIGATION.POPUP.TERMS.ACTION,
          label: GTM_HELPER.GTM_EVENT.NAVIGATION.POPUP.TERMS.LABEL,
          properties: {},
        },
    );
  }, []);

  return (
    <>
      <DialogTitle onClose={() => props.handleClose()}></DialogTitle>
      <DialogContent id="terms-dialog-content">
        <Typography gutterBottom variant={"h5"}>
          {translate("DIALOG_CONTENT.TERMS.HEADING")}
        </Typography>
        <Typography gutterBottom>
          {translate("DIALOG_CONTENT.TERMS.CONTENT_1")}
          <br />
          {translate("DIALOG_CONTENT.TERMS.CONTENT_2")}
        </Typography>
      </DialogContent>
    </>
  );
}

export default (withTranslate(TermsDialog));
