/* eslint-disable spaced-comment */
import { HELPER, CONSTANTS, ROUTE_CONSTANTS } from "../../utils";
import { addressConstants, mapConstants, verificationConstants } from "../actionTypes";

const initState = {
  // Add Address Lat Lng
  setCoordinatesLat: null,
  setCoordinatesLng: null,
  // Add Address Lat Lng Full Screen
  setCoordinatesLatMap: "",
  setCoordinatesLngMap: "",
  // Edit Address LAt Lng
  updateCoordinatesLat: null,
  updateCoordinatesLng: null,

  searchPlace: "",
  getMyLocation: false,
  //  Full screen mode
  enableFullScreenMode: false,
  clearAddress: false,
  locationPermission: CONSTANTS.PERMISSIONS.PROMPT,
};
const mapReducer = (state = initState, action) => {
  const fullScreen=window.location.pathname === ROUTE_CONSTANTS.MAP_FULL_SCREEN;

  switch (action.type) {
    case mapConstants.ADD_ADDRESS_LAT_LNG_REQUEST:
      return {
        ...state,
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        setCoordinatesLat: fullScreen ? state.setCoordinatesLat : action?.lat,
        setCoordinatesLng: fullScreen ? state.setCoordinatesLng : action?.lng,
        clearAddress: false,
        //full screen Map
        setCoordinatesLatMap: fullScreen ? action?.lat : "",
        setCoordinatesLngMap: fullScreen ? action?.lng : "",
      };
   //lat lng save on confirm button
    case addressConstants.CONFIRM_ADDRESS_FULLSCREEN_MAP:
      return {
       ...state,
       updateCoordinatesLat: "",
       updateCoordinatesLng: "",
       setCoordinatesLat: state?.setCoordinatesLatMap || state?.setCoordinatesLat,
       setCoordinatesLng: state?.setCoordinatesLngMap || state?.setCoordinatesLng,
       clearAddress: false,
       //full screen Map
       setCoordinatesLatMap: "",
       setCoordinatesLngMap: "",
     };
    case mapConstants.UPDATE_ADDRESS_LAT_LNG_REQUEST:
      return {
        ...state,
        updateCoordinatesLat: action?.lat,
        updateCoordinatesLng: action?.lng,
        clearAddress: false,
      };
    case mapConstants.FULL_SCREEN_MODE:
      return {
        ...state,
        enableFullScreenMode: action?.state,
        clearAddress: false,
      };
    case mapConstants.GET_MY_LOCATION:
      return {
        ...state,
        getMyLocation: action?.state,
        clearAddress: false,
      };
    case verificationConstants.VERIFY_CLIENT_SUCCESS:
      const is_pin_location_mandatory = HELPER.stringToBoolean(action?.response?.is_pin_location_mandatory);
      return {
        ...state,
        enableFullScreenMode: is_pin_location_mandatory,
      };
    case addressConstants.EMPTY_LOCATION_DETAILS:
      return {
        ...state,
        // Add Address Lat Lng
        setCoordinatesLat: fullScreen ? state.setCoordinatesLat : "",
        setCoordinatesLng: fullScreen ? state.setCoordinatesLng : "",
        // Edit Address LAt Lng
        updateCoordinatesLat: "",
        updateCoordinatesLng: "",
        searchPlace: fullScreen && state.setCoordinatesLng ? state.searchPlace : "",
        clearAddress: fullScreen && state.setCoordinatesLng ? false : true,
        //full screen Map
        setCoordinatesLatMap: "",
        setCoordinatesLngMap: "",
      };
    case mapConstants.UPDATE_PLACE_SEARCH_REQUEST:
      return {
        ...state,
        searchPlace: action?.address,
      };
    case mapConstants.INITIATE_MAP:
      return {
        ...state,
        clearAddress: false,
      };
    case addressConstants.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        searchPlace: action.data.address,
      };
    case mapConstants.UPDATE_PERMISSION_LOCATION:
      return {
        ...state,
        locationPermission: action?.locationPermission,
      };
    default:
      return state;
  }
};
export default mapReducer;
