// @flow

import * as React from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import {
  ROUTE_CONSTANTS,
  CHECKOUT_NAVIGATION,
} from "../../../../../utils";

let currentPage;
let addressIcon;
let verificationIcon;
const NAVIGATION_STEP_COUNT = CHECKOUT_NAVIGATION.NORMAL_CHECKOUT.STEP_COUNT;
const ADDRESS_SCREEN = NAVIGATION_STEP_COUNT.ADDRESS_SCREEN;


function CheckoutBreadcrumb(props) {
  const {
    location,
    translate,
  } = props;

  const path = location.pathname;


  const NAVIGATION_ICON_LIST = CHECKOUT_NAVIGATION.ICONS;

  const VERIFICATION_ROUTES = path === ROUTE_CONSTANTS.PHONE_SCREEN;

  const ADDRESS_ROUTES =
    path === ROUTE_CONSTANTS.ADD_NEW_ADDRESS ||
    path === ROUTE_CONSTANTS.MAP_FULL_SCREEN;

  const activeClass =
    "active activeText animate__animated animate__fadeInRight";

  currentPage = ADDRESS_ROUTES ? ADDRESS_SCREEN : NAVIGATION_STEP_COUNT.IDLE_SCREEN;


  verificationIcon = VERIFICATION_ROUTES || currentPage > NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? NAVIGATION_ICON_LIST.VERIFICATION : "stepNo";
  addressIcon = ADDRESS_ROUTES || currentPage > ADDRESS_SCREEN ? NAVIGATION_ICON_LIST.ADDRESS : "stepNo";

  return (
    <div className={`stepStatus`}>
      <div className={`stepIcons ${VERIFICATION_ROUTES ? activeClass : ""}`}>
        <div className={`icon ${VERIFICATION_ROUTES ? "" : currentPage < NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? "" : "iconPrev"}`}>
          <span className={`${verificationIcon} ${currentPage === NAVIGATION_STEP_COUNT.VERIFICATION_SCREEN ? "iconW" : "iconT "}`}></span>
        </div>
        <div className="statusText">
          {translate("address_breadcrumb_heading")}
        </div>
      </div>
      <div
        className={`stepIcons ${ADDRESS_ROUTES ? activeClass : ""} ${currentPage > ADDRESS_SCREEN ? "clickable" : ""}`}
      >
        <div className={`icon ${ADDRESS_ROUTES ? "" : currentPage < ADDRESS_SCREEN ? "" : "iconPrev"}`}>
          <span className={`${addressIcon} ${currentPage === ADDRESS_SCREEN ? "iconW" : "iconT "}`}>
            {`${ADDRESS_ROUTES || currentPage > ADDRESS_SCREEN ? "" : ADDRESS_SCREEN}`}
          </span>
        </div>
        <div className="statusText">
          {translate("address_breadcrumb_heading")}
        </div>
      </div>
    </div>
  );
}


export default withRouter(withTranslate(CheckoutBreadcrumb));
