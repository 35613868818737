import { HELPER, LOCAL_STORAGE_SERVICE } from "../../utils";
import { languageConstants } from "../actionTypes";
import { IntlActions } from "react-redux-multilingual";

export const languageActions = {
  openLanguagePopup,
  closeLanguagePopup,
  updateLanguage,
};
// TERMS & CONDITIONS
function openLanguagePopup() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: languageConstants.LANGUAGE_POPUP_OPEN };
  }
}

function closeLanguagePopup() {
  return (dispatch) => {
    dispatch(close());
  };

  function close() {
    return { type: languageConstants.LANGUAGE_POPUP_CLOSE };
  }
}

function updateLanguage(lang_locale) {
  return (dispatch) => {
    if (!HELPER.isEmpty(lang_locale)) {
      LOCAL_STORAGE_SERVICE._updateInLocalStorage("locale", lang_locale);
      dispatch(IntlActions.setLocale(lang_locale));
      dispatch(set_locale(lang_locale));
    }
  };

  function set_locale(lang_locale) {
    return {
      type: languageConstants.UPDATE_LANGUAGE_LOCALE,
      locale: lang_locale,
    };
  }
}
