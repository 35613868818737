import React from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

function MandatoryPin(props) {
  const { is_pin_location_mandatory, translate } = props;

  return (
    is_pin_location_mandatory ?
      <div id="mandatoryPin">
        <div className="pinStripe">
          <p>
            {translate("mandatory_pin_placeholder")}
          </p>
        </div>
      </div> : ""
  );
}

function mapStateToProps(state) {
  const { is_pin_location_mandatory } = state.config;
  return { is_pin_location_mandatory };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(MandatoryPin));
