/* eslint-disable comma-dangle, prefer-const, new-cap, indent */

import {
  API_REQUEST,
  API_ENDPOINTS
} from "../../utils";

const baseURL = process.env.REACT_APP_API_URL;

export const apiService = {
  // VERIFY CLIENT
  verifyClient,
  identifyCustomer,
  // OTP
  verifyOtp,
  resendOtp,
  sendOtp,
  // Addresses
  addAddress,
  getLocationDetails,

  getMetaData,
  getCountryDropdownList,
};

/*
 *  VERIFY CLIENT
 */

async function verifyClient(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.VERIFY_CLIENT,
    false
  )(requestData);
  return response;
}

async function identifyCustomer(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.IDENTIFY_CUSTOMER,
    true,
    false
  )(requestData);
  return response;
}
/*
 * OTP
 */
async function verifyOtp(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.OTP_VERIFY,
    true
  )(requestData);
  return response;
}
async function resendOtp(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.OTP_RESEND,
    true
  )(requestData);
  return response;
}
async function sendOtp(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.OTP_SEND,
    true
  )(requestData);
  return response;
}

/*
 * Addresses
 */

async function addAddress(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.ADD_ADDRESS,
    true,
    true
  )(requestData);
  return response;
}

async function getLocationDetails(requestData) {
  let response = await API_REQUEST(
    "post",
    `${baseURL}` + API_ENDPOINTS.GET_LOCATION_DETAILS,
    true,
    true
  )(requestData);
  return response;
}

/*
 *META DATA APIS
 */
async function getMetaData() {
  let response = await API_REQUEST(
    "get",
    `${baseURL}` + API_ENDPOINTS.GET_META,
    false,
    true
  )();
  return response;
}

async function getCountryDropdownList() {
  let response = await API_REQUEST(
    "get",
    `${baseURL}` + API_ENDPOINTS.GET_COUNTRY_DROPDOWN_LIST,
    false,
    true
  )();
  return response;
}
