import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  Dialog,
  Slide,
} from "@material-ui/core";

import TermsDialog from "./Terms";
import PrivacyDialog from "./Privacy";

import { dialogAction } from "../../store/actions";
import { CONSTANTS } from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function ContentDialog(props) {
  const { setOpen, dialogType } = props;
  const [open, unsetOpen] = React.useState(setOpen);

  useEffect(() => {
    unsetOpen(props.setOpen);
  }, [props]);

  const handleClose = () => {
    unsetOpen(false);
    props.closeDialogBox();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          className: CONSTANTS.POPUP_TYPE.TERMS ? "TermsPopup" : "PrivacyPopup",
        }}
      >
        {dialogType === CONSTANTS.POPUP_TYPE.TERMS ? <TermsDialog handleClose={handleClose} /> : <PrivacyDialog handleClose={handleClose} />}
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  const { setOpen, popupType } = state.action;
  return {
    setOpen,
    dialogType: popupType,
  };
}

const mapDispatchToProps = {
  closeDialogBox: dialogAction.closeDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDialog);
