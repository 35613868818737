/* eslint-disable semi */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { scroller } from "react-scroll";

import { Alert } from "../../../components";
import { AddAddressForm } from "../index";
import {
  HELPER,
  CONSTANTS,
  VALIDATION_HELPER,
  GTM_HELPER,
} from "../../../utils";
import {
  verificationAction,
  addressAction,
  alertActions,
  formAction,
} from "../../../store/actions";

let timeoutTime;
const defaultDropdownState = {
  value: null,
  label: null,
};
const defaultErrorState = {
  name: "",
  email: "",
  country_id: "",
  province_id: "",
  city_id: "",
  area_id: "",
  address: "",
};
const validation_errors = {
  name: "",
  email: "",
  country_id: "",
  province_id: "",
  city_id: "",
  area_id: "",
  address: "",
};

function AddressForm(props) {
  const {
    history,
    metaData,
    setCoordinatesLat,
    setCoordinatesLng,
    locationDetails,
    defaultCountry,
    alert,
    translate,
    location,
    locale,
    is_pin_location_mandatory,
    showBtnLoader,
    name,
    email,
  } = props;

  const [customer_name, setCustomerName] = useState(name);
  const [disableName, setDisableName] = useState(false);
  const [customer_email, setCustomerEmail] = useState(email);
  const [disableEmail, setDisableEmail] = useState(false);
  const [country_id, setCountry_id] = useState(defaultDropdownState);
  const [province_id, setProvince_id] = useState(defaultDropdownState);
  const [city_id, setCity_id] = useState(defaultDropdownState);
  const [area_id, setArea_id] = useState(defaultDropdownState);
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState(defaultErrorState);
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [meta, setMetaData] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [is_default, setIs_default] = useState(1);
  const [zip_code, setzip_code] = useState("");

  const state = {
    country_id,
    province_id,
    city_id,
    area_id,
    address,
    errors,
    countryData,
    citiesData,
    provinceData,
    areasData,
    latitude,
    longitude,
    meta,
    is_default,
    translate,

  };
  const addressData = location?.state?.data;

  const errorName = HELPER.isEmpty(errors.name) ? false : true;
  const errorEmail = HELPER.isEmpty(errors.email) ? false : true;
  let alertComponent = null;
  if (alert !== {}) {
    alertComponent = alert.message && alert.type && <Alert alert={alert} />;
  }

  useEffect(() => {
    GTM_HELPER._appFlow(2, GTM_HELPER.GTM_EVENT_CATEGORY.OTP);
  }, []);

  useEffect(() => {
    setCustomerName(name);
  }, [name]);

  useEffect(() => {
    setCustomerEmail(email);
  }, [email]);

  useEffect(() => {
    props.updateCustomerName(customer_name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_name]);

  useEffect(() => {
    props.updateCustomerEmail(customer_email);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_email]);

  const handleNameChange = (e) => {
    const name = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(name)) {
      setCustomerName(name);
    }

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.NAME.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.NAME.LABEL,
        properties: {},
      },
    );
  };

  const handleEmailChange = (e) => {
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.EMAIL.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.EMAIL.LABEL,
        properties: {},
      },
    );
    setCustomerEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.ADDRESS.LABEL,
        properties: {},
      },
    );
  };

  const handleDefaultChange = (e) => {
    setIs_default(!is_default);
  };

  const handleCountryChange = (e, options) => {
    if (country_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCountry_id(defaultDropdownState);
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setProvinceData([]);
    setCitiesData([]);
    setAreasData([]);

    if (!HELPER.isEmpty(options?.value)) {
      // set country id
      setCountry_id({
        value: options?.value,
        label: options?.label,
      });
      // send selected_country_id.value parameter to parse provinces from HELPER
      const provinces = HELPER.findProvinceByCountryId(countryData, options?.value);
      // save parsed provinces data in provinces dropdown
      setProvinceData(provinces);
    }
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.COUNTRY.LABEL,
        properties: {},
      },
    );
  };

  const handleProvinceChange = (e, options) => {
    if (province_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setProvince_id(defaultDropdownState);
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setCitiesData([]);
    setAreasData([]);

    if (!HELPER.isEmpty(options?.value)) {
      // set province id
      setProvince_id({
        value: options?.value,
        label: options?.label,
      });

      // send selected_province_id.value parameter to parse cities from HELPER
      const cities = HELPER.findCityByProvinceId(provinceData, options?.value);
      // save parsed cities data in cities dropdown
      setCitiesData(cities);
    }
    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.PROVINCE.LABEL,
        properties: {},
      },
    );
  };

  const handleCityChange = (e, options) => {
    if (city_id?.value === options?.value) return
    // Clear all selected country_id, province_id, city_id, area_id and respective dropdown values
    setCity_id(defaultDropdownState);
    setArea_id(defaultDropdownState);

    setAreasData([]);

    if (!HELPER.isEmpty(options?.value)) {
      // set city id
      setCity_id({
        value: options?.value,
        label: options?.label,
      });

      // send selected_city_id.value parameter to parse areas from HELPER
      const areas = HELPER.findAreaByCityId(citiesData, options?.value);
      // save parsed areas data in areas dropdown
      setAreasData(areas);
    }

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.AREA.LABEL,
        properties: {},
      },
    );
  };

  const handleAreaChange = (e, options) => {
    if (area_id?.value === options?.value) return
    // Clear all selected area_id and respective dropdown values
    setArea_id(defaultDropdownState);

    if (!HELPER.isEmpty(options?.value)) {
      // save parsed areas data in areas dropdown
      setArea_id({
        value: options?.value,
        label: options?.label,
      });
    }

    // LOAD GTM EVENT
    GTM_HELPER._generalEventLog(
      GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
      {
        action: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.ACTION,
        label: GTM_HELPER.GTM_EVENT.ADDRESS.INPUT.CITY.LABEL,
        properties: {},
      },
    );
  };

  const handleValidation = (e) => {
    setErrors(defaultErrorState);
    validation_errors.email = "";
    validation_errors.name = "";
    validation_errors.area_id = "";
    const validation_error = validation_errors;
    // same as above, but feel free to move this into a class method now.
    let validation_field_name = "";
    const validate_email = VALIDATION_HELPER.validateEmail(customer_email, translate);
    validation_error.email = "";
    validation_error.address = "";
    if (!customer_name) {
      validation_error.name = translate("field_is_required");
      validation_field_name = "name";
    } else if (customer_name.length > 50) {
      validation_error.name = translate("name_field_max_validation");
      validation_field_name = "name";
    } else if (customer_name.length > 50) {
      validation_error.name =
        "This name field should not be greater than 50 characters";
      validation_field_name = "name";
    } else if (validate_email.status === true) {
      validation_error.email = validate_email.message;
      validation_field_name = "email";
    } else if (HELPER.isEmpty(area_id.value)) {
      validation_error.area_id = translate("field_is_required");
      validation_field_name = "area_id";
    } else if (HELPER.stringToBoolean(is_pin_location_mandatory)) {
      if (HELPER.isEmpty(latitude) || HELPER.isEmpty(longitude)) {
        validation_error.latitude = translate("mandatory_pin_placeholder");
        props.errorAlerts(translate("mandatory_pin_placeholder"));
        validation_field_name = "email";
      } else if (!address) {
        validation_error.address = translate("field_is_required");
        validation_field_name = "address";
      } else if (address.length < 5) {
        validation_error.address = translate("address_field_min_validation");
        validation_field_name = "address";
      } else if (address.length > 190) {
        validation_error.address = translate("address_field_max_validation");
        validation_field_name = "address";
      }
    } else {
      if (HELPER.isEmpty(country_id.value)) {
        validation_error.country_id = translate("field_is_required");
        validation_field_name = "country_id";
      } else if (HELPER.isEmpty(province_id.value)) {
        validation_error.province_id = translate("field_is_required");
        validation_field_name = "province_id";
      } else if (HELPER.isEmpty(city_id.value)) {
        validation_error.city_id = translate("field_is_required");
        validation_field_name = "city_id";
      } else if (!address) {
        validation_error.address = translate("field_is_required");
        validation_field_name = "address";
      } else if (address.length < 5) {
        validation_error.address = translate("address_field_min_validation");
        validation_field_name = "address";
      } else if (address.length > 190) {
        validation_error.address = translate("address_field_max_validation");
        validation_field_name = "address";
      }
    }
    if (validation_field_name !== "") {
      scroller.scrollTo(validation_field_name, { offset: -200, smooth: true });
    }
    setErrors(validation_error);
    return validation_error;
  };

  const checkErrorCondition = (e) => {
    const errorName = HELPER.isEmpty(errors.name) ? false : true;
    const errorEmail = HELPER.isEmpty(errors.email) ? false : true;
    const errorCountry = HELPER.isEmpty(errors.country_id) ? false : true;
    const errorProvince = HELPER.isEmpty(errors.province_id) ? false : true;
    const errorCity = HELPER.isEmpty(errors.city_id) ? false : true;
    const errorArea = HELPER.isEmpty(errors.area_id) ? false : true;
    const errorAddress = HELPER.isEmpty(errors.address) ? false : true;
    const checkErrorCondition = errorName || errorEmail || errorCountry || errorProvince || errorCity || errorArea || errorAddress ? true : false;
    return checkErrorCondition;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationCheck = handleValidation();
    if (
      validationCheck.name === "" &&
      validationCheck.email === "" &&
      validationCheck.country_id === "" &&
      validationCheck.province_id === "" &&
      validationCheck.city_id === "" &&
      validationCheck.area_id === "" &&
      validationCheck.address === "" &&
      !showBtnLoader
    ) {
      const addressObject = {
        name,
        email: customer_email,
        country_id: country_id.value,
        province_id: province_id.value,
        city_id: city_id.value,
        area_id: area_id?.value,
        address,
        latitude,
        longitude,
        is_default: is_default === true || is_default === 1 ? 1 : 0,
        zip_code,
      };
      const dropdown = {
        countryData,
        provinceData,
        citiesData,
        areasData,
      };
      props.addAddress(addressObject, dropdown, history);

      if (typeof timeoutTime !== "undefined") {
        clearTimeout(timeoutTime);
      }
      timeoutTime = setTimeout(() => {
        props.clearAlerts();
      }, 7000);

      // LOAD GTM EVENT
      GTM_HELPER._generalEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.ADDRESS,
        {
          action: GTM_HELPER.GTM_EVENT.ADDRESS.SUBMIT.ACTION,
          label: GTM_HELPER.GTM_EVENT.ADDRESS.SUBMIT.LABEL,
          properties: {},
        },
      );
    } else {
      timeoutTime = setTimeout(() => {
        setErrors(defaultErrorState);
      }, 5000);
    }
  };

  useEffect(() => {
    //  Clear alerts
    props.clearAlerts();
    //  Address Data
    if (!HELPER.isEmpty(addressData?.customer_email)) {
      setCustomerEmail(addressData?.customer_email);
      setDisableEmail(true);
    }
    if (!HELPER.isEmpty(addressData?.customer_name)) {
      setCustomerName(addressData?.customer_name);
      setDisableName(true);
    }
    // Check if reducer has metaData values and assign it to meta state if not, then call meta data api asap
    if (HELPER.isEmpty(metaData)) {
      props.getMeta();
    } else {
      // Since meta state has values of country data thus assign metaData reducer state to countrydata as well
      setMetaData(metaData);
      setCountryData(metaData);
    }

    // returned function will be called on component unmount
    return () => {
      props.clearAlerts();
      clearTimeout(timeoutTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultCountry && !HELPER.isEmpty(metaData)) {
      const provinces = HELPER.findProvinceByCountryId(metaData, defaultCountry?.value);
      setCountry_id(defaultCountry);
      setProvinceData(provinces);
    }
    if (!HELPER.isEmpty(metaData)) {
      setMetaData(metaData);
      setCountryData(metaData);
    }

    if (setCoordinatesLat || setCoordinatesLng) {
      setLatitude(setCoordinatesLat);
      setLongitude(setCoordinatesLng);
    }

    if (!HELPER.isEmpty(locationDetails)) {
      let cities = [];
      let areas = [];
      const loc_details_country_id = locationDetails.country_id;
      const loc_details_state_id = locationDetails.state_id;
      const loc_details_city_id = locationDetails.city_id;
      const loc_details_area_id = locationDetails.area_id;
      // Get province, city and area values from metaData;
      const provinces = HELPER.findProvinceByCountryId(metaData, loc_details_country_id);
      if (!HELPER.isEmpty(loc_details_state_id)) {
        cities = HELPER.findCityByProvinceId(provinces, loc_details_state_id);
        if (!HELPER.isEmpty(loc_details_city_id)) {
          areas = HELPER.findAreaByCityId(cities, loc_details_city_id);
        }
      }

      setCountry_id({
        value: loc_details_country_id,
        label: locationDetails.country,
      });
      setProvinceData(provinces);
      setProvince_id({
        value: loc_details_state_id,
        label: locationDetails.state,
      });
      setCitiesData(cities);
      setCity_id({
        value: loc_details_city_id,
        label: locationDetails.city,
      });
      setAreasData(areas);
      setArea_id({
        value: loc_details_area_id,
        label: locationDetails.area,
      });
      setAddress(locationDetails.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData, setCoordinatesLat, setCoordinatesLng, locationDetails]);

  const checkError = checkErrorCondition();

  return (
    <div className="signInView">
      <h1>{translate("address_title")}</h1>
      <p className="signviewsmalltext">
        {translate("shipping_address_placeholder")}
      </p>
      {alertComponent}
      <form
        className="myForm"
        // onSubmit={handleSubmit}
        dir={locale === CONSTANTS.IS_URDU ? "rtl" : "ltr"}
      >
        {/* Full Name */}
        <TextField
          type="text"
          margin="normal"
          variant="outlined"
          className="col-md-12"
          label={translate("name_label")}
          name="name"
          placeholder={translate("name_placeholder")}
          required={true}
          onChange={handleNameChange}
          value={customer_name}
          error={errorName}
          autoFocus={true}
          helperText={errors.name}
          disabled={disableName}
        />
        {/* Email */}
        <TextField
          type="email"
          margin="normal"
          variant="outlined"
          className="col-md-12"
          label={translate("email_label")}
          name="email"
          placeholder={translate("email_placeholder")}
          onChange={handleEmailChange}
          required={true}
          value={customer_email}
          error={errorEmail}
          helperText={errors.email}
          disabled={disableEmail}
        />
        {/* Add New Address Form */}
        <AddAddressForm
          state={state}
          propStates={{ is_pin_location_mandatory: is_pin_location_mandatory }}
          handleCountryChange={handleCountryChange}
          handleProvinceChange={handleProvinceChange}
          handleCityChange={handleCityChange}
          handleAreaChange={handleAreaChange}
          handleChange={handleAddressChange}
          handleDefaultChange={handleDefaultChange}
          setCoordinatesLat={setCoordinatesLat}
          setzip_code={setzip_code}
          zip_code={zip_code}
        />
        <div className="address-Btn singleBtn">
          <button
            type="button"
            className={
              "btn btn-primary btn-block " +
              (checkError ? "disabled" : "")
            }
            id="address-submit"
            onClick={handleSubmit}
            disabled={checkError ? true : false}
          >
            {translate("next_btn")}
          </button>
        </div>
      </form>
    </div >
  );
}

function mapStateToProps(state) {
  const { alert } = state;
  const { showBtnLoader } = state.loading;
  const { name, email } = state.form;
  const { locale } = state.Intl;
  const { locationDetails } = state.address;
  const { metaData, defaultCountry } = state.meta;
  const { setCoordinatesLat, setCoordinatesLng } = state.map;
  const { is_pin_location_mandatory } = state.config;
  return {
    alert,
    showBtnLoader,
    defaultCountry,
    locationDetails,
    metaData,
    setCoordinatesLat,
    setCoordinatesLng,
    locale,
    is_pin_location_mandatory,
    name,
    email,
  };
}
const mapDispatchToProps = {
  getMeta: verificationAction.get_meta_data,
  addAddress: addressAction.add_new_address,
  errorAlerts: alertActions.error,
  clearAlerts: alertActions.clear,
  updateCustomerName: formAction.UPDATE_CUSTOMER_NAME,
  updateCustomerEmail: formAction.UPDATE_CUSTOMER_EMAIL,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(AddressForm)));
