import Cookies from "universal-cookie";
import {
  HELPER,
} from "../../utils";

const cookies = new Cookies();
// Set expiry time
const days = parseInt(process.env.REACT_APP_COOKIE_EXPIRY);
const expiry = new Date();
expiry.setDate(expiry.getDate() + days);

const cookieSettings = {
  path: "/",
  // httpOnly: true,
  expires: expiry,
  secure: true,
  domain: process.env.REACT_APP_SUB_DOMAIN_URL,
  sameSite: "lax", // A String, with possible values lax or strict, prevents the browser from sending cookie along with cross-site requests.
};

/*
 * COOKIE STORAGE SERVICE
 */
function _getAccessToken() {
  return cookies.get("_access_token");
}
function _updateAccessToken(access_token) {
  cookies.set("_access_token", access_token, cookieSettings);
}
function _removeAccessToken() {
  if (cookies.get("_access_token") !== undefined) {
    return cookies.remove("_access_token", cookieSettings);
  }
}

function _saveToCookieStorage(key, value) {
  cookies.set(key, value, cookieSettings);
}

function _updateInCookieStorage(key, value) {
  cookies.set(key, value, cookieSettings);
}

function _getFromCookieStorage(token) {
  return cookies.get(token);
}

function _deleteFromCookieStorage(key) {
  cookies.remove(key, cookieSettings);
}

/*
 * SESSION STORAGE SERVICE
 */
function _saveToSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

function _getFromSessionStorage(token) {
  return sessionStorage.getItem(token);
}

function _updateInSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

function _deleteFromSessionStorage(key, value) {
  sessionStorage.removeItem(key);
}
function _clearSessionStorage(keys) {
  sessionStorage.clear();
}
/*
 * LOCAL STORAGE SERVICE
 */
function _saveToLocalStorage(key, value) {
  sessionStorage.setItem(key, value);
  // localStorage.setItem(key, value);
}

function _getFromLocalStorage(token) {
  return sessionStorage.getItem(token);
  // return localStorage.getItem(token);
}

function _updateInLocalStorage(key, value) {
  sessionStorage.setItem(key, value);
  // localStorage.setItem(key, value);
}

function _deleteFromLocalStorage(key, value) {
  sessionStorage.removeItem(key);
  // localStorage.removeItem(key);
}
function _clearLocalStorage(keys) {
  sessionStorage.clear();
  // localStorage.clear();
}
function _getTimer(timer) {
  if (HELPER.isEmpty(timer)) {
    return Date.now() + 0;
  } else {
    return Date.now() + timer;
  }
}

export const LOCAL_STORAGE_SERVICE = {
  _getAccessToken,
  _updateAccessToken,
  _removeAccessToken,
  _saveToCookieStorage,
  _getFromCookieStorage,
  _updateInCookieStorage,
  _deleteFromCookieStorage,
  _saveToSessionStorage,
  _getFromSessionStorage,
  _updateInSessionStorage,
  _deleteFromSessionStorage,
  _clearSessionStorage,
  _saveToLocalStorage,
  _getFromLocalStorage,
  _updateInLocalStorage,
  _deleteFromLocalStorage,
  _clearLocalStorage,
  _getTimer,
};
