const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  SECONDARY: "ALERT_SECONDARY",
  CLEAR: "ALERT_CLEAR",
};

const languageConstants = {
  LANGUAGE_POPUP_OPEN: "LANGUAGE_POPUP_OPEN_REQUEST",
  LANGUAGE_POPUP_CLOSE: "LANGUAGE_POPUP_CLOSE_REQUEST",

  UPDATE_LANGUAGE_LOCALE: "LANGUAGE_LOCALE_UPDATE_REQUEST",
};

const verificationConstants = {
  RESET_DEFAULT_REQUEST: "RESET_DEFAULT_REQUEST",

  VERIFY_CLIENT_REQUEST: "CLIENT_VERIFY_REQUEST",
  VERIFY_CLIENT_SUCCESS: "CLIENT_VERIFY_SUCCESS",
  VERIFY_CLIENT_FAILURE: "CLIENT_VERIFY_FAILURE",

  SHOW_ACCESS_FORBIDDEN: "SHOW_ACCESS_FORBIDDEN",

  AT_VALIDATION_REQUEST: "CUSTOMER_VALIDATE_ACCESS_TOKEN_REQUEST",
  AT_VALIDATION_UNSUCCESSFULL: "CUSTOMER_VALIDATE_ACCESS_TOKEN_UNSUCCESSFULL",
  AT_VALIDATION_SUCCESSFULL: "CUSTOMER_VALIDATE_ACCESS_TOKEN_SUCCESSFULL",
  AT_VALIDATION_FAILURE: "CUSTOMER_VALIDATE_ACCESS_TOKEN_FAILURE",

  PHONE_VERIFICATION_REQUEST: "CUSTOMER_PHONE_VERIFICATION_REQUEST",
  PHONE_VERIFICATION_SUCCESS: "CUSTOMER_PHONE_VERIFICATION_SUCCESS",
  PHONE_VERIFICATION_FAILURE: "CUSTOMER_PHONE_VERIFICATION_FAILURE",
};

const formConstants = {
  UPDATE_COUNTRY_LIST: "UPDATE_COUNTRY_LIST",
  UPDATE_PHONE_NUMBER: "UPDATE_PHONE_NUMBER",
  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  UPDATE_CUSTOMER_NAME: "UPDATE_CUSTOMER_NAME",
  UPDATE_CUSTOMER_EMAIL: "UPDATE_CUSTOMER_EMAIL",
};

const otpConstants = {
  OTP_VERIFY_REQUEST: "CUSTOMER_OTP_VERIFY_REQUEST",
  OTP_VERIFY_SUCCESS: "CUSTOMER_OTP_VERIFY_SUCCESS",
  OTP_VERIFY_FAILURE: "CUSTOMER_OTP_VERIFY_FAILURE",

  RETRY_OTP_SEND_REQUEST: "CUSTOMER_RETRY_OTP_SEND_REQUEST",
  RETRY_OTP_SEND_SUCCESS: "CUSTOMER_RETRY_OTP_SEND_SUCCESS",
  RETRY_OTP_SEND_FAILURE: "CUSTOMER_RETRY_OTP_SEND_FAILURE",

  DISABLE_OTP_TIMER: "SET_OTP_TIMER_STATE",
};

const addressConstants = {
  ADD_NEW_REQUEST: "ADD_NEW_ADDRESS_REQUEST",
  ADD_NEW_SUCCESS: "ADD_NEW_ADDRESS_SUCCESS",
  ADD_NEW_FAILURE: "ADD_NEW_ADDRESS_FAILURE",

  GET_LOCATION_DETAILS_REQUEST: "GET_LOCATION_DETAILS_REQUEST",
  GET_LOCATION_DETAILS_SUCCESS: "GET_LOCATION_DETAILS_SUCCESS",
  GET_LOCATION_DETAILS_FAILURE: "GET_LOCATION_DETAILS_FAILURE",
  GET_LOCATION_DETAILS_EMPTY: "GET_LOCATION_DETAILS_EMPTY",
  EMPTY_LOCATION_DETAILS: "EMPTY_LOCATION_DETAILS",
  CONFIRM_ADDRESS_FULLSCREEN_MAP: "CONFIRM_ADDRESS_FULLSCREEN_MAP",

};

const metaConstants = {
  META_REQUEST: "META_DATA_REQUEST",
  META_SUCCESS: "META_DATA_SUCCESS",
  META_FAILURE: "META_DATA_FAILURE",

  DEFAULT_META_REQUEST: "DEFAULT_META_DATA_REQUEST",
  DEFAULT_META_SUCCESS: "DEFAULT_META_DATA_SUCCESS",
  DEFAULT_META_FAILURE: "DEFAULT_META_DATA_FAILURE",

  COUNTRY_LIST_REQUEST: "COUNTRY_LIST_DATA_REQUEST",
  COUNTRY_LIST_SUCCESS: "COUNTRY_LIST_DATA_SUCCESS",
  COUNTRY_LIST_FAILURE: "COUNTRY_LIST_DATA_FAILURE",
};

const dialogConstants = {
  TERMS_OPEN: "DIALOG_OPEN_REQUEST",
  TERMS_CLOSE: "DIALOG_CLOSE_REQUEST",

  MODAL_OPEN: "MODAL_OPEN_REQUEST",
  MODAL_CLOSE: "MODAL_CLOSE_REQUEST",

  PERMISSION_POPUP_OPEN: "PERMISSION_POPUP_OPEN_REQUEST",
  PERMISSION_POPUP_CLOSE: "PERMISSION_POPUP_CLOSE_REQUEST",
  PERMISSION_POPUP_ALLOWED: "PERMISSION_POPUP_ALLOWED_SUCCESS",
  PERMISSION_POPUP_DISALLOWED: "PERMISSION_POPUP_DISALLOWED_SUCCESS",

  LANGUAGE_POPUP_OPEN: "LANGUAGE_POPUP_OPEN_REQUEST",
  LANGUAGE_POPUP_CLOSE: "LANGUAGE_POPUP_CLOSE_REQUEST",
};

const mapConstants = {
  ADD_ADDRESS_LAT_LNG_REQUEST: "ADD_ADDRESS_LOCATION_REQUEST",

  UPDATE_ADDRESS_LAT_LNG_REQUEST: "UPDATE_ADDRESS_LOCATION_REQUEST",

  UPDATE_PLACE_SEARCH_REQUEST: "UPDATE_PLACE_SEARCH_REQUEST",

  FULL_SCREEN_MODE: "SET_FULL_SCREEN_MODE",
  GET_MY_LOCATION: "GET_MY_LOCATION_PARAMS",
  INITIATE_MAP: "INITIATE_MAP",
  UPDATE_PERMISSION_LOCATION: "UPDATE_PERMISSION_LOCATION",
};

export {
  alertConstants,
  verificationConstants,
  otpConstants,
  addressConstants,
  metaConstants,
  dialogConstants,
  mapConstants,
  languageConstants,
  formConstants,
};
