/* eslint-disable indent */

import { otpConstants, metaConstants } from "../actionTypes";
import { apiService } from "../middlewares/api_service";
import { HELPER } from "../../utils";
import { CONSTANTS, ERROR_MESSAGES } from "../../utils";
import { alertActions, errorAction } from "./index";

export const verificationAction = {
  get_meta_data,
  get_default_dropdown,
  get_country_dropdown,
  get_country_dropdown_list,
  SET_OTP_TIMER_STATE,
};

function SET_OTP_TIMER_STATE(state) {
  return (dispatch) => {
    dispatch(request(state));
  };
  function request(state) {
    return { type: otpConstants.DISABLE_OTP_TIMER, state };
  }
}

function get_meta_data() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // check countries
          const countries = response?.data?.body;
          const data = HELPER.parseMetaData(countries);
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          dispatch(failure(error));
        }
      });
  };

  function request() {
    return { type: metaConstants.META_REQUEST };
  }
  function success(data) {
    return { type: metaConstants.META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: metaConstants.META_FAILURE };
  }
}

function get_default_dropdown() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // check countries
          const countries = response?.data?.body;
          const data = HELPER.parseDefaultMetaData(countries);
          dispatch(success(data));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          dispatch(failure(error));
        }
      });
  };

  function request() {
    return { type: metaConstants.DEFAULT_META_REQUEST };
  }
  function success(data) {
    return { type: metaConstants.DEFAULT_META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: metaConstants.DEFAULT_META_FAILURE };
  }
}

function get_country_dropdown_list() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCountryDropdownList()
      .then((response) => {
        const responseStatus = response?.data?.status;
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          const dataList = response?.data?.body;
          dispatch(success(dataList));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        const error_message = errorAction(error_response);
        if (error_response === undefined) {
          dispatch(alertActions.error(error_message));
        }
        dispatch(failure(error_message));
      });
  };

  function request() {
    return { type: metaConstants.COUNTRY_LIST_REQUEST };
  }
  function success(data) {
    return { type: metaConstants.COUNTRY_LIST_SUCCESS, response: data };
  }
  function failure() {
    return { type: metaConstants.COUNTRY_LIST_FAILURE };
  }
}
function get_country_dropdown(responseData) {
  return (dispatch) => {
    dispatch(meta_request());
    dispatch(request());
    apiService
      .getMetaData()
      .then((response) => {
        // check response status
        const responseStatus = response?.data?.status;
        // Check if respone sttaus is success
        if (
          !HELPER.isEmpty(responseStatus) &&
          responseStatus === CONSTANTS.HTTP_RESPONSE.SUCCESS
        ) {
          // check countries
          const countries = response?.data?.body;
          const responseBody = HELPER.parseDefaultMetaData(countries, responseData);
          const data = HELPER.parseMetaData(countries);
          dispatch(meta_success(data));
          dispatch(success(responseBody));
        }
      })
      .catch((error) => {
        const error_response = error?.response;
        if (error_response === undefined) {
          dispatch(failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(meta_failure(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
          dispatch(alertActions.error(ERROR_MESSAGES.NETWORK_CONNECTIVITY));
        } else {
          const error_message = error_response?.data?.message;
          dispatch(failure(error_message));
          dispatch(meta_failure(error_message));
        }
      });
  };

  function request() {
    return { type: metaConstants.DEFAULT_META_REQUEST };
  }
  function meta_request() {
    return { type: metaConstants.META_REQUEST };
  }
  function meta_success(data) {
    return { type: metaConstants.META_SUCCESS, metaData: data };
  }
  function success(data) {
    return { type: metaConstants.DEFAULT_META_SUCCESS, metaData: data };
  }
  function failure() {
    return { type: metaConstants.DEFAULT_META_FAILURE };
  }
  function meta_failure() {
    return { type: metaConstants.META_FAILURE };
  }
}
