// @flow
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { animateScroll as scroll } from "react-scroll";

import {
  IMAGE_SRC,
  SUPPORTED_LANG,
  HELPER,
  ROUTE_CONSTANTS,
  CONSTANTS,
  GTM_HELPER,
} from "../../utils";
import {
  AppBreadcrumb,
  AppFooter,
  LanguagePopup,
  PermissionPopup,
  PoweredBy,
} from "../../components";
import {
  languageActions,
} from "../../store/actions";
import routes from "../../route";

const languageSelected = (locale) => {
  const lang = SUPPORTED_LANG.find((language) => {
    return language.locale_switch === locale;
  });
  return lang.language_icon;
};


function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    const path = routes.find((route) => {
      return route.path === location.pathname;
    });
    if (path !== -1 && path?.view) {
      GTM_HELPER._setPageView(path.view);
    } else {
      GTM_HELPER._setPageView(location.pathname);
    }
  },
  [location],
  );
}

function AppWrapper(props) {
  const {
    showPoweredBy,
    showLangIcon,
    merchant_app_theme_code,
    showFooter,
    locale,
    breadcrumb,
    showHeader,
    loadingStatus,
    showAddressScreen,
    customer_name,
    customer_email,
    history,
    location,
  } = props;

  const [lang, setLang] = useState("");
  const [customAnimationClassName, setCustomAnimationClassName] = useState("");
  // componentDidMount
  useEffect(() => {
    /*
      SET LANGUAGE ICON
    */
    setLang(languageSelected(locale));
    setCustomAnimationClassName("");
    /*
      SCROLL SCREEN TO TOP ON EACH RENDER
    */
    scroll.scrollToTop();
    /*
      INITIALIZE APP THEME COLOR
    */
    if (!HELPER.isEmpty(merchant_app_theme_code)) {
      document.documentElement.style.setProperty(
          "--theme-custom-color",
          merchant_app_theme_code,
      );
    }

    // returned function will be called on component unmount
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // componentWillRecieveProps
  useEffect(() => {
    /*
      UPDATE APP THEME COLOR
    */
    if (!HELPER.isEmpty(merchant_app_theme_code)) {
      document.documentElement.style.setProperty(
          "--theme-custom-color",
          merchant_app_theme_code,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant_app_theme_code]);

  useEffect(() => {
    /*
      UPDATE SELECTED LANGU
    */
    setLang(languageSelected(locale));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (showAddressScreen && location.pathname !== ROUTE_CONSTANTS.MAP_FULL_SCREEN) {
      history.push({
        pathname: ROUTE_CONSTANTS.ADD_NEW_ADDRESS,
        state: {
          prevState: CONSTANTS.PREV_SCREEN.OTP_VERIFY_SCREEN,
          data: { customer_name, customer_email },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddressScreen]);

  const changeLanguage = () => {
    const lang_selected = SUPPORTED_LANG.find((language) => {
      return language.locale_switch === locale;
    });
    props.changeLanguage(lang_selected.locale);

    const htmlEl = document.querySelector("html");
    htmlEl.setAttribute("lang", lang_selected.locale);

    const lang_direction = HELPER.getLngDirection(lang_selected.locale);
    const bodyEl = document.querySelector("body");
    bodyEl.setAttribute("dir", lang_direction);
    // LOAD GTM EVENT
    GTM_HELPER._navigationEventLog(
        GTM_HELPER.GTM_EVENT_CATEGORY.NAVIGATION,
        {
          action: GTM_HELPER.GTM_EVENT.NAVIGATION.LANG_CHANGE.ACTION,
          label: GTM_HELPER.GTM_EVENT.NAVIGATION.LANG_CHANGE.LABEL,
          properties: {
            previous: locale,
            new: lang_selected.locale,
          },
        },
    );
  };

  const handleLangIcon = () => {
    return (
      <div className="dropdown d-flex languageIcon">
        <span className="nav-link" onClick={changeLanguage}>
          {lang}
        </span>
      </div>
    );
  };


  return (
    <>
      {usePageViews()}
      {showHeader ? (
        <div className="mainWrap">
          <header>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-5">
                  <div className="logo_One">
                    <img
                      src={IMAGE_SRC.BSECURE_BLACK_ICON}
                      alt="bSecure"
                      className="header-brand-img"
                    />
                  </div>
                </div>
                <div className="d-flex order-lg-3 ml-auto">
                  {showLangIcon ? handleLangIcon() : ""}
                </div>
              </div>
            </div>
          </header>
        </div>
      ) : ""}
      <div className="progressWrap">
        <div className="progressLine animate__animated animate__fadeInLeft" style={{ width: `${loadingStatus}%` }}></div>
      </div>
      <div className="header d-lg-flex p-0">
        {showPoweredBy && <PoweredBy />}
      </div>
      <div className={`contentWrapper custom-animate ${customAnimationClassName}`}>
        {breadcrumb.show ? <AppBreadcrumb properties={breadcrumb} /> : ""}
        {props.children}
        {showFooter ? <AppFooter /> : ""}
      </div>
      <LanguagePopup />
      <PermissionPopup />
    </>
  );
}

function mapStateToProps(state) {
  const { locale } = state.Intl;
  const { loadingStatus } = state.loading;
  const { merchant_app_theme_code } = state.config;
  const {
    customer_name,
    customer_email,
    showAddressScreen,
  } = state.client;
  return {
    loadingStatus,
    locale,
    merchant_app_theme_code,
    customer_name,
    customer_email,
    showAddressScreen,
  };
}
const mapDispatchToProps = {
  openLanguagePopup: languageActions.openLanguagePopup,
  changeLanguage: languageActions.updateLanguage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AppWrapper));
