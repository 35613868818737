// @flow
// eslint-disable-next-line
import React from "react";

import {
  Breadcrumb,
  CheckoutBreadcrumb,
} from "../../../components";

import {
  CONSTANTS,
} from "../../../utils";

function AppBreadcrumb(props) {
  const { options, type } = props.properties;

  return <>
    {
            type !== CONSTANTS.BREADCRUMB_TYPE.CHECKOUT && type !== CONSTANTS.BREADCRUMB_TYPE.NONE ?
                <Breadcrumb
                  options={options}
                  label={type}
                /> :
                type === CONSTANTS.BREADCRUMB_TYPE.CHECKOUT ? <CheckoutBreadcrumb /> : ""
    }
  </>;
}

export default AppBreadcrumb;
