import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { CONSTANTS } from "../../utils";

const en_setting = (
  <ul>
    <li>Go to Settings > Privacy > Location Services.</li>
    <li>Make sure that Location Services is on.</li>
    <li>Scroll down to find the Browser application.</li>
    <li>Tap the app and select an option:</li>
    <ul>
      <li>
        <b>Never:</b> Prevents access to Location Services information.
      </li>
      <li>
        <b>Ask Next Time:</b> This allows you to choose Always While Using App,
        Allow Once, or Don't Allow.
      </li>
      <li>
        {" "}
        While Using the App: Allows access to Location Services only when the
        app or one of its features is visible on the screen.
      </li>
      <li>
        <b>Always:</b>Allows access to your location even when the app is in the
        background.
      </li>
    </ul>
  </ul>
);
const ur_setting = (
  <ul>
    <li>سیٹنگیں کھولیں "رازداری"> "مقام کی خدمات" پر جائیں</li>
    <li>یقینی بنائیں کہ "لوکیشن سروسز" آن ہے</li>
    <li>
      ان تمام ایپس کی فہرست تلاش کرنے کے لئے نیچے سکرول کریں جو مقام کی خدمات کو
      قابل بناتے ہیں۔ اگر آپ اپنے محل وقوع کے اعداد و شمار تک رسائی حاصل کرنے کے
      لئے باسکور ایپ کو فعال کرنا چاہتے ہیں تو ، براؤزر کی درخواست پر کلک کریں۔
    </li>
    <li>
      آپ ایپ کا استعمال کرتے ہوئے ، ہمیشہ ، اگلی بار پوچھیں ، اور کبھی نہیں کے
      درمیان انتخاب کرسکیں گے
    </li>
    <ul>
      <li>کبھی نہیں: مقام کی خدمات تک معلومات تک رسائی کو روکتا ہے۔</li>
      <li>
        اگلی بار پوچھیں: یہ آپ کو استعمال کرتے وقت ہمیشہ منتخب کرنے کی اجازت
        دیتا ہے ایپ ، ایک بار اجازت دیں ، یا اجازت نہ دیں۔
      </li>
      <li>
        ہمیشہ: آپ کے مقام تک رسائی کی اجازت دیتا ہے یہاں تک کہ ایپ موجود ہے پس
        منظر.
      </li>
    </ul>
    <li>اپنی ترجیح منتخب کریں ، اور اپنے براؤزر کے لئے مقام کو قابل بنائیں</li>
  </ul>
);

function IosLocationUpdate(props) {
  const { locale } = props;

  return (
    <div
      className="browSetting"
    >
      {locale === CONSTANTS.IS_ENGLISH ? en_setting : ur_setting}
    </div>
  );
}
function mapStateToProps(state) {
  const { locale } = state.Intl;
  return { locale };
}

export default connect(mapStateToProps)(withRouter(IosLocationUpdate));
