/* eslint-disable max-len */

const LANG_TRANS = {
  ur: {
    locale: "ur",
    messages: {
      //  Error Screen
      error_heading: "غلطی",
      oops_text: "غلطی ہوگئی",
      go_back: "واپس جائيں",
      not_found: "نہيں ملا",
      bad_request: "غلط درخواست۔",
      unauthorized_access: "غیرمجاز رسائی۔",
      internal_server_error: "اندرونی سرور کی غلطی۔",
      access_forbidden: "رسائی ممنوع۔",
      service_unavailable: "سہولت دستیاب نہيں۔",
      network_error:
        "کوئی نیٹ ورک کنکشن نہیں ملا۔ برائے مہربانی اپنا انٹرنیٹ کنکشن چیک کریں",

      // Phone Screen
      phone_screen_title: "آپ کا فون نمبر",
      phone_breadcrumb_heading: "فون",
      phone_title: "لاگ ان کرنے کےلئے اپنا فون نمبر درج کریں",
      phone_placeholder: "فون نمبر",

      // OTP Screen
      resend_otp_title: "دوبارہ بھیجیں",
      otp_title: "او ٹی پی",
      // otp_placeholder:
      //   "آپ کے {ٹیلی نار} نمبر {0345 111 1111} پر ایک پیغام بھیجا گیا ہے۔ اگر آپ {ٹیلی نار} پر نہیں ہیں تو ،",
      otp_placeholder:
        "آپ کے {ٹیلی نار} نمبر {0345 111 1111} پر ایک پیغام بھیجا گیا ہے۔ ،",
      // otp_click_here: "یہاں کلک کریں۔",
      otp_click_here: "",

      // Address Screen
      address_breadcrumb_heading: "پتہ",
      address_title: "آپ کا ایڈریس",
      shipping_address_placeholder: "اپنا شپنگ کا پتہ نیچے درج کریں",
      default_address_text: "بطور ڈیفالٹ ایڈریس سیٹ کریں",

      //  Permission Popup
      continue_with: " ۔{مرچنٹ} کے ساتھ جاری رکھیں",
      permission_text: " ۔{مرچنٹ} کو درج ذیل معلومات موصول ہوں گں",
      what_you_allow: "دیکھیں آپ کیا اجازت دیتے ہیں",
      permission_footer_text:
        "یہ ویب سائٹ آپ کے ادائیگی کی تفصیلات وصول نہیں کرتی ہے",

      //  Language Popup
      suggested_language_text: "تجویز کردہ زبانیں اور علاقہ جات",
      select_region_text: "زبان اور علاقہ منتخب کریں",

      // One tap login Screen
      continue_as_user: "بطور {بطور} جاری رکھیں",
      not_as_user: "۔{بطور} نہیں ہیں؟",
      one_tap_login: "ایک ٹیپ کے ساتھ لاگ ان",

      //  Placeholder fields
      phone_label: "فون",
      otp_label: "او ٹی پی",
      name_label: "نام",
      email_label: "ای میل",
      country_label: "ملک",
      province_label: "صوبہ",
      city_label: "شہر",
      area_label: "علاقہ",
      address_label: "پتہ",
      ADDRESS: {
        FORM: {
          LABEL: {
            ZIPCODE: "زپ کوڈ",
          },
        },
      },

      name_placeholder: "اپنا نام درج کریں",
      email_placeholder: "اپنا ای میل درج کریں",
      country_placeholder: "ملک",
      province_placeholder: "صوبہ",
      city_placeholder: "شہر",
      area_placeholder: "علاقہ",
      address_placeholder: "پتہ",
      // General
      // get_my_location_btn: "اپنا مقام حاصل کریں",
      get_my_location_btn: "نقشہ",
      find_geo_location: "جغرافیائی مقام تبدیل کرنے کے لئے یہاں کلک کریں",
      found_geo_location: "آپ کا جغرافیائی مقام معلوم ہوگیا ہے",
      powered_by: "معاونت فراہم کنندہ",
      allow_btn: "اجازت دیتے ہیں",
      cancel_btn: "منسوخ کریں",
      back_btn: "پیچھے",
      next_btn: "آگے",
      invalid_phone_number: "درست فون نمبر درج کریں",
      field_is_required: "اس کو پر کرنا ضروری ہے",
      name_field_max_validation: "نام کا فیلڈ 50 حروف سے زیادہ نہیں ہونا چاہئے",
      email_field_max_validation:
        "ای میل کا فیلڈ 50 حروف سے زیادہ نہیں ہونا چاہئے",
      invalid_email_address: "غلط ای میل ایڈریس",
      otp_validation_message: "او ٹی پی کم از کم 6 ہندسوں کا ہونا چاہئے",
      address_field_min_validation:
        " پتہ کی فیلڈ کم از کم 5 حروف کا ہونا چاہئے",
      address_field_max_validation:
        "پتہ کی فیلڈ 199 حروف سے زیادہ نہیں ہونا چاہئے",
      limit_validation_message: "یہ فیلڈ {فیلڈ} حروف سے {کم} نہیں ہوسکتی ہے",
      minimum_limit_text: "کم",
      maximum_limit_text: "زیادہ",
      mandatory_pin_placeholder: "جی پی ایس مقام لازمی ہے",
      TERMS_AND_CONDITION: {
        terms_and_condition_text_prepend: "آپ کا آرڈر مکمل کرنے کا مطلب ہے کہ آپ بی سیکیور کے",
        terms_and_condition_text: "شرائط و ضوابط ",
        terms_and_condition_text_append: "سے متفق ہیں",
        //  Terms & Condition Popup
        terms_and_condition_heading: "شرائط و ضوابط",
        terms_and_condition_para_1:
          "ہم مسلسل ہماری خدمات کو بہتر بنانے کے لئے نئی ٹیکنالوجی اور خصوصیات ترقی کر رہے ہیں. مثال کے طور پر ، ہم مصنوعی ذہانت میں سرمایہ کاری کرتے ہیں جو اسپیم اور مالویئر کا پتہ لگانے اور اسے مسدود کرنے اور آپ کو بیک وقت ترجمے جیسی جدید خصوصیات مہیا کرنے کیلئے مشین لرننگ کا استعمال کرتے ہیں۔ اس مستقل بہتری کے ایک حصے کے طور پر ، ہم بعض اوقات خصوصیات اور افعال کو شامل یا ختم کردیتے ہیں ، ہماری خدمات کی حدود میں اضافہ یا کمی کرتے ہیں ، اور نئی خدمات کی پیش کش کرنا شروع کردیتے ہیں یا پرانی خدمات کی پیش کش بند کردیتے ہیں۔",
        terms_and_condition_para_2:
          "اگر ہم ایسی مادی تبدیلیاں کرتے ہیں جو ہماری خدمات کے آپ کے استعمال پر منفی اثر ڈالتے ہیں یا اگر ہم کسی پیش کش کی پیش کش بند کردیتے ہیں تو ، ہم آپ کو مناسب پیشگی اطلاع دیں گے اور بی ایسیکور اکاؤنٹ سے آپ کے مواد کو برآمد کرنے کا موقع فراہم کریں گے ، سوائے ضروری حالات میں۔ جیسا کہ بدسلوکی کی روک تھام ، قانونی تقاضوں کا جواب دینا یا سیکیورٹی اور آپریبلٹی کے امور کو حل کرنا۔",
      },
      FOOTER: {
        TERMS: "شرائط و ضوابط",
        PRIVACY: "رازداری کی پالیسی",
      },
      DIALOG_CONTENT: {
        TERMS: {
          HEADING: "شرائط و ضوابط",
          CONTENT_1: "ہم مسلسل ہماری خدمات کو بہتر بنانے کے لئے نئی ٹیکنالوجی اور خصوصیات ترقی کر رہے ہیں. مثال کے طور پر ، ہم مصنوعی ذہانت میں سرمایہ کاری کرتے ہیں جو اسپیم اور مالویئر کا پتہ لگانے اور اسے مسدود کرنے اور آپ کو بیک وقت ترجمے جیسی جدید خصوصیات مہیا کرنے کیلئے مشین لرننگ کا استعمال کرتے ہیں۔ اس مستقل بہتری کے ایک حصے کے طور پر ، ہم بعض اوقات خصوصیات اور افعال کو شامل یا ختم کردیتے ہیں ، ہماری خدمات کی حدود میں اضافہ یا کمی کرتے ہیں ، اور نئی خدمات کی پیش کش کرنا شروع کردیتے ہیں یا پرانی خدمات کی پیش کش بند کردیتے ہیں۔",
          CONTENT_2:
            "اگر ہم ایسی مادی تبدیلیاں کرتے ہیں جو ہماری خدمات کے آپ کے استعمال پر منفی اثر ڈالتے ہیں یا اگر ہم کسی پیش کش کی پیش کش بند کردیتے ہیں تو ، ہم آپ کو مناسب پیشگی اطلاع دیں گے اور بی ایسیکور اکاؤنٹ سے آپ کے مواد کو برآمد کرنے کا موقع فراہم کریں گے ، سوائے ضروری حالات میں۔ جیسا کہ بدسلوکی کی روک تھام ، قانونی تقاضوں کا جواب دینا یا سیکیورٹی اور آپریبلٹی کے امور کو حل کرنا۔",

        },
        PRIVACY: {
          SECTION_1: {
            HEADING: "رازداری کی پالیسی",
            CONTENT_1: "بی سیکور پرائوٹ لمیٹڈ میں ، ہم آپ کی ذاتی معلومات کے بارے میں آپ کے کنسرن کو سمجھتے ہیں ، ہمیں اس کی بھی پرواہ ہے۔ اس طرح ، ہم یقینی بناتے ہیں کہ آپکی فراہم کردہ معلومات کی حفاظت کریں اور آپکی پراؤسی  کا احترام کریں جب آپ بی سیکور کی ویب سائٹ پر لاگ ان ہوں گے ۔",
            CONTENT_2: "یہ رازداری کی پالیسی آپ کی ہماری ویب سائٹ کے استعمال سے متعلق آپ کے ذاتی طور پر قابل شناخت معلومات (نام ، رابطہ نمبر ، میلنگ اور پوسٹل ایڈریس) کو جمع کرنے ، سے متعلق آپ کے رازداری کے حقوق پر حکمرانی کرتی ہے۔ یہ معلومات الیکٹرانک ڈیٹا بیس میں محفوظ کی گئی ہے ، جو صرف بی سیکور پرائوٹ لمیٹڈ کمپنی کی ویب سائٹ کے استعمال کے لئے ہے۔ یہ رازداری کی پالیسی سائٹ اور خدمات کے ذریعہ فراہم کردہ خدمات پر لاگو ہوتی ہے",
          },
          SECTION_2: {
            HEADING: "ہم کون سی ذاتی معلومات اکٹھا کرتے ہیں؟",
            CONTENT_1: "جب آپ ویب سائٹ پر ہمارے ساتھ کوئی آرڈر دیتے ہو تو ہم مختلف معلومات اکٹھا کرسکتے ہیں۔ آپ کا ڈیٹا ویب سائٹ پر دیئے گئے آپ کے آرڈر پر کاڑوائی کرنے میں ہماری سہولت کیلئے ہے۔ ہم آپ کی ذاتی معلومات بھی اکٹھا کرسکتے ہیں جس میں آپ کے نام ، جنس ، تاریخ پیدائش ، ای میل ایڈریس ، پوسٹل ایڈریس ، ٹیلیفون نمبر ، ادائیگی کی تفصیلات ، ادائیگی کارڈ کی تفصیلات یا بینک اکاؤنٹ کی تفصیلات شامل ہوسکتی  ہے۔",
            CONTENT_2: "ہم آپ کی معلومات کو اپنے ساتھ اپنے اکاؤنٹ میں اندراج کرنے ، عمل کرنے اور ویب سائٹ پر اپنا آرڈر دیتے وقت ادائیگیوں کی تصدیق کرنے کے لئے بھی استعمال کرسکتے ہیں ، ہماری ویب سائٹ سے ڈیٹا ڈاؤن لوڈ کرنے کا آڈٹ کرسکتے ہیں ، صفحات کی ترتیب اور ​ یا مواد کو بہتر بنائیں گے۔ ہماری ویب سائٹ اور ان کو صارفین کی مرضی کے مطابق بنائیں گے ، ہماری ویب سائٹ پر آنے والوں کی شناخت کریں ، ہمارے صارفین کے اعدادوشمار پر تحقیق کریں ، آپ کو ایسی معلومات بھیجیں جو آپ کو لگتا ہے کہ آپ کو مفید مل سکتا ہے یا جس سے آپ نے ہماری مصنوعات اور خدمات کے بارے میں معلومات بھی فراہم کی ہیں۔ آپ نے اشارہ کیا ہے کہ آپ کو ان مقاصد کے لئے رابطہ کرنے پر کوئی اعتراض نہیں ہے۔ آپ کی رضامندی حاصل کرنے کے ساتھ مشروط ہم دوسرے مصنوعات اور خدمات کی تفصیلات کے ساتھ ای میل کے ذریعے آپ سے رابطہ کرسکتے ہیں۔ اگر آپ ترجیح دیتے ہیں کہ ہم سے کوئی مارکیٹنگ مواصلات حاصل نہ کریں تو آپ کسی بھی وقت آپٹ آؤٹ کرسکتے ہیں۔",
          },
          SECTION_3: {
            HEADING: "ہم آپ کا ڈیٹا کیسے جمع کرتے ہیں؟",
            CONTENT: "بی سیکور (پی وی ٹی) لمیٹڈ میں ، ہم آپ کی ذاتی معلومات کے بارے میں آپ کی فکر کو سمجھتے ہیں ، ہمیں اس کی بھی پرواہ ہے۔ اس طرح ، ہم یقینی بناتے ہیں کہ فراہم کردہ معلومات کی حفاظت کریں اور رازداری کے ل for آپ کی درخواستوں کا احترام کریں جب آپ 'www.bsecure.pk' ویب سائٹ پر لاگ ان ہوں گے (اس کے بعد سے ویب سائٹ کے طور پر حوالہ دیا جائے گا)۔.",
            LIST: {
              ITEM_1: "•     آن لائن رجسٹر ہوں یا ہماری ویب سائٹ پر درج ہماری مصنوعات میں سے کسی کے لئے آرڈر دیں۔",
              ITEM_2: "•     رضاکارانہ طور پر ایک گراہک مکمل کریں یا جب آپ ہمیں اپنی رائے فراہم کریں۔",
              ITEM_3: "•     اپنے براؤزر کی کوکیز کے ذریعے ہماری ویب سائٹ تک رسائی حاصل کریں",
              ITEM_4: "•     ہماری کمپنی آپ کا ڈیٹا بالواسطہ درج ذیل وسائل سے وصول کرسکتی ہے۔",
            },
          },
          SECTION_4: {
            HEADING: "ہم آپ کی معلومات کی حفاظت کیسے کریں گے؟",
            CONTENT_1: "یہ رازداری کی پالیسی آپ کی ہماری ویب سائٹ کے استعمال سے متعلق آپ کے ذاتی طور پر قابل شناخت معلومات (نام ، رابطہ نمبر ، میلنگ اور پوسٹل ایڈریس) کو جمع کرنے ، اسٹوریج اور جمع کرنے سے متعلق آپ کے رازداری کے حقوق پر حکمرانی کرتی ہے۔ یہ معلومات الیکٹرانک ڈیٹا بیس میں محفوظ کی گئی ہے ، جو صرف نجی نجی کمپنی کی ویب سائٹ کے استعمال کے لئے ہے۔ یہ رازداری کی پالیسی سائٹ اور خدمات کے ذریعہ فراہم کردہ خدمات پر لاگو ہوتی ہے",
            CONTENT_2: "عام کاروبار کے دوران ہم آپ کی کچھ ذاتی معلومات اپنے کارپوریٹ فیملی میں اور تیسری پارٹی کے ساتھ جو ہماری طرف سے کام کر رہے ہیں یا قابل اطلاق قانون کے ذریعہ اجازت یا ضرورت کے مطابق بانٹ سکتے ہیں۔",
            CONTENT_3: "ہم اس بات کو یقینی بنانے کے لئے معقول کوشش کرتے ہیں کہ جب آپ ہماری ویب سائٹ پر ہوں تو آپ کی ذاتی معلومات کو محفوظ رہے.",
            CONTENT_4: "ہم اس بات کو یقینی بنانے کے لئے معقول کوشش کرتے ہیں کہ جب آپ ہماری ویب سائٹ پر ہوں تو آپ کی ذاتی معلومات کو محفوظ رہے.",
          },
          SECTION_5: {
            HEADING: "بی سیکور پرائوٹ لمیٹڈ آپ کی معلومات کو کس طرح استعمال کرتا ہے؟",
            CONTENT_1: "بی سیکور پرائوٹ لمیٹڈ میں ، ہم آپ کی ذاتی معلومات کے بارے میں آپ کی فکر کو سمجھتے ہیں ، ہمیں اس کی بھی پرواہ ہے۔ اس طرح ، ہم اس بات کو یقینی بناتے ہیں کہ ہم فراہم کردہ معلومات کی حفاظت کریں اور رازداری کے ل یوور آپ کی درخواستوں کا احترام کریں ـ",
            // "The security of your personal information is a high priority for BE SECURE (PVT) LIMITED. BE SECURE (PVT) LIMITED uses your personal information to enhance its services and personalize your shopping experience. At BE SECURE (PVT) LIMITED, we do not sell or rent the customer information to any third parties except to provide BE SECURE (PVT) LIMITED service or as explained below.",
            LIST: {
              ITEM_1: "•     بی سیکور پرائوٹ لمیٹڈ  اپنے صارفین کے ڈیٹا جیسےکے ڈیموگرافکس کا استعمال کرسکتی ہے اور مختلف مقاصد کے لئے مشتہرین ، شراکت داروں اور دیگر کو انکشاف کرسکتی ہے۔.",
              ITEM_2: "•    بی سیکور پرائوٹ لمیٹڈ  کچھ سرگرمیوں جیسے پیکجوں کی فراہمی اور ڈیٹا انیلیسیس کے لئے دوسری کمپنیوں کی خدمات حاصل کرسکتی ہیں۔ ان کمپنیوں کو اپنی سرگرمیاں انجام دینے کے لے آپ کی ذاتی معلومات تک رسائی حاصل ہوسکتی ہے۔",
            },
            CONTENT_2: "ہماری ویب سائٹس ڈیٹا ٹرانسپورٹ کے دوران آپ کی ذاتی معلومات کی حفاظت کے لئے انکرپشن کی ٹکنالوجی کا استعمال کرتی ہیں.",
          },
        },
      },
      LOCATION: {
        PERMISION: {
            ALLOW: {
                HEADING: "مقام کو جاری رکھنے کی اجازت دیں",
                TEXT: "اس آرڈر کو درست طریقے سے ڈیلیور کرنے کے لیے ہمیں آپ کا مقام جاننے کی ضرورت ہے",
            },
        },
        MANDATORY: "مقام کی اجازت لازمی ہے",
        ENABLE: "براؤزر کے مقام تک رسائی کو فعال کریں",
        UPDATE: "اجازت کو اپ ڈیٹ کرنے کے لیے کلک کریں",
        BROWSER_LOCATION_ENABLE: "براؤزر کی ترتیبات سے براہ کرم مقام کو فعال کریں۔",
    },
    },
  },
  en: {
    locale: "en-US",
    messages: {
      //  Error Screen
      error_heading: "Error",
      oops_text: "Oops",
      go_back: "Go back",
      not_found: "Not Found",
      bad_request: "Bad Request.",
      unauthorized_access: "Unauthorized Access.",
      internal_server_error: "Internal Server Error.",
      access_forbidden: "Access Forbidden.",
      service_unavailable: "Service Unavailable.",
      network_error:
        "No network connection found. Kindly check your internet connection",

      // Phone Screen
      phone_screen_title: "Phone number",
      phone_breadcrumb_heading: "Phone",
      phone_title: "Enter your phone number to login",
      phone_placeholder: "Phone Number",

      // OTP Screen
      resend_otp_title: "Resend OTP",
      otp_title: "OTP",
      // otp_placeholder:
      //   "A text has been sent to your {network_name} number {phone_number}.  If you are not on {network_name}.",
      otp_placeholder: "A text has been sent to your number {phone_number}.",
      // otp_click_here: "Click here",
      otp_click_here: "",

      // Address Screen
      address_breadcrumb_heading: "Address",
      address_title: "Your Address",
      shipping_address_placeholder: "Enter your shipping address below",
      default_address_text: "Set as Default Address",

      //  Permission Popup
      continue_with: "Continue with {merchant_name}",
      permission_text:
        "{merchant_name} will receive the following information:",
      what_you_allow: "See what you allow",
      permission_footer_text:
        "This website doesn’t receive your payment details",

      //  Language Popup
      suggested_language_text: "Suggested languages and regions",
      select_region_text: "Choose a language and region",

      // One tap login Screen
      continue_as_user: "Continue as {customer_name}",
      not_as_user: "Not {customer_name}",
      one_tap_login: "One-Tap Login",

      //  Placeholder fields
      name_label: "Name",
      email_label: "Email",
      country_label: "Country",
      province_label: "Province",
      city_label: "City",
      area_label: "Area",
      address_label: "Address",
      ADDRESS: {
        FORM: {
          LABEL: {
            ZIPCODE: "Zip code",
          },
        },
      },

      name_placeholder: "Enter your name",
      email_placeholder: "Enter your email",
      country_placeholder: "Country",
      province_placeholder: "Province",
      city_placeholder: "City",
      area_placeholder: "Area",
      address_placeholder: "Address",

      // General
      get_my_location_btn: "Get my location",
      find_geo_location: "Click here to change Location.",
      found_geo_location: "Your geo-location has been detected",
      powered_by: "Powered by",
      allow_btn: "Allow",
      cancel_btn: "Cancel",
      back_btn: "Back",
      next_btn: "Next",
      invalid_phone_number: "Enter valid phone number",
      field_is_required: "This field is required",
      name_field_max_validation:
        "The name field should not be greater than 50 characters",
      email_field_max_validation:
        "The email field should not be greater than 50 characters",
      invalid_email_address: "Invalid email address",
      otp_validation_message: "OTP should be atleast 6 digits",
      address_field_min_validation:
        "This address field should be atleast 5 characters",
      address_field_max_validation:
        "The address field should not be greater than 199 characters",
      limit_validation_message:
        "The {name} field may not be {limit} than {digits} characters",
      minimum_limit_text: "less",
      maximum_limit_text: "greater",
      mandatory_pin_placeholder: "GPS Location is mandatory",
      TERMS_AND_CONDITION: {
        terms_and_condition_text: "terms and conditions",
        terms_and_condition_text_prepend: "By completing your order you agree to abide by bSecure's ",
        terms_and_condition_heading: "Terms & Conditions",
        terms_and_condition_para_1:
          "We’re constantly developing new technologies and features to improve our services. For example, we invest in artificial intelligence that uses machine learning to detect and block spam and malware, and to provide you with innovative features like simultaneous translations. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.",
        terms_and_condition_para_2:
          "If we make material changes that negatively impact your use of our services or if we stop offering a service, we’ll provide you with reasonable advance notice and an opportunity to export your content from your bSecure Account using bSecure Checkout, except in urgent situations such as preventing abuse, responding to legal requirements or addressing security and operability issues.",
      },
      FOOTER: {
        TERMS: "Terms & Conditions",
        PRIVACY: "Privacy",
      },
      DIALOG_CONTENT: {
        TERMS: {
          HEADING: "Terms & Conditions",
          CONTENT_1: "We’re constantly developing new technologies and features to improve our services. For example, we invest in artificial intelligence that uses machine learning to detect and block spam and malware, and to provide you with innovative features like simultaneous translations. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.",
          CONTENT_2:
            "If we make material changes that negatively impact your use of our services or if we stop offering a service, we’ll provide you with reasonable advance notice and an opportunity to export your content from your bSecure Account using bSecure Checkout, except in urgent situations such as preventing abuse, responding to legal requirements or addressing security and operability issues.",
        },
        PRIVACY: {
          SECTION_1: {
            HEADING: "Privacy Policy",
            CONTENT_1: "At BE SECURE (PVT) LIMITED, we understand your concern about your personal information, we care about it too. As such, we ensure we safeguard the information provided and honor your requests for confidentiality when you log in to www.bsecure.pk Website (henceforth shall be referred to as “Website”).",
            CONTENT_2: "This Privacy Policy explains how we collect, use and (under certain conditions) disclose your personal information. This Privacy Policy also explains the steps we have taken to secure your personal information. And this Privacy Policy explains your options regarding the collection, use and disclosure of your personal information. By visiting the Site directly or through another site, you accept the practices described in this Policy.",
          },
          SECTION_2: {
            HEADING: "What personal information do we collect?",
            CONTENT_1: "We may collect various information while you are placing an order with us at the Website. Your Data is collected, stored and processed in order to facilitate us in processing your order placed on the Website. We may also collect your personal information which may include but is not limited to your name, gender, date of birth, email address, postal address, telephone number, payment details, payment card details or bank account details.",
            CONTENT_2: "We may also use your information in order to register your account with us, process and verify the payments that you make while placing your order on the Website, audit the downloading of data from our website, improve the layout and/or content of the pages of our website and customize them for users, identify visitors on our website, carry out research on our users' demographics, send you information we think you may find useful or which you have requested from us, including information about our products and services, provided you have indicated that you have not objected to being contacted for these purposes. Subject to obtaining your consent we may contact you by email with details of other products and services. If you prefer not to receive any marketing communications from us, you can opt out at any time.",
          },
          SECTION_3: {
            HEADING: "How do we collect your data?",
            CONTENT: "At BE SECURE (PVT) LIMITED, we understand your concern about your personal information, we care about it too. As such, we ensure we safeguard the information provided and honor your requests for confidentiality when you log in to www.bsecure.pk Website (henceforth shall be referred to as “Website”).",
            LIST: {
              ITEM_1: "•     Register online or place an orderfor any of our productslisted on our website.",
              ITEM_2: "•     Voluntarily complete a customersurvey or when you provide us with your feedback.",
              ITEM_3: "•     Access our website through yourbrowser’s cookies.",
              ITEM_4: "•     Our Company may receive your dataindirectly from the following resource.",
            },
          },
          SECTION_4: {
            HEADING: "How do we protect your information?",
            CONTENT_1: "This privacy policy governs your privacy rights regarding our collection, storage and accumulation of your personally identifiable information (name, contact number, mailing and postal address) concerning your use of our website. This information is stored in an electronic database, which is for BE SECURE (PVT) LIMITED’s website use only. This privacy policy applies to the Site and services provided by BE SECURE (PVT) LIMITED.",
            CONTENT_2: "In the normal course of business we may share some of your personal information within our corporate family and with third parties acting on our behalf or as permitted or required by applicable law.",
            CONTENT_3: "We make reasonable efforts to ensure that your personal information is protected while you are on our Website.",
            CONTENT_4: "We make reasonable efforts to ensure that your personal information is protected while you are on our Website.",
          },
          SECTION_5: {
            HEADING: "How does BE SECURE (PVT)LIMITED use your information?",
            CONTENT_1: "The security of your personal information is a high priority for BE SECURE (PVT) LIMITED. BE SECURE (PVT) LIMITED uses your personal information to enhance its services and personalize your shopping experience. At BE SECURE (PVT) LIMITED, we do not sell or rent the customer information to any third parties except to provide BE SECURE (PVT) LIMITED service or as explained below.",
            LIST: {
              ITEM_1: "•     BE SECURE (PVT) LIMITED may use the customer data such as demographics and disclose to advertisers, partners, and other for various purposes.",
              ITEM_2: "•     BE SECURE (PVT) LIMITED may hire other companies for certain activities like delivering packages and data analysis. These companies may have access to your personal information to perform their activities.",
            },
            CONTENT_2: "Our Web Sites use encryption technology to protect your personal information during data transport. Such technology encrypts ordering information such as your name, address, and credit card number.",
          },
        },
      },
      LOCATION: {
        PERMISION: {
            ALLOW: {
                HEADING: "Allow location to continue",
                TEXT: "We need to know your location in order to deliver this order precisely",
            },
        },
        MANDATORY: "Location Permission is mandatory",
        ENABLE: "Enable browser location access",
        UPDATE: "Click to update permission",
        BROWSER_LOCATION_ENABLE: "Please enable location from the browser settings.",
      },
    },
  },
};

const SUPPORTED_LANG = [
  {
    locale: "ur",
    language: "Urdu",
    region: "(اردو)",
    country: "Pakistan",
    lng_priority: 0,
    dir: "rtl",
    language_icon: "اردو",
    locale_switch: "en",
  },
  {
    locale: "en",
    language: "English",
    region: "(UK)",
    country: "United Kingdom",
    lng_priority: 1,
    dir: "ltr",
    language_icon: "English",
    locale_switch: "ur",
  },
  // {
  //   locale: "ar",
  //   language: "Arabic",
  //   region: "(عربی)",
  //   country: "Saudi Arab",
  //   lng_priority: 0,
  // },
  // {
  //   locale: "en-us",
  //   language: "English",
  //   region: "(US)",
  //   country: "United States",
  //   lng_priority: 0,
  // },
  // {
  //   locale: "ch",
  //   language: "Chinese",
  //   region: "",
  //   country: "China",
  //   lng_priority: 0,
  // },
];

export {
  LANG_TRANS,
  SUPPORTED_LANG,
};
