import { dialogConstants } from "../actionTypes";

export const dialogAction = {
  openDialog,
  closeDialog,
};

// TERMS & CONDITIONS
function openDialog(type) {
  return (dispatch) => {
    dispatch(open(type));
  };
  function open(type) {
    return { type: dialogConstants.TERMS_OPEN, response: type };
  }
}

function closeDialog() {
  return (dispatch) => {
    dispatch(close());
  };

  function close() {
    return { type: dialogConstants.TERMS_CLOSE };
  }
}
