import {
  otpConstants,
  verificationConstants,
} from "../actionTypes";

const initState = {
  is_pin_location_mandatory: true,
  gtm_id: process.env.REACT_APP_DEFAULT_GTM_ID,
  merchant_app_theme_code: process.env.REACT_APP_DEFAULT_APP_THEME_COLOR,
  breadcrumbSettings: {
    disableClick: false,
  },
  retryOtp: false,
};

const configReducer = (state = initState, action) => {
  switch (action.type) {
    // RESEND OTP
    case otpConstants.OTP_RETRY_REQUEST:
      return {
        ...state,
        retryOtp: true,
      };
    case otpConstants.OTP_RETRY_SUCCESS:
      return {
        ...state,
        retryOtp: false,
      };
    case otpConstants.OTP_RETRY_FAILURE:
      return {
        ...state,
        retryOtp: false,
      };
    case verificationConstants.VERIFY_CLIENT_SUCCESS:
      return {
        ...state,
        gtm_id: action?.response?.gtm_id ?? process.env.REACT_APP_DEFAULT_GTM_ID,
        merchant_app_theme_code: action?.response?.theme_colour ?? process.env.REACT_APP_DEFAULT_APP_THEME_COLOR,
        is_pin_location_mandatory: action?.response?.is_pin_location_mandatory,
      };

    case otpConstants.DISABLE_OTP_TIMER:
      return {
        ...state,
        breadcrumbSettings: {
          ...state.breadcrumbSettings,
          disableClick: action?.state,
        },
      };
    default:
      return state;
  }
};
export default configReducer;
