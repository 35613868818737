import { isMobile } from "react-device-detect";
import { CONSTANTS, LOCAL_STORAGE_SERVICE } from "../../utils";

function capitalizeFirstLetter(string) {
  let val = "";
  if (!isEmpty(string)) {
    val = string.charAt(0).toUpperCase() + string.slice(1);
  }
  return val;
}

function capitalize(string) {
  let val = "";
  if (!isEmpty(string)) {
    val = string.toUpperCase();
  }
  return val;
}

function isEmpty(x) {
  return (
    typeof x === "undefined" ||
    x === null ||
    x === "null" ||
    x === "undefined" ||
    x === false || // same as: !x
    x.length === 0 ||
    x === ""
    // ||
    // (x.replace(/\s/g,"") === "")
    // ||
    // (!/[^\s]/.test(x))
    // ||
    // (/^\s*$/.test(x))
  );
}

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function checkLocalStorage(key, propVal) {
  const localStorageVal = LOCAL_STORAGE_SERVICE._getFromLocalStorage(key);
  return isEmpty(localStorageVal) ? propVal : localStorageVal;
}

function parseMetaData(data) {
  const countries = data?.countries;
  let default_country = null;
  const countryObject = [];
  const countryData = {};
  let networks;
  let networkObject;
  let networkData;
  let provinces;
  let provinceObject;
  let provinceData;
  let cities;
  let cityObject;
  let cityData;
  let areas;
  let areaObject;
  let areaData;
  // Country
  countries &&
    countries.map((country) => {
      countryData = {};
      countryData.value = country?.country_id;
      countryData.label = country?.name;
      if (country?.is_default === 1) {
        default_country = {
          value: country?.country_id,
          label: country?.name,
        };
      }
      // Provinces
      provinces = country?.provinces;
      provinceObject = [];
      provinces &&
        provinces.map((province) => {
          provinceData = {};
          provinceData.value = province?.id;
          provinceData.label = province?.name;
          // Cities
          cities = province?.cities;
          cityObject = [];
          cities &&
            cities.map((city) => {
              cityData = {};
              cityData.value = city?.id;
              cityData.label = city?.name;
              //  Area
              areas = city?.areas;
              areaObject = [];
              areas &&
                areas.map((area) => {
                  areaData = {};
                  areaData.value = area?.id;
                  areaData.label = area?.name;
                  areaObject.push(areaData);
                  return null;
                });
              cityData.areas = areaObject;
              cityObject.push(cityData);
              return null;
            });
          provinceData.cities = cityObject;
          provinceObject.push(provinceData);
          return null;
        });
      countryData.provinces = provinceObject;
      //  Telecom Networks
      networks = country?.teleco_networks;
      networkObject = [];
      networks &&
        networks.map((network) => {
          networkData = {};
          networkData.value = network?.id;
          networkData.label = network?.name;
          networkObject.push(networkData);
          return null;
        });
      countryData.networks = networkObject;
      //  Save country data with province, city, area and telecom networks
      countryObject.push(countryData);
      return null;
    });
  return { default_country, countryObject };
}

function parseDefaultMetaData(data, selected) {
  const cityId = selected?.city_id;

  const countries = data?.countries;
  const countryObject = [];
  const countryData = {};
  const defaultAreaObject = [];
  let provinces;
  let provinceObject;
  let provinceData;
  let cities;
  let cityObject;
  let cityData;
  let areas;
  let areaObject;
  let areaData;

  // Country
  countries &&
    countries.map((country) => {
      countryData = {};
      countryData.value = country?.country_id;
      countryData.label = country?.name;
      // Provinces
      provinces = country?.provinces;
      provinceObject = [];
      provinces &&
        provinces.map((province, key) => {
          provinceData = {};
          provinceData.value = province?.id;
          provinceData.label = province?.name;
          // Cities
          cities = province?.cities;
          cityObject = [];
          cities &&
            cities.map((city, key1) => {
              cityData = {};
              cityData.value = city?.id;
              cityData.label = city?.name;
              //  Areas
              areas = city?.areas;
              areaObject = [];
              areas &&
                areas.map((area) => {
                  areaData = {};
                  areaData.value = area?.id;
                  areaData.label = area?.name;
                  areaObject.push(areaData);
                  if (city?.id === cityId) {
                    defaultAreaObject.push(areaData);
                  }
                  return null;
                });
              cityData.areas = areaObject;
              cityObject.push(cityData);
              return null;
            });
          provinceData.cities = cityObject;
          provinceObject.push(provinceData);
          return null;
        });
      countryData.provinces = provinceObject;
      //  Save country data with province, city, area and telecom networks
      countryObject.push(countryData);
      return null;
    });
  const dropdown = {
    countryData: countryObject,
    provinceData: provinceObject,
    citiesData: cityObject,
    areasData: defaultAreaObject,
  };
  return dropdown;
}
function findProvinceByCountryId(meta, countryId) {
  const countries = meta;
  let provinces;
  let provinceData;
  let cities;
  let cityObject;
  let cityData;
  let areas;
  let areaObject;
  let areaData;

  // Country
  const provinceObject = [];
  countries &&
    countries.map((country) => {
      if (country?.value === countryId) {
        // Provinces
        provinces = country?.provinces;
        provinces &&
          provinces.map((province) => {
            provinceData = {};
            provinceData.value = province?.value;
            provinceData.label = province?.label;
            // Cities
            cities = province?.cities;
            cityObject = [];
            cities &&
              cities.map((city) => {
                cityData = {};
                cityData.value = city?.value;
                cityData.label = city?.label;
                //  Areas
                areas = city?.areas;
                areaObject = [];
                areas &&
                  areas.map((area) => {
                    areaData = {};
                    areaData.value = area?.value;
                    areaData.label = area?.label;
                    areaObject.push(areaData);
                    return null;
                  });
                cityData.areas = areaObject;
                cityObject.push(cityData);
                return null;
              });
            provinceData.cities = cityObject;
            provinceObject.push(provinceData);
            return null;
          });
      }
      return null;
    });
  return provinceObject;
}

function findCityByProvinceId(provinces, provinceId) {
  let cities;
  let cityObject;
  let cityData;
  let areas;
  let areaObject;
  let areaData;
  // Provinces
  provinces &&
    provinces.map((province) => {
      if (province?.value === provinceId) {
        // Cities
        cities = province?.cities;
        cityObject = [];
        cities &&
          cities.map((city) => {
            cityData = {};
            cityData.value = city?.value;
            cityData.label = city?.label;
            //  Areas
            areas = city?.areas;
            areaObject = [];
            areas &&
              areas.map((area) => {
                areaData = {};
                areaData.value = area?.value;
                areaData.label = area?.label;
                areaObject.push(areaData);
                return null;
              });
            cityData.areas = areaObject;
            cityObject.push(cityData);
            return null;
          });
        return null;
      }
      return null;
    });
  return cityObject;
}

function findAreaByCityId(cities, cityId) {
  let areas;
  let areaObject;
  let areaData;
  // Cities
  cities &&
    cities.map((city) => {
      if (parseInt(city?.value) === parseInt(cityId)) {
        //  Areas
        areas = city?.areas;
        areaObject = [];
        areas &&
          areas.map((area) => {
            areaData = {};
            areaData.value = area?.value;
            areaData.label = area?.label;
            areaObject.push(areaData);
            return null;
          });
      }
      return null;
    });
  return areaObject;
}

function parseToFloat(value, digit) {
  let digits;
  if (!HELPER.isEmpty(digit)) {
    digits = parseFloat(value).toFixed(parseInt(digit));
  } else {
    digits = "";
  }
  return digits;
}

function createNetworkList(networks) {
  // Network
  const networkObject = [];
  networks &&
    networks.map((network) => {
      const networkList = {};
      networkList.value = network?.id;
      networkList.label = network?.name;
      networkObject.push(networkList);
      return null;
    });
  return networkObject;
}

function getNetworkIdFromName(networks, network_name) {
  // Network
  let network_id = "";
  networks &&
    networks.map((network) => {
      if (network.label === network_name) {
        network_id = network.value;
      }
      return null;
    });
  return network_id;
}

function getNetworkNameFromId(networks, network_id) {
  // Network
  let network_name = "";
  networks &&
    networks.map((network) => {
      if (network.value === network_id) {
        network_name = network.label;
      }
      return null;
    });
  return network_name;
}

function getNetworkIdFromPhoneNumber(networks, phone_number) {
  const phone_code = phone_number.substring(0, 2);
  let network_name = "";

  networks &&
    networks.map((network) => {
      const network_code = network.codes;
      Object.keys(network_code).map((key) => {
        // [Number(key), obj[key]]
        if (parseInt(network_code[key]) === parseInt(phone_code)) {
          network_name = network.name;
        }
        return null;
      });
      return null;
    });
  return network_name;
}

function getLngDirection(lngLocale) {
  let direction = "ltr";
  if (lngLocale === CONSTANTS.IS_URDU) {
    direction = "rtl";
  }
  return direction;
}

function replaceUrduText(string_to_replace, regex, text_to_replace) {
  // The substituted value will be contained in the result variable
  const result = string_to_replace.replace(regex, text_to_replace);

  return result;
}

function otpTextFormatting(string_to_replace, network_name, phone_number) {
  const phone_number_regex = /(?=\s|)({0345 111 1111})(?=\s|$)/g;
  const network_regex = /(?=\s|)({ٹیلی نار})(?=\s|$)/g;

  let formatted_text = replaceUrduText(string_to_replace, phone_number_regex, ' <span className="num" dir="ltr">' + phone_number + "</span>");

  formatted_text = replaceUrduText(formatted_text, network_regex, network_name);

  return formatted_text;
}

function oneTapTextFormatting(string_to_replace, user_name, type) {
  const continue_as_regex = /(?=\s|)({بطور})(?=\s|$)/g;
  const not_as_regex = /(?=\s|)(۔{بطور})(?=\s|$)/g;
  let formatted_text;

  if (type === "AS_USER") {
    formatted_text = replaceUrduText(string_to_replace, continue_as_regex, user_name);
  } else {
    formatted_text = replaceUrduText(string_to_replace, not_as_regex, user_name);
  }

  return formatted_text;
}

function getPermissionText(string_to_replace, merchant_name) {
  const merchant_regex = /(?=\s|)(۔{مرچنٹ})(?=\s|$)/g;
  const formatted_text = replaceUrduText(string_to_replace, merchant_regex, merchant_name);
  return formatted_text;
}

function validationMessageFormat(string_to_replace, limit, digit) {
  const limit_regex = /(?=\s|)({فیلڈ})(?=\s|$)/g;
  const digit_regex = /(?=\s|)({کم})(?=\s|$)/g;

  let formatted_text = replaceUrduText(string_to_replace, limit_regex, limit);

  formatted_text = replaceUrduText(formatted_text, digit_regex, digit);

  return formatted_text;
}

function getDefaultLocale(propLocale) {
  const storageLocale = LOCAL_STORAGE_SERVICE._getFromLocalStorage("locale");
  const defaultLocale = HELPER.isEmpty(propLocale) ? "en" : propLocale;
  const locale = propLocale !== storageLocale ? storageLocale : defaultLocale;
  return HELPER.isEmpty(storageLocale) ? defaultLocale : locale;
}

function findInJson(object, value) {
  const record = object.filter((data) => data.id === value);
  return record[0];
}


function stringToBoolean(string) {
  switch (string) {
    case "true":
    case "yes":
    case "1":
    case 1:
      return true;
    case "false":
    case "no":
    case "0":
    case 0:
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

//  FOOTER EVENT

function hideShowFooter(show = false) {
  if (isMobile) {
    const element = document.getElementsByClassName("footerWrap");
    if (show) {
      element[0].classList.add("footer-transition");
    } else {
      element[0].classList.remove("footer-transition");
    }
  }
}

function redirectToPathAndClearLocalStorage(path, store_redirect_url = LOCAL_STORAGE_SERVICE._getFromLocalStorage("store_url")) {
  if (!HELPER.isEmpty(path) && !HELPER.isEmpty(store_redirect_url)) {
    if (window.opener !== null) {
      window.onbeforeunload = null;
      // Send message to bulider website for hosted checkout //
      const msg = { hrf: path, app_type: CONSTANTS.APP_TYPE.SINGLE_SIGN_ON };
      window.opener.postMessage(msg, store_redirect_url);
      window.close();
    } else {
      window.location.href = path;
    }
  } else {
    window.location.href = path;
  }
  if (window.webkit && isIOS) {
    window.webkit.messageHandlers.iosListener.postMessage(path);
  }
  LOCAL_STORAGE_SERVICE._clearLocalStorage();
  return true;
}

const HELPER = {
  getLngDirection,
  replaceUrduText,
  otpTextFormatting,
  capitalizeFirstLetter,
  capitalize,
  checkLocalStorage,
  createNetworkList,
  isEmpty,
  parseMetaData,
  parseDefaultMetaData,
  findProvinceByCountryId,
  findCityByProvinceId,
  findAreaByCityId,
  getNetworkNameFromId,
  getNetworkIdFromName,
  getNetworkIdFromPhoneNumber,
  ucfirst,
  parseToFloat,
  oneTapTextFormatting,
  getPermissionText,
  validationMessageFormat,
  getDefaultLocale,
  findInJson,
  stringToBoolean,
  hideShowFooter,
  redirectToPathAndClearLocalStorage,
};
export default HELPER;
