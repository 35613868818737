// @flow

import * as React from "react";
import { GoogleMap } from "../../containers";

function CheckoutMap(props) {
  const { showMap } = props;

  return showMap ? (
    <>
      <div className="GmapWrapper customGmapWrapper">
        <GoogleMap />
      </div>
    </>
  ) : (
    <></>
  );
}

export default CheckoutMap;
