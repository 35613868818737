import React from "react";
import Paper from "@material-ui/core/Paper";
import { useTranslate } from "react-redux-multilingual/lib/context";
import { IosLocationUpdate } from '../../components';
import { CONSTANTS } from '../../utils';
import { isIOS } from "react-device-detect";

export default function MapFullScreenPopUp({ locationPermission }) {
    const translate = useTranslate();
    const LocationMandatory = () => {
        return (
            <>
                <span className="title">{translate("LOCATION.MANDATORY")}</span>
                <div className="content">
                    {translate("LOCATION.PERMISION.ALLOW.TEXT")}
                </div>
            </>
        );
    };

    const EnableLocationPermission = () => {
        return (
            <div className="overflow-scroll">
                <div className="enable-browser"><i className="icomoon-location-blocked fs-12"></i></div>
                <span className="title">{translate("LOCATION.ENABLE")}</span>
                {isIOS ? <IosLocationUpdate /> : <div>{translate("LOCATION.BROWSER_LOCATION_ENABLE")}</div> }
                <div className="btn-ref" onClick={() => window.location.reload()}>{translate("LOCATION.UPDATE")}</div>
            </div>
        );
    };

    return (
        <Paper className="dialog">
            {locationPermission === CONSTANTS.PERMISSIONS.PROMPT ?
                <LocationMandatory /> :
                    locationPermission === CONSTANTS.PERMISSIONS.DENIED ?
                        <EnableLocationPermission /> :
                        null
            }
        </Paper>
    );
}
