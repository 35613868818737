
import { CONSTANTS } from "./responseConstants";

export const BREADCRUMB_SETTINGS_JSON = {
  PHONE_SCREEN: {
    TYPE: CONSTANTS.BREADCRUMB_TYPE.GENERAL,
    LABEL: "phone_breadcrumb_heading",
    ICON: "new-icons-phone-verification",
    ICON_CLASS: "iconW font18",
    CLASS: "",
    HEADER_CLASS: "",
    SHOW: true,
    SHOW_CART_ICON: true,
    PROGRESS: 16.6666666667,
  },
  ONE_TAP_LOGIN: {
    TYPE: CONSTANTS.BREADCRUMB_TYPE.GENERAL,
    LABEL: "one_tap_login",
    ICON: "",
    ICON_CLASS: "",
    CLASS: "",
    HEADER_CLASS: " oneTap",
    SHOW: true,
    SHOW_CART_ICON: false,
    PROGRESS: 0,
  },
  CHECKOUT: {
    TYPE: CONSTANTS.BREADCRUMB_TYPE.CHECKOUT,
    LABEL: "",
    ICON: "",
    ICON_CLASS: "",
    CLASS: "",
    HEADER_CLASS: "",
    SHOW: true,
    SHOW_CART_ICON: true,
    PROGRESS: 83.3333333333,
  },
  OTP: {
    TYPE: CONSTANTS.BREADCRUMB_TYPE.OTP,
    LABEL: "",
    ICON: "",
    ICON_CLASS: "",
    CLASS: "",
    HEADER_CLASS: "",
    SHOW: true,
    SHOW_CART_ICON: true,
    PROGRESS: 33.3333333333,
  },
  DEFAULT: {
    TYPE: CONSTANTS.BREADCRUMB_TYPE.NONE,
    LABEL: "",
    ICON: "",
    ICON_CLASS: "",
    CLASS: "",
    HEADER_CLASS: "",
    SHOW: true,
    SHOW_CART_ICON: false,
    PROGRESS: 50,
  },
};
