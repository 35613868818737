import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AppWrapper } from "../../components";
import { clientAction } from "../../store/actions";
import queryString from "query-string";
import {
  CONSTANTS,
  ERROR_MESSAGES,
  ROUTE_CONSTANTS,
  HELPER,
  LOCAL_STORAGE_SERVICE,
  IMAGE_SRC,
  BREADCRUMB_SETTINGS_JSON,
} from "../../utils";

function VerifyClient(props) {
  // History get for redirection
  const { history, location, client_verified } = props;

  useEffect(() => {
    // Save query string parameters
    const params = queryString.parse(location.search);
    // Extract parameters from query string
    const client_id = params.client_id;
    const response_type = params.response_type;
    const state = params.state;
    const scope = params.scope;
    // Check identifier
    const request_id = location?.state?.request_id;
    if (!HELPER.isEmpty(request_id)) {
      props.identifyCustomer(request_id, history);
    } else if (!client_verified) {
      // check if 4 params exist  in url (client id, scope, response, state)
      if (HELPER.isEmpty(client_id)) {
        showErrorPage(400, CONSTANTS.OAUTH_PARAMETERS.CLIENT_ID);
      } else if (HELPER.isEmpty(response_type)) {
        showErrorPage(400, CONSTANTS.OAUTH_PARAMETERS.RESPONSE_TYPE);
      } else if (HELPER.isEmpty(state)) {
        showErrorPage(400, CONSTANTS.OAUTH_PARAMETERS.STATE);
      } else if (HELPER.isEmpty(scope)) {
        showErrorPage(400, CONSTANTS.OAUTH_PARAMETERS.SCOPE);
      } else {
        const requestData = {
          client_id,
          response_type,
          state,
          scope,
        };
        LOCAL_STORAGE_SERVICE._clearLocalStorage();
        props.verifyClient(requestData, history);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showErrorPage = (errorCode, param_identifier) => {
    // History get for redirection
    let message;
    if (param_identifier === CONSTANTS.OAUTH_PARAMETERS.CLIENT_ID) {
      message = ERROR_MESSAGES.CLIENT_NOT_FOUND;
    } else if (param_identifier === CONSTANTS.OAUTH_PARAMETERS.RESPONSE_TYPE) {
      message = ERROR_MESSAGES.RESPONSE_TYPE_FOUND;
    } else if (param_identifier === CONSTANTS.OAUTH_PARAMETERS.CODE) {
      message = ERROR_MESSAGES.CODE_FOUND;
    } else if (param_identifier === CONSTANTS.OAUTH_PARAMETERS.SCOPE) {
      message = ERROR_MESSAGES.STATE_NOT_FOUND;
    } else if (param_identifier === CONSTANTS.OAUTH_PARAMETERS.STATE) {
      message = ERROR_MESSAGES.SCOPE_NOT_FOUND;
    }
    const state = {
      errorCode,
      message,
    };
    props.errorClientAccess(state, history);
    // Show error page
    history.push({
      pathname: ROUTE_CONSTANTS.GENERAL_ERROR_PAGE,
    });
  };
  return (
    <AppWrapper
      showAccountDropdown={false}
      showLangIcon={false}
      showPoweredBy={true}
      showFooter={false}
      breadcrumb={{
        type: BREADCRUMB_SETTINGS_JSON.DEFAULT.TYPE,
        options: BREADCRUMB_SETTINGS_JSON.DEFAULT,
        show: BREADCRUMB_SETTINGS_JSON.DEFAULT.SHOW,
      }}
    >
      <div className="screen midWrap bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="home-loading">
                <div className="home-heading">
                  Loading Secure Checkout
                </div>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="screen midWrap processingWrap d-none">
        <div className="container ">
          <div className="screenFrame">
            <div className="signInView midView ">
              <img
                src={IMAGE_SRC.BSECURE_ANNIMATION}
                alt="Processing BSecure Order"
              />
            </div>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}

function mapStateToProps(state) {
  const { client_verified } = state.client;
  return { client_verified };
}
const mapDispatchToProps = {
  verifyClient: clientAction.verify_client,
  errorClientAccess: clientAction.error_client_access,
  identifyCustomer: clientAction.identify_customer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyClient));
