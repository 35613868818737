export const CONSTANTS = {
  // Payment Screen
  DEFAULT_PAYMENT_METHOD_UI: "none",
  EASYPAISA_PAYMENT_METHOD_UI: "mobile_account_number",
  CC_PAYMENT_METHOD_UI: "credit_card",
  APP_TYPE: {
    CHECKOUT: 1,
    SINGLE_SIGN_ON: 2,
  },
  POPUP_TYPE: {
    TERMS: "TERMS_POPUP",
    PRIVACY: "PRIVACY_POPUP",
  },
  //  API RESPONSE
  HTTP_RESPONSE: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    INPROCESSIBLE: 422,
    SERVER_ERROR: 500,
  },
  //  GENERAL
  IS_RTL: "rtl",
  IS_URDU: "ur",
  IS_ENGLISH: "en",
  PREV_SCREEN: {
    IDENTIFY_CUSTOMER: "identify_customer",
    PHONE_SCREEN: "verify_phone",
    OTP_VERIFY_SCREEN: "verify_otp",
  },
  OAUTH_PARAMETERS: {
    CLIENT_ID: "client_id",
    SCOPE: "scope",
    RESPONSE_TYPE: "response_type",
    STATE: "state",
    CODE: "code",
  },
  AUTHENTICATION_PARAMETERS: {
    CUSTOMER_IDENTIFIER: "customer_identifier",
    REQUEST_ID: "request_id",
  },
  BREADCRUMB_TYPE: {
    GENERAL: 1,
    OTP: 2,
    CHECKOUT: 3,
    ORDER_STATUS: 4,
    NONE: 5,
  },
  PERMISSIONS: {
    GRANTED: "granted",
    DENIED: "denied",
    PROMPT: "prompt",
  },
};
